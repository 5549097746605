.sucess-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.sucess-modal img {
  height: 140px;
}

.payment-success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.payment-success span {
  font-size: 25px;
}

.payent-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.failure-img img {
  height: 100px;
}
