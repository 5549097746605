.box-outer {
    background: var(--dark-bg) 0% 0% no-repeat padding-box;
    border-radius: 13px;
    width: 100%;
    height: 100%;
    min-height: 85vh !important;
    padding: 10px;
}

.box-inner {
    background: #010001 0% 0% no-repeat padding-box;
    border-radius: 13px;
    width: 100%;
    height: 100%;
}

.box-outer.right .box-inner {
    padding: 20px;
}

.filter {
    width: 100%;
    padding-left: 10px;
    padding-top: 45px;
}

.filter .filter-heading {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 50px;
}

.filter .filter-heading span {
    font: normal normal normal 14px/18px 'source-sans-pro', sans-serif !important;
    letter-spacing: 0px;
    color: var(--whiteColor);
}

.filter .filter-heading span.icon {
    position: static;
    transform: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 31px;
    height: 24px;
    background: var(--dark-bg) 0% 0% no-repeat padding-box;
    border-radius: 8px;
}

.filter-body .filter-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    gap: 35px;
}

.filter-body .filter-list .filter-item {
    width: 100%;
}

.filter-body .filter-list .filter-item .filter-name {
    font: normal normal 400 16px/20px 'source-sans-pro', sans-serif !important;
    letter-spacing: 0px;
    color: var(--whiteColor);
    margin-bottom: 15px;
}

.filter-body .filter-list .filter-item .filter-input {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.filter-body .filter-list .filter-item .filter-input input,
.filter-body .filter-list .filter-item .filter-input select {
    width: 100%;
    max-width: 100%;
    height: 35px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--dark-bg);
    border-radius: 10px;
    font: normal normal 400 16px/20px 'source-sans-pro', sans-serif !important;
    letter-spacing: 0px;
    color: var(--whiteColor);
    border: none;
    outline: none;
}

input[type="date"]:invalid::-webkit-datetime-edit {
    color: var(--whiteColor);
}

.filter-body .filter-list .filter-item .filter-input .input-box {
    position: relative;
    width: 100% !important;
    max-width: 100% !important;
}

.input-box .icon {
    position: absolute;
    pointer-events: none;
}

.box-inner .action-flex {
    margin-bottom: 15px;
}

.box-inner .action-flex .action-item {
    width: 100%;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--dark-bg) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    font: normal normal 400 17px/22px 'source-sans-pro', sans-serif !important;
    letter-spacing: 0px;
    color: var(--whiteColor);
}
.box-inner .action-flex .action-item.cohorts {
    width: 200px;
}
.box-inner .action-flex .action-item.cohorts a {
    display: flex;
    align-items: center;
    gap: 14px;
}
textarea.form-control {
    height: 265px !important;
}
.action-item a {
    color: var(--whiteColor);
}

.box-inner .action-flex .action-item.active {
    background: #010001 0% 0% no-repeat padding-box;
    /* border: 2px solid var(--primary-color); */
}

.box-outer.right .box-inner .search-bar {
    width: 100%;
    /* margin-bottom: 15px; */
}

.box-outer.right .box-inner .search-bar input {
    width: 100%;
    height: 35px;
    background: #010001 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 8px !important;
    padding-left: 15px;
    font: normal normal 400 15px/18px 'source-sans-pro', sans-serif !important;
    letter-spacing: 0px;
    color: var(--whiteColor);
}

.box-outer.right .box-inner .search-bar input::placeholder {
    font: normal normal 400 15px/18px 'source-sans-pro', sans-serif !important;
    letter-spacing: 0px;
    color: var(--whiteColor);
}

.act-flex {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 35px;
}

.permission-flex {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}

.multiple-permission {
    width: 100%;
}

.permission-flex .multiple-permission select {
    width: 100%;
    height: 35px;
    background: var(--chips-color) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    /* font: normal normal 400 18px/22px 'source-sans-pro', sans-serif !important; */
    font: normal normal 400 13px/16px 'source-sans-pro', sans-serif !important;
    letter-spacing: 0px;
    color: var(--whiteColor);
    border: none !important;
    padding-left: 10px;
    padding-right: 10px;
}

.permission-flex .act-permission {
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    gap: 5px;
}

.permission-flex .act-permission button {
    background: var(--chips-color) 0% 0% no-repeat padding-box;
    border-radius: 34px;
    width: 115px;
    height: 35px;
    font: normal normal 400 13px/16px 'source-sans-pro', sans-serif !important;
    letter-spacing: 0px;
    color: var(--whiteColor);
}

.preset-roles button {
    background: var(--chips-color) 0% 0% no-repeat padding-box;
    border: 2px solid var(--primary-color);
    border-radius: 34px;
    width: 115px;
    height: 35px;
    font: normal normal 400 13px/16px 'source-sans-pro', sans-serif !important;
    letter-spacing: 0px;
    color: var(--whiteColor);
}

.user-status-flex {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.user-status-flex .user-status,
.user-status {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 125px;
    height: 30px;
    background: var(--chips-color) 0% 0% no-repeat padding-box;
    border-radius: 18px;
    font: normal normal 600 15px/30px 'source-sans-pro', sans-serif !important;
    letter-spacing: 0px;

}

.user-status-flex .user-status.all {
    border: 2px solid #D3D5D3;
    color: #FDFFFC;
}

.user-status-flex .user-status.active,
.user-status.active {
    border: 2px solid #39FF14;
    color: #39FF14;
}

.user-status-flex .user-status.inactive,
.user-status.inactive {
    border: 2px solid #F03D3D;
    color: #F03D3D;
}

.user-status-flex .user-status.unassigned {
    border: 2px solid var(--primary-color);
    color: var(--primary-color);
}

.admin-users .table-responsive {
    /* height: 350px; */
    height: 100% !important;
}

.admin-users.cohorts .table-responsive {
    height: 460px;
}

.admin-users table {
    border-collapse: separate;
    border-spacing: 0px 10px;
}

.admin-users table>:not(:last-child)>:last-child>* {
    border-bottom: none;
}

.admin-users table thead tr {
    height: 0px;
}

.admin-users table thead tr th {
    font: normal normal 400 14px/18px 'source-sans-pro', sans-serif !important;
    letter-spacing: 0px;
    color: var(--whiteColor);
}

.admin-users table tbody tr td {
    font: normal normal 400 14px/18px 'source-sans-pro', sans-serif !important;
    letter-spacing: 0px;
    color: var(--whiteColor);
}

.admin-users table tbody tr.odd td {
    background: var(--chips-color) 0% 0% no-repeat padding-box;
}

.admin-users table tbody tr.even td {
    background: var(--table-row-even) 0% 0% no-repeat padding-box;
}

.admin-users table tbody tr.active {
    border: 2px solid var(--primary-color) !important;
}

.admin-users table tbody tr td .user-flex {
    display: flex;
    align-items: center;
    gap: 10px;
}

.admin-users table tbody tr td .user-flex .user-info {
    display: flex;
    flex-direction: column;
}

.admin-users table tbody tr td .user-flex .user-info span.name {
    font: normal normal 400 14px/18px 'source-sans-pro', sans-serif !important;
}

.admin-users table tbody tr td .user-flex .user-info span.role {
    font: normal normal 400 12px/16px 'source-sans-pro', sans-serif !important;
    color: var(--whiteColor);
}

.admin-users table>:not(caption)>*>* {
    border: none !important;
}

.admin-users .permission-btn {
    height: 30px;
    background: #010001 0% 0% no-repeat padding-box;
    border-radius: 10px;
    font: normal normal 400 15px/30px 'source-sans-pro', sans-serif !important;
    letter-spacing: 0px;
    color: var(--whiteColor);
    padding-left: 10px;
}

.admin-users .table-act {
    display: flex;
    align-items: center;
    gap: 5px;
}

.admin-users .table-act button {
    background: var(--body-dark-bg) 0% 0% no-repeat padding-box;
    border-radius: 18px;
    width: 75px;
    height: 30px;
    font: normal normal 500 15px/25px 'source-sans-pro', sans-serif !important;
    letter-spacing: 0px;
    color: var(--whiteColor);
}

.admin-users.cohorts .table-act button {
    width: max-content;
    padding-left: 10px;
    padding-right: 10px;
    font: normal normal 500 14px/18px 'source-sans-pro', sans-serif !important;
}

.admin-users table tbody tr.active td {
    border-top: 2px solid var(--primary-color) !important;
    border-bottom: 2px solid var(--primary-color) !important;
}

.admin-users table tbody tr td:first-child {
    border-radius: 12px 0px 0px 12px;
}

.admin-users table tbody tr td:last-child {
    border-radius: 0px 12px 12px 0px;
}

.admin-users table tbody tr.active td:first-child {
    border-left: 2px solid var(--primary-color) !important;
}

.admin-users table tbody tr.active td:last-child {
    border-right: 2px solid var(--primary-color) !important;
}

.checkbox {
    position: relative;
}

.checkbox .styled-checkbox {
    position: absolute;
    opacity: 0;
    z-index: 100;
    width: 20px;
    height: 20px;
}

.checkbox .styled-checkbox+label {
    position: relative;
    cursor: pointer;
    padding: 0;
}

.checkbox .styled-checkbox+label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: transparent;
    border: 1px solid #707070;
}

.checkbox .styled-checkbox:hover+label:before {
    background: var(--primary-color);
}

.checkbox .styled-checkbox:focus+label:before {
    /* box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12); */
}

.checkbox .styled-checkbox:checked+label:before {
    background: var(--primary-color);
}

.checkbox .styled-checkbox:disabled+label {
    color: #b8b8b8;
    cursor: auto;
}

.checkbox .styled-checkbox:disabled+label:before {
    box-shadow: none;
    background: #ddd;
}

.checkbox .styled-checkbox:checked+label:after {
    content: "";
    position: absolute;
    left: 5px;
    top: 9px;
    background: black;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 black, 4px 0 0 black, 4px -2px 0 black, 4px -4px 0 black, 4px -6px 0 black, 4px -8px 0 black;
    transform: rotate(45deg);
}

.cust-select {
    width: 600px;
    background: var(--chips-color) 0% 0% no-repeat padding-box !important;
    border: 1px solid #707070;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
}

.cust-select .cust-select-label {
    background: var(--chips-color) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    width: 245px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cust-select .cust-select-label label {
    font: normal normal 400 18px/22px 'source-sans-pro', sans-serif !important;
    letter-spacing: 0px;
    color: var(--whiteColor);
}

.css-13cymwt-control,
.css-t3ipsp-control,
.react-select-container {
    width: 500px !important;
    background: transparent !important;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

.css-qbdosj-Input,
.css-166bipr-Input,
.css-1jqq78o-placeholder {
    color: var(--whiteColor) !important;
}

.css-1p3m7a8-multiValue {
    background: #010001 0% 0% no-repeat padding-box !important;
    border-radius: 34px !important;
    color: var(--whiteColor) !important;
    padding-left: 5px;
    padding-right: 5px;
}

.css-wsp0cs-MultiValueGeneric {
    color: var(--whiteColor) !important;
}

.react-select__menu {
    background-color: #010001 !important;
    /* color: var(--whiteColor); */
}

.react-select__menu-list .react-select__option,
.react-select-container:hover .react-select__option,
.react-select__menu-list .react-select__option:visited,
.react-select__option:hover,
.react-select__option:focus {
    background-color: var(--chips-color);
}

.css-1u9des2-indicatorSeparator {
    display: none !important;
}

.box-outer.addUser {
    /* padding: 35px; */
    height: 100%;
}

.box-outer.addUser .heading h1 {
    font: normal normal 400 20px/24px 'Avenir', sans-serif!important;;
    letter-spacing: 0px;
    color: var(--whiteColor);
    margin-bottom: 25px;
}

.bulk-upload-area {
    width: 950px;
    height: 145px;
    background: var(--dark-bg) 0% 0% no-repeat padding-box;
    border: 2px dashed var(--primary-color);
    border-radius: 18px;
    position: relative;
    display: block;
    margin: 0 auto;
    margin-bottom: 25px;
}

.bulk-upload-area .info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 225px;
    height: 35px;
    background: var(--body-dark-bg) 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font: normal normal 400 16px/20px 'Avenir', sans-serif!important;;
    letter-spacing: 0px;
    color: var(--whiteColor);
}

.bulk-upload-area input[type="file"] {
    opacity: 0;
    position: absolute;
    z-index: 5;
    width: 100%;
    height: 100%;
}

.add-user-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.add-user-form label {
    font: normal normal 400 16px/20px 'Avenir', sans-serif!important;;
    letter-spacing: 0px;
    color: var(--whiteColor);
}

.add-user-form .input-box .form-control {
    background: var(--dark-bg) 0% 0% no-repeat padding-box;
    border-radius: 16px;
    width: 705px;
    height: 45px;
    padding-left: 10px;
    padding-right: 10px;
    border: none;
    box-shadow: none;
    font: normal normal 400 16px/20px 'Avenir', sans-serif!important;;
    letter-spacing: 0px;
    color: var(--whiteColor);
    margin-bottom: 0px;
}

.add-user-form .input-box .form-select {
    margin-bottom: 0px;
    background: var(--dark-bg) 0% 0% no-repeat padding-box;
}

.add-user-form .input-box .form-control:focus {
    background: var(--dark-bg) 0% 0% no-repeat padding-box;
}

.define-user {
    display: flex;
    align-items: center;
    gap: 25px;
}

.checkcontainer {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font: normal normal 400 16px/20px 'Avenir', sans-serif!important;;
    letter-spacing: 0px;
    color: var(--whiteColor);
}

.checkcontainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.radiobtn {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;
}

.checkcontainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.checkcontainer:hover input ~ .radiobtn {
    background-color: #ccc;
}

.checkcontainer input:checked ~ .radiobtn {
    background-color: var(--whiteColor);
}

.radiobtn:after {
    content: "";
    position: absolute;
    display: none;
}
.checkcontainer input:checked ~ .radiobtn:after {
    display: block;
}

.checkcontainer .radiobtn:after {
    top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   width: 15px;
   height: 15px;
   border-radius: 50%;
   background: var(--primary-color);
}
.roles {
    width: 950px;
    display: block;
    margin: 0 auto;
}
.roles .roles-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.define-role {
    display: flex;
    align-items: center;
    gap: 15px;
}
.define-role label {
    font: normal normal 400 16px/20px 'Avenir', sans-serif!important;;
    letter-spacing: 0px;
    color: var(--whiteColor);
    margin-bottom: 0px;
}
.define-role select {
    background: #010001 0% 0% no-repeat padding-box;
    border-radius: 10px;
    width: 200px;
    height: 32px;
    border: none;
    outline: none;
    font: normal normal 400 16px/20px 'Avenir', sans-serif!important;;
    letter-spacing: 0px;
    color: var(--whiteColor);
    padding-left: 10px;
    padding-right: 10px;
}
.act-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.act-btn button {
    width: 140px;
    height: 32px;
    background: var(--primary-color) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    font: normal normal 500 15px/32px 'Avenir', sans-serif!important;;
    letter-spacing: 0px;
    color: var(--body-dark-bg);
}
.react-select__input, .css-1dimb5e-singleValue {
    color: var(--whiteColor) !important;
}
.server .react-tagsinput {
    background-color: var(--dark-bg) !important;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.04);
    border-radius: 10px !important;
    border: none !important;
    padding: 10px !important;
}
.server .react-tagsinput-tag {
    background-color: var(--chips-color) !important;
}
.flex-end .btn {
    background-color: var(--chips-color) !important;
    color: var(--whiteColor);
}