.certificate .box-outer {
    padding: 15px;
}
.pdfViewer .toolbar {
    display: none !important;
}
.certificate.box-outer {
    padding: 25px;
}
.certificate .heading h2 {
    font: normal normal 400 24px/30px 'source-sans-pro', sans-serif;
    letter-spacing: 0px;
    color: var(--whiteColor);
}
.certificate .addElements {
    background: var(--body-dark-bg) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    width: 100%;
    height: max-content;
    margin-bottom: 10px;
    padding: 20px;
}
.certificate .addedElements {
    background: var(--body-dark-bg) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    width: 100%;
    height: 535px;
    padding: 20px;
}
.certificate .preview {
    background: var(--body-dark-bg) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    width: 100%;
    height: max-content;
    padding: 20px;
    margin-bottom: 15px;
}
.form-label {
    font: normal normal 400 15px/20px 'source-sans-pro', sans-serif;
    letter-spacing: 0px;
    color: var(--whiteColor);
}

.certificate .input-box .form-control, .certificate .form-select {
    width: 100%;
    height: 45px;
    background: var(--chips-color) 0% 0% no-repeat padding-box !important;
    background-color: var(--chips-color) !important;
    border-radius: 10px !important;
    border: none !important;
    outline: none;
    font: normal normal 400 14px/18px 'source-sans-pro', sans-serif;
    letter-spacing: 0px;
    color: rgb(255, 255, 255) !important;
    margin-bottom: 5px;
}
.elements-flex.elements-text {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    gap: 30px;
}
.elements-flex.elements-upload {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}
.elements-flex .elements-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.elements-flex  .item {
    background: var(--chips-color) 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 10px;
    width: 150px;
    max-width: 100%;
    height: 40px;
    line-height: 40px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.elements-flex .item span {
    font: normal normal 400 16px/20px 'source-sans-pro', sans-serif;
    letter-spacing: 0px;
    color: var(--whiteColor);
}
.elements-flex .item .deleteIcon {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translate(-50%, -50%);
}
.elements-list .img-item {
    background: var(--chips-color) 0% 0% no-repeat padding-box;
    /* border: 1px solid #707070; */
    border: 1px dashed #DCD5D5;
    border-radius: 10px;
    width: 150px;
    height: 125px;
    padding-left: 0px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.elements-flex .elements-list .img-item .deleteIcon {
    position: absolute;
    top: 0px;
    right: 5px;
}
.elements-flex .elements-list .img-item span {
    font: normal normal 400 14px/18px 'source-sans-pro', sans-serif;
    letter-spacing: 0px;
    color: var(--whiteColor);
    text-align: center;
}
.preview-act-flex {
    display: flex;
    gap: 20px;
}
.preview-act-flex .preview-act {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.preview-act-flex .preview-act .act-btns {
    width: 175px;
    height: 95px;
    background: #000000 0% 0% no-repeat padding-box;
    border-radius: 20px;
    font: normal normal 400 14px/18px 'source-sans-pro', sans-serif;
    letter-spacing: 0px;
    color: var(--whiteColor);
}
.chnage-color {
    display: flex;
    align-items: flex-start;
    gap: 15px;
}
.color-area input[type="color"], .color-area input[type="file"]  {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
}
.color-area {
    width: 125px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    position: relative;
    padding-top: 10px;
}
.color-area img {
    width: 24px;
}
.color-area span {
    font: normal normal 400 18px/20px 'source-sans-pro', sans-serif;
    letter-spacing: 0px;
    color: var(--whiteColor);
}

.chnage-color .flex-btn {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
}
.chnage-color .flex-btn .input-color {
    display: flex;
    gap: 10px;
}
.chnage-color .flex-btn .onboard-btn-f {
    width: 125px !important;
    height: 30px !important;
}

.certificate.box-outer .uploadimg {
    width: 175px;
    display: flex;
    align-items: center;
}
.certificate.box-outer .uploadimg label {
    text-align: center;
}
.certificate.box-outer .uploadimg svg {
    width: 40px;
}
.certificate.box-outer .flex-start {
    display: flex;
    align-items: center;
    gap: 50px;
}
.certificate.box-outer .flex-start .action {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
.certificate.box-outer .flex-start .action button {
    background: #000000 0% 0% no-repeat padding-box;
    border-radius: 20px;
    width: 180px;
    height: 90px;
    font: normal normal 400 16px/20px 'source-sans-pro', sans-serif;
    letter-spacing: 0px;
    color: var(--whiteColor);
    display: flex;
    align-items: center;
    justify-content: center;
}
.certificate.box-outer .flex-start .action button span {
    display: block;
    width: 100px;
    text-align: center;
}

.certificate.box-outer .flex-start .color-areas {
    position: absolute;
    width: 75px;
    height: 50px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: none !important;
}

.certificate.box-outer .flex-start .color-areas input[type="color"] {
    width: 50px;
    height: 50px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    padding: 5px;
    border: none !important;
}
.templates-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    gap: 40px;
}
.templates-list .template-item {
    background: var(--body-dark-bg) 0% 0% no-repeat padding-box;
    border: 3px solid #5A5A5A;
    border-radius: 10px;
    width: 100%;
    padding: 8px;
}

.templates-list .template-item.active {
    border: 3px solid var(--primary-color);
}

@media only screen and (max-width: 1366px) {
    .elements-flex.elements-text {
        grid-template-columns: repeat(2, 1fr);
    }
    .elements-flex .item {
        width: 275px;
        max-width: 100%;
    }
    .elements-list .img-item {
        width: 125px;
        height: 125px;
        max-width: 100%;
    }
}

@media only screen and (max-width: 1280px) {
    .elements-flex.elements-text {
        grid-template-columns: repeat(2, 1fr);
    }
    .elements-flex .item {
        width: 275px;
        max-width: 100%;
    }
    .elements-list .img-item {
        width: 125px;
        height: 125px;
        max-width: 100%;
    }
}

.Toastify {
    width: 500px !important;
}
.Toastify__toast-container.Toastify__toast-container--top-center {
    width: 500px !important;
    height: 50px !important;
}