@media only screen and (max-width: 1280px) {
    .css-13cymwt-control, .css-t3ipsp-control, .react-select-container {
        width: 100% !important;
    }
}
@media only screen and (max-width: 1125px) {
    .admin-users .permission-btn {
        font: normal normal 400 12px/30px 'source-sans-pro', sans-serif !important;
        width: max-content;
        padding-left: 10px;
        padding-right: 10px;
    }
    .css-13cymwt-control, .css-t3ipsp-control, .react-select-container {
        width: 100% !important;
    }
}

@media only screen and (max-width: 992px) {
    .filter-body .filter-list .filter-item .filter-input, .filter-body .filter-list {
        flex-direction: row;
    }
    .box-outer {
        height: max-content;
    }
    .box-inner {
        padding-bottom: 25px;
    }
    .css-13cymwt-control, .css-t3ipsp-control, .react-select-container {
        width: 100% !important;
    }
}
@media only screen and (max-width: 925px) {
    .filter-body .filter-list .filter-item .filter-input {
        flex-direction: row;
    }
    .filter-body .filter-list {
        flex-direction: column;
    }
    .box-outer {
        height: max-content;
    }
    .box-inner {
        padding-bottom: 25px;
    }
    .css-13cymwt-control, .css-t3ipsp-control, .react-select-container {
        width: 100% !important;
    }
}

@media only screen and (max-width: 778px) {
    .box-inner .action-flex, .act-flex {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 15px;
    }
    .css-13cymwt-control, .css-t3ipsp-control, .react-select-container {
        width: 100% !important;
    }
}

@media only screen and (max-width: 600px) {
    .filter-body .filter-list .filter-item .filter-input {
        flex-direction: column;
    }
    .filter-body .filter-list {
        flex-direction: column;
    }
    .box-outer {
        height: max-content;
    }
    .box-inner {
        padding-bottom: 25px;
    }

    .permission-flex, .permission-flex .act-permission, .user-status-flex {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .css-13cymwt-control, .css-t3ipsp-control, .react-select-container {
        width: 100% !important;
    }
    .theme-list-flex {
        grid-template-columns: repeat(2, 1fr);
    }
    
}


