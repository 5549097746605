.cart-img {
  width: 28%;
  border-radius: 20px;
  height: 100%;
}

.deleteIconCart {
  display: flex;
  justify-content: flex-end;
  padding: 0px 3px 7px 0px;
}

.deleteIconCart span svg {
  fill: var(--whiteColor);
  cursor: pointer;
}

.list-item-cart {
  height: 90px;
  display: flex;
  flex-direction: row;
  padding: 6px;
  gap: 5px;
}

.list-item-cart:hover {
  border: 1px solid var(--primary-color);
  opacity: 1;
}

.cart-courses {
  display: flex;
  flex-direction: column;
  width: 145px;
}

.cart-text {
  font: normal normal normal 15px/23px Source Sans Pro;
  letter-spacing: 0px;
  color: var(--whiteColor);
  opacity: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cart-course-details {
  display: flex;
  flex-direction: row;
  gap: 3px;
  align-items: flex-start;
  padding: 5px 0px;
}

.cart-price-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  /* padding: 7px; */
}

.cart-buy-now-btn {
  background: var(--primary-color) 0% 0% no-repeat padding-box;
  /* border: 1px solid var(--primary-color); */
  border-radius: 18px;
  opacity: 1;
  font: normal normal bold 15px Source Sans Pro;
  color: #28282b;
  opacity: 1;
  width: 100px;
  height: 30px;
}

.price-text {
  font: normal normal normal 15px/23px Source Sans Pro;
  letter-spacing: 0px;
  color: var(--whiteColor);
  opacity: 1;
}

.cart-review {
  font: normal normal normal 12px/23px Source Sans Pro;
  letter-spacing: 0px;
  color: var(--whiteColor);
  opacity: 1;
}

.cart-menu {
  position: absolute;
  padding: 5px 10px;
  width: 442px;
}

.delete-cart-icon {
  display: flex;
  align-items: flex-start;
  padding-top: 4px;
  padding-left: 10px;

  /* justify-content: flex-end; */
  /* margin-top: -5px;
  margin-left: 10px; */
}

.delete-cart-icon svg {
  fill: var(--whiteColor);
  cursor: pointer;
}

.cat-course-info {
  gap: 25px;
}

.cat-topics-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cart-container .loading h1 {
  color: var(--whiteColor) !important;
  font: normal normal 200 21px/30px 'source-sans-pro', sans-serif !important;
}

.cart-container .loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 75vh;
}

.cart-container .loading h1::after {
  content: "";
  border: none !important;
}