tbody,
td,
tfoot,
th,
thead,
tr {
  color: var(--whiteColor);
}

.box-outer {
  background: var(--dark-bg) 0% 0% no-repeat padding-box;
  border-radius: 13px;
  width: 100%;
  height: 100%;
  min-height: 85vh;
  padding: 10px;
}

/* .box-outer.editCourse {
    padding: 35px;
} */
.box-inner {
  background: var(--body-dark-bg) 0% 0% no-repeat padding-box;
  border-radius: 13px;
  width: 100%;
  height: 100%;
  padding: 20px;
}

.box-outer.editCourse .heading h1 {
  font: normal normal 400 18px/22px "Source Sans Pro", sans-serif;
  letter-spacing: 0px;
  color: var(--whiteColor);
  margin-bottom: 25px;
}

.box-outer.right .box-inner {
  padding: 20px;
}

.filter {
  width: 100%;
  width: 300px !important;
  padding-left: 10px;
  padding-top: 45px;
  position: relative;
}

.filter .filter-heading {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 50px;
}

.filter .filter-heading span {
  font: normal normal normal 14px/18px 'Avenir', sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
}

.filter .filter-heading span.icon {
  position: static;
  transform: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 31px;
  height: 24px;
  background: var(--dark-bg) 0% 0% no-repeat padding-box;
  border-radius: 8px;
}

.filter-body .filter-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  gap: 35px;
}

.filter-body .filter-list .filter-item {
  width: 100%;
}

.filter-body .filter-list .filter-item .filter-name {
  font: normal normal 400 16px/20px 'Avenir', sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
  margin-bottom: 15px;
}

.filter-body .filter-list .filter-item .filter-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.filter-body .filter-list .filter-item .filter-input input,
.filter-body .filter-list .filter-item .filter-input select {
  width: 100%;
  max-width: 100%;
  height: 35px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: var(--dark-bg);
  border-radius: 10px;
  font: normal normal 400 16px/20px 'Avenir', sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
  border: none;
  outline: none;
}

input[type="date"]:invalid::-webkit-datetime-edit {
  color: var(--whiteColor);
}

.filter-body .filter-list .filter-item .filter-input .input-box {
  position: relative;
  width: 100% !important;
  max-width: 100% !important;
}

.input-box .icon {
  position: absolute;
  pointer-events: none;
}

.box-outer .action-flex {
  margin-bottom: 15px;
}

.box-outer .action-flex .courses-act {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.box-outer .action-flex .action-item {
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--dark-bg) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  font: normal normal 400 17px/22px 'Avenir', sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
}

.box-outer .action-flex .action-item.cohorts {
  width: 200px;
}

.box-outer .action-flex .courses-act .action-item {
  width: 200px;
}

.box-outer .action-flex .action-item.back a {
  display: flex;
  align-items: center;
  gap: 10px;
}

textarea.form-control {
  height: 265px !important;
}

.action-item a {
  color: var(--whiteColor);
}

.box-inner .action-flex .action-item.active {
  background: var(--dark-bg) 0% 0% no-repeat padding-box;
  /* border: 2px solid var(--primary-color); */
  outline: 2px solid var(--primary-color) !important;
}

.box-inner .action-flex .action-item:hover {
  background: var(--dark-bg) 0% 0% no-repeat padding-box;
  /* border: 2px solid var(--primary-color); */
  outline: 2px solid var(--primary-color);
}

.box-inner .action-flex .action-item.btn:hover {
  outline: none !important;
}

.box-outer.right .box-inner .search-bar {
  width: 100%;
  /* margin-bottom: 15px; */
  display: flex;
}

.box-outer.right .box-inner .search-bar input {
  width: 100%;
  height: 35px;
  background: var(--body-dark-bg) 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 8px !important;
  padding-left: 15px;
  font: normal normal 400 15px/18px 'Avenir', sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
}

.box-outer.right .box-inner .search-bar input::placeholder {
  font: normal normal 400 15px/18px 'Avenir', sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
}

.act-flex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;
  gap: 10px;
}

.permission-flex {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
}

.multiple-permission {
  width: 100%;
}

.permission-flex .multiple-permission select {
  width: 100%;
  height: 35px;
  background: var(--chips-color) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  /* font: normal normal 400 18px/22px 'source-sans-pro', sans-serif !important; */
  font: normal normal 400 13px/16px 'Avenir', sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
  border: none !important;
  padding-left: 10px;
  padding-right: 10px;
}

.permission-flex .act-permission {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  gap: 10px;
}

.permission-flex .act-permission button {
  background: var(--chips-color) 0% 0% no-repeat padding-box;
  border-radius: 34px;
  width: 100px;
  height: 35px;
  font: normal normal 400 13px/16px 'Avenir', sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
}

.preset-roles button {
  background: var(--chips-color) 0% 0% no-repeat padding-box;
  border: 2px solid var(--primary-color);
  border-radius: 34px;
  width: 115px;
  height: 35px;
  font: normal normal 400 13px/16px 'Avenir', sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
}

.user-status-flex {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.user-status-flex .user-status,
.user-status {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 125px;
  height: 30px;
  background: var(--chips-color) 0% 0% no-repeat padding-box;
  border-radius: 18px;
  font: normal normal 600 15px/30px 'Avenir', sans-serif !important;
  letter-spacing: 0px;
}

.user-status-flex .course-status {
  width: 164px;
  height: 51px;
  background: var(--body-dark-bg) 0% 0% no-repeat padding-box;
  border-radius: 18px;
}

.user-status-flex .user-status.all {
  border: 2px solid #d3d5d3;
  color: #fdfffc;
}

.user-status-flex .user-status.active,
.user-status.active {
  border: 2px solid #39ff14;
  color: #39ff14;
}

.user-status-flex .user-status.inactive,
.user-status.inactive {
  border: 2px solid #f03d3d;
  color: #f03d3d;
}

.user-status-flex .user-status.unassigned {
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
}

.user-status.cstatus span {
  opacity: 0;
}
.admin-users.courses-list .table-responsive {
  height: 100%;
  overflow-y: auto;
  padding-right: 20px;
}

.admin-users.cohorts .table-responsive {
  height: 460px;
}

.admin-users table tbody {
  border-collapse: separate !important;
  border-spacing: 0px 10px !important;
}

.admin-users table > :not(:last-child) > :last-child > * {
  border-bottom: none;
}

.admin-users table thead tr {
  height: 0px !important;
}

.admin-users table thead tr th {
  font: normal normal 400 16px/18px 'Avenir', sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
}

.admin-users table tbody tr td {
  font: normal normal 400 16px/18px 'Avenir', sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
}

.admin-users table tbody tr td {
  background: var(--chips-color) 0% 0% no-repeat padding-box;
  /* height: 65px; */
}

.admin-users table tbody tr.active {
  border: 2px solid var(--primary-color) !important;
}

.admin-users table tbody tr td .user-flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.admin-users table tbody tr td .user-flex .user-pic.course-img {
  width: 75px;
  height: 50px;
}

.admin-users table tbody tr td .user-flex .user-pic.course-img img {
  /* width: 100%;
    height: 100%;
    object-fit: cover; */
  width: 75px;
  height: 50px;
  border-radius: 8px;
}

.admin-users table tbody tr td .user-flex .user-info {
  display: flex;
  flex-direction: column;
}

.admin-users table tbody tr td .user-flex .user-info span.name {
  font: normal normal 400 14px/18px 'Avenir', sans-serif !important;
}

.admin-users table tbody tr td .user-flex .user-info span.role {
  font: normal normal 400 12px/16px 'Avenir', sans-serif !important;
  color: var(--whiteColor);
}

.admin-users table > :not(caption) > * > * {
  border: none !important;
}

.admin-users .permission-btn {
  height: 30px;
  background: var(--body-dark-bg) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  font: normal normal 400 15px/30px 'Avenir', sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
  padding-left: 10px;
}

.admin-users .table-act {
  display: flex;
  align-items: center;
  gap: 5px;
}

.admin-users .table-act button.edit span svg {
  stroke: #ffc107 !important;
  fill: #ffc107 !important;
}

.admin-users .table-act button.delete span svg {
  fill: #dc3545 !important;
}

.admin-users .table-act button.view svg {
  fill: var(--primary-color);
}

.admin-users .table-act button {
  background: var(--body-dark-bg) 0% 0% no-repeat padding-box;
  border-radius: 18px;
  width: max-content;
  padding-left: 15px !important;
  padding-right: 15px !important;
  height: 30px;
  font: normal normal 500 15px/25px 'Avenir', sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.delete span svg {
  fill: #dc3545 !important;
}

.edit span svg {
  stroke: #ffc107 !important;
  fill: #ffc107 !important;
}

.draft span svg {
  /* stroke: #ffc107 !important; */
  /* fill: #EA7773 !important; */
  /* fill: #ff6700 !important; */
  fill: var(--nav-inactive) !important;
}

.manage-learners span svg {
  fill: #7cec9f !important;
}
/* .delete span {
  color: #dc3545 !important;
  color: #ffffff !important;
} */

.admin-users .table-act button a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

/* .admin-users .table-act button:hover {
  /* border: 2px solid var(--primary-color); */
/* outline: 2px solid var(--primary-color); 
} */

.admin-users.cohorts .table-act button {
  width: 40px;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  font: normal normal 500 14px/18px 'Avenir', sans-serif !important;
}

/* .admin-users.cohorts .table-act button:hover {
    border: 1px solid var(--primary-color);
} */

.admin-users table tbody tr.active td {
  border-top: 2px solid var(--primary-color) !important;
  border-bottom: 2px solid var(--primary-color) !important;
}

.admin-users table tbody tr td:first-child {
  border-radius: 12px 0px 0px 12px;
}

.admin-users table tbody tr td:last-child {
  border-radius: 0px 12px 12px 0px;
}

.admin-users table tbody tr.active td:first-child {
  border-left: 2px solid var(--primary-color) !important;
}

.admin-users table tbody tr.active td:last-child {
  border-right: 2px solid var(--primary-color) !important;
}

.checkbox {
  position: relative;
}

.checkbox .styled-checkbox {
  position: absolute;
  opacity: 0;
  z-index: 100;
  width: 20px;
  height: 20px;
}

.checkbox .styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
}

.checkbox .styled-checkbox + label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background-color: var(--chips-color);
  /* background-color: transparent !important; */
  border: 1px solid #707070;
}

.checkbox .styled-checkbox:hover + label:before {
  background: var(--primary-color);
}

/* .checkbox .styled-checkbox:focus + label:before {
  /* box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12); 
} */

.checkbox .styled-checkbox:checked + label:before {
  background: var(--primary-color);
}

.checkbox .styled-checkbox:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}

.checkbox .styled-checkbox:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}

.checkbox .styled-checkbox:checked + label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 9px;
  background: black;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 black, 4px 0 0 black, 4px -2px 0 black, 4px -4px 0 black,
    4px -6px 0 black, 4px -8px 0 black;
  transform: rotate(45deg);
}

.cust-select {
  width: 600px;
  background: var(--chips-color) 0% 0% no-repeat padding-box !important;
  border: 1px solid #707070;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.cust-select .cust-select-label {
  background: var(--chips-color) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  width: 245px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cust-select .cust-select-label label {
  font: normal normal 400 18px/22px 'Avenir', sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
}

.css-13cymwt-control,
.css-t3ipsp-control,
.react-select-container {
  width: 575px !important;
  background: transparent !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.css-qbdosj-Input,
.css-166bipr-Input,
.css-1jqq78o-placeholder {
  color: var(--whiteColor) !important;
}

.css-1p3m7a8-multiValue {
  background: var(--body-dark-bg) 0% 0% no-repeat padding-box !important;
  border-radius: 34px !important;
  color: var(--whiteColor) !important;
  padding-left: 5px;
  padding-right: 5px;
}

.form-flex .css-1p3m7a8-multiValue {
  background: #3c3c3e 0% 0% no-repeat padding-box !important;
}

.css-wsp0cs-MultiValueGeneric {
  color: var(--whiteColor) !important;
}

.react-select__menu {
  background-color: var(--body-dark-bg) !important;
  /* color: var(--whiteColor); */
}

.react-select__menu-list .react-select__option,
.react-select-container:hover .react-select__option,
.react-select__menu-list .react-select__option:visited,
.react-select__option:hover,
.react-select__option:focus {
  background-color: var(--chips-color);
}

.css-1u9des2-indicatorSeparator {
  display: none !important;
}

.box-outer.addUser {
  /* padding: 35px; */
  height: 100%;
}

.box-outer.addUser .heading h1 {
  font: normal normal 400 20px/24px "Source Sans Pro", sans-serif;
  letter-spacing: 0px;
  color: var(--whiteColor);
  margin-bottom: 25px;
}

.bulk-upload-area {
  width: 950px;
  max-width: 100%;
  height: 145px;
  background: var(--dark-bg) 0% 0% no-repeat padding-box;
  border: 2px dashed var(--primary-color);
  border-radius: 18px;
  position: relative;
  display: block;
  margin: 0 auto;
  margin-bottom: 25px;
}

.bulk-upload-area .info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 225px;
  height: 35px;
  background: var(--body-dark-bg) 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal 400 16px/20px "Source Sans Pro", sans-serif;
  letter-spacing: 0px;
  color: var(--whiteColor);
}

.bulk-upload-area input[type="file"] {
  opacity: 0;
  position: absolute;
  z-index: 5;
  width: 100%;
  height: 100%;
}

.add-user-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-user-form .mb-3 {
  width: 705px;
  max-width: 100%;
}

.add-user-form label {
  font: normal normal 400 16px/20px "Source Sans Pro", sans-serif;
  letter-spacing: 0px;
  color: var(--whiteColor);
}

.add-user-form .input-box {
  max-width: 100% !important;
}

.add-user-form .input-box .form-control {
  background: var(----dark-bg) 0% 0% no-repeat padding-box;
  background-color: var(--dark-bg) !important;
  border-radius: 16px;
  width: 100%;
  height: 45px;
  padding-left: 10px;
  padding-right: 10px;
  border: none;
  box-shadow: none;
  font: normal normal 400 16px/20px "Source Sans Pro", sans-serif;
  letter-spacing: 0px;
  color: var(--whiteColor);
  margin-bottom: 0px;
}

input:-internal-autofill-selected {
  background-color: var(--dark-bg) !important;
}

.add-user-form .input-box .form-select {
  width: 100%;
  margin-bottom: 0px;
  background: var(--dark-bg) 0% 0% no-repeat padding-box;
}

.add-user-form .input-box .form-control:focus {
  background: var(--dark-bg) 0% 0% no-repeat padding-box;
}

.define-user {
  display: flex;
  align-items: center;
  gap: 25px;
}

.checkcontainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font: normal normal 400 16px/20px "Source Sans Pro", sans-serif;
  letter-spacing: 0px;
  color: var(--whiteColor);
}

.checkcontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radiobtn {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
}

.checkcontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkcontainer:hover input ~ .radiobtn {
  background-color: #ccc;
}

.checkcontainer input:checked ~ .radiobtn {
  background-color: var(--whiteColor);
}

.radiobtn:after {
  content: "";
  position: absolute;
  display: none;
}

.checkcontainer input:checked ~ .radiobtn:after {
  display: block;
}

.checkcontainer .radiobtn:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: var(--primary-color);
}

.roles {
  width: 950px;
  max-width: 100%;
  display: block;
  margin: 0 auto;
  margin-bottom: 25px;
}

.roles .roles-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.define-role {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 500px;
  max-width: 100%;
}

.define-role label {
  font: normal normal 400 16px/20px "Source Sans Pro", sans-serif;
  letter-spacing: 0px;
  color: var(--whiteColor);
  margin-bottom: 0px;
}

.define-role select {
  background: var(--body-dark-bg) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  width: 200px;
  height: 32px;
  border: none;
  outline: none;
  font: normal normal 400 16px/20px "Source Sans Pro", sans-serif;
  letter-spacing: 0px;
  color: var(--whiteColor);
  padding-left: 10px;
  padding-right: 10px;
}

.act-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.act-btn button {
  width: 140px;
  height: 32px;
  background: var(--body-dark-bg) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  font: normal normal 400 15px/32px "Source Sans Pro", sans-serif;
  letter-spacing: 0px;
  color: var(--body-dark-bg);
}

/* .admin-users.courses-list .table tbody tr {
    height: 115px !important;
}  */

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--primary-color);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--primary-color);
}

input:checked + .slider:before {
  -webkit-transform: translateX(23px);
  -ms-transform: translateX(23px);
  transform: translateX(23px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.box-inner .action-flex .heading h1 {
  font: normal normal 400 24px/30px "Source Sans Pro", sans-serif;
  letter-spacing: 0px;
  color: var(--whiteColor);
}

.form-flex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 35px;
}

.form-flex .text-input .input-box {
  width: 500px;
  max-width: 100%;
  height: 45px;
  margin-bottom: 0px;
}

.form-flex .date-input .input-box {
  width: 200px;
  height: 45px;
}

.form-flex .date-input .input-box span.icon {
  top: 65%;
  right: 10px;
  width: 14px;
}

.form-flex .text-input .input-box input {
  background-color: transparent;
  border: 1px solid #707070 !important;
  border-radius: 10px !important;
}

.form-flex .date-input {
  display: flex;
  align-items: center;
  gap: 15px;
}

.form-flex .css-13cymwt-control,
.css-t3ipsp-control,
.react-select-container {
  width: 100% !important;
}

.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-select ~ label::after {
  background-color: transparent !important;
  color: var(--whiteColor) !important;
}

.form-floating > label {
  color: var(--whiteColor) !important;
  font: normal normal 400 12px/12px "Source Sans Pro", sans-serif;
}

.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  min-height: initial !important;
}

.course-flex {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  height: max-content;
}

.course-flex .course {
  width: 100%;
  height: 145px;
  background: #3c3c3e 0% 0% no-repeat padding-box;
  border-radius: 12px;
  cursor: move;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  padding: 30px;
  margin-bottom: 25px;
}

.course-flex .course .course-count {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.course-flex .course .course-count span {
  font: normal normal 400 36px/48px "Source Sans Pro", sans-serif;
  letter-spacing: 0px;
  color: var(--whiteColor);
}

.course-flex .course .draggable-course {
  width: 800px;
  max-width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.course-flex .course .draggable-course .course-info .course-name {
  font: normal normal 400 20px/26px "Source Sans Pro", sans-serif;
  letter-spacing: 0px;
  color: var(--whiteColor);
  padding: 10px !important;
  margin: 0px !important;
  width: max-content !important;
  height: max-content !important;
}

.course-flex
  .course
  .draggable-course
  .course-info
  .course-name
  .input-box
  .form-control {
  font: normal normal 400 20px/26px "Source Sans Pro", sans-serif;
  letter-spacing: 0px;
  color: var(--whiteColor);
  padding: 10px !important;
  margin: 0px !important;
  height: max-content !important;
}

.course-flex .course .course-info {
  width: 650px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.course-flex .course .course-info button {
  background-color: var(--body-dark-bg);
  color: var(--whiteColor);
}
button.btn.setcons {
  width: max-content !important;
}
.course-flex .course .course-info .course-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.course-flex .course .course-info .course-name span {
  font: normal normal 400 20px/26px "Source Sans Pro", sans-serif;
  letter-spacing: 0px;
  color: var(--whiteColor);
}

.course-flex .course .course-info .conditions {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.course-flex .course .course-info .conditions label {
  font: normal normal 400 14px/20px "Source Sans Pro", sans-serif;
  letter-spacing: 0px;
  color: var(--whiteColor);
  margin-bottom: 5px;
}

.course-flex .course .course-info .conditions .form-select {
  background: var(--body-dark-bg) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: none !important;
  outline: none !important;
  font: normal normal 400 14px/20px "Source Sans Pro", sans-serif;
  letter-spacing: 0px;
  color: var(--whiteColor);
  box-shadow: none !important;
}

.course-flex .course .course-action {
  display: flex;
  align-items: center;
  gap: 10px;
}

.course-flex .course .course-action .btn {
  width: 40px;
  height: 40px;
  background-color: var(--body-dark-bg);
  color: var(--whiteColor) !important;
}

.course-flex .course .course-action .btn svg {
  fill: var(--whiteColor) !important;
}

.course-flex .course .course-action .btn svg path#Path_11890 {
  stroke: var(--whiteColor) !important;
}
.course-flex .course .course-action .btn svg path#Path_11892 {
  fill: var(--whiteColor) !important;
}
.upload-btn svg {
  fill: var(--whiteColor) !important;
}
.reorder-flex .reorder-label {
  font: normal normal 400 15px/20px "Source Sans Pro", sans-serif;
  letter-spacing: 0px;
  color: var(--whiteColor);
}

.flex-end .input-btn {
  background: var(--dark-bg) 0% 0% no-repeat padding-box;
  border: 2px solid var(--primary-color);
  border-radius: 10px;
  width: 130px;
  height: 35px;
  font: normal normal 400 15px/20px "Source Sans Pro", sans-serif;
  letter-spacing: 0px;
  color: var(--whiteColor);
}

.box-outer.editCourse .nav-tabs .nav-item.show .nav-link,
.box-outer.editCourse .nav-tabs .nav-link {
  background: #757272 0% 0% no-repeat padding-box;
  border: none !important;
  border-radius: 10px;
  padding: 0px !important;
  font: normal normal 400 16px/20px "Source Sans Pro", sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
  width: 100px;
  height: 40px;
}

.box-outer.editCourse .nav-tabs {
  gap: 15px;
}

.box-outer.editCourse .nav-tabs .nav-item.show .nav-link,
.box-outer.editCourse .nav-tabs .nav-link.active {
  background: #010001 0% 0% no-repeat padding-box !important;
  border: 2px solid var(--primary-color) !important;
}

.box-outer.editCourse .add-user-form .input-box .form-control {
  background: var(----dark-bg) 0% 0% no-repeat padding-box;
  border-radius: 16px;
  width: 100% !important;
  height: 45px;
  padding-left: 12px;
  padding-right: 10px;
  border: none !important;
  box-shadow: none;
  font: normal normal 400 14px/20px "Source Sans Pro", sans-serif;
  letter-spacing: 0px;
  color: var(--whiteColor);
  margin-bottom: 0px;
}

.form-flex .mb-3 {
  display: flex;
}

.form-flex .input-flex {
  display: flex;
  align-items: center;
  gap: 15px;
}

.box-outer.editCourse .form-flex {
  justify-content: center !important;
}

.box-outer.editCourse .add-user-form .mb-3 {
  width: 800px !important;
  max-width: 100%;
}

.box-outer.addUser.createCourse .bulk-upload-area {
  width: 375px;
  max-width: 100%;
  height: 200px !important;
  margin: 0 !important;
}

.box-outer.addUser.createCourse .bulk-upload-area img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 18px;
}

.box-outer.addUser.createCourse .action-flex .action-item {
  background: var(--chips-color) 0% 0% no-repeat padding-box;
}

.box-outer.addUser.createCourse .action-flex {
  margin-bottom: 30px;
}

.box-outer.addUser.createCourse .action-flex .action-item.active {
  background: var(--dark-bg) 0% 0% no-repeat padding-box;
  border: 2px solid var(--primary-color);
}

.certificate-area {
  background: var(--body-dark-bg) 0% 0% no-repeat padding-box;
  border-radius: 15px;
  width: 100%;
  height: max-content;
  margin-bottom: 25px;
  padding: 20px;
}

.certificate-preview {
  width: 100%;
  height: 600px;
  background: var(--chips-color) 0% 0% no-repeat padding-box;
  background: #ffffff !important;
  border: 0.25px solid #a5a5a5;
  border-radius: 20px;
  overflow: auto;
  padding-right: 10px;
}

/* .certificate-preview .konvajs-content {
    width: 100%;
    height: 100%;
    object-fit: cover;
} */

.certificate-preview > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.certificate-elements .addedElements {
  width: 650px;
  max-width: 100%;
}

.certificate-elements .elements-flex .item {
  width: 300px;
}

.certificate-elements .elements-flex .img-item {
  height: max-content;
  background: var(--chips-color) 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 10px;
  padding: 10px;
}

.elements-flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.certificate-elements .elements-flex.elements-text {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.certificate-elements .elements-flex.elements-upload {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 20px;
}

.certificate-elements .elements-list .img-item {
  width: 145px;
  height: 145px;
  padding: 10px;
}

.certificate-elements .mb-3 label {
  display: block;
}

.certificate-preview .elements-flex .item {
  width: 150px;
}

canvas .item {
  background-color: #39ff14;
  width: 100px;
  height: 50px;
}

.courseupload-area,
.preview-course {
  background: var(--body-dark-bg) 0% 0% no-repeat padding-box;
  border-radius: 15px;
  width: 100%;
  /* height: 500px; */
  height: max-content;
  margin-bottom: 25px;
  padding: 20px;
}

.elements-flex.action-btn .item {
  width: 145px;
  color: var(--whiteColor);
}

.elements-flex.action-btn input.item {
  background-color: var(--primary-color) !important;
  color: var(--body-dark-bg) !important;
  font: normal normal 400 14px/18px 'Avenir', sans-serif !important;
}

.add-elemnet-btn .item {
  background-color: var(--primary-color) !important;
  color: var(--body-dark-bg) !important;
  font: normal normal 400 14px/18px 'Avenir', sans-serif !important;
}

.elements-flex.action-btn .item.applybtn {
  background-color: var(--primary-color) !important;
}

.elements-flex.action-btn .item.applybtn span {
  color: var(--body-dark-bg) !important;
}

.elements-flex.action-btn {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.container-fluid .certificate-area .row > div {
  margin-bottom: 0px !important;
}

.elements-flex .item span {
  font: normal normal 400 14px/18px 'Avenir', sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
}

.course-img-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 25px;
}

.course-img-flex .bulk-upload-area {
  width: 300px;
  height: 200px;
}

.course-img-flex .bulk-upload-area img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.select-cerele {
  margin-bottom: 25px;
}

.select-cerele .input-box .form-control,
.input-box .form-select {
  margin-bottom: 10px;
  width: 100% !important;
}

.img-fluid-course {
  border-radius: 20px;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.loading.load-anim {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1056;
}

.select-cerele .input-box input[type="file"] {
  padding-left: 20px !important;
  padding-top: 15px !important;
}

.css-1nmdiq5-menu {
    /* color: var(--whiteColor) !important; */
    background-color: var(--dark-bg) !important;
}

.accordion-button:not(.collapsed) {
  background-color: var(--primary-color) !important;
  color: var(--body-dark-bg) !important;
}

.accordion-item {
  margin-bottom: 10px !important;
}

.font-color .input-box .form-control {
  width: 75px !important;
}

.templates-list {
  height: max-content;
}

.modal-body .flex-end .onboard-btn {
  font: normal normal normal 15px/20px 'Avenir', sans-serif !important;
}

/* .css-b62m3t-container {
  width: 500px;
  max-width: 100%;
} */

.rdw-editor-main {
  background-color: var(--dark-bg) !important;
  color: var(--whiteColor) !important;
  padding: 10px !important;
}

@media only screen and (max-width: 1290px) {
  .form-flex {
    flex-direction: column !important;
  }

  .certificate-elements .elements-flex.elements-upload {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media only screen and (max-width: 992px) {
  .form-flex {
    flex-direction: column !important;
  }
}

@media only screen and (max-width: 768px) {
  .form-flex {
    flex-direction: column !important;
  }

  .course-flex .course .course-info .conditions {
    width: 400px !important;
  }

  .form-flex .text-input .input-box {
    width: 450px !important;
  }
}

@media only screen and (max-width: 600px) {
  .box-inner .action-flex .courses-act {
    flex-direction: column;
    width: 100%;
  }

  .box-inner .action-flex .courses-act .action-item {
    width: 100% !important;
  }

  .form-flex {
    flex-direction: column !important;
  }

  .course-flex .course .course-info .conditions {
    width: 250px !important;
  }

  .form-flex .text-input .input-box {
    width: 450px !important;
  }

  .course-flex .course {
    padding: 10px !important;
  }
}

@media only screen and (max-width: 450px) {
  .form-flex .text-input .input-box {
    width: 100% !important;
  }

  .course-flex .course .course-info .conditions {
    width: 150px !important;
  }

  .course-flex .course {
    padding: 10px !important;
  }
}
