.course-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    height: 350px;
    overflow: auto;
}
.course-list .course-item {
    width: 100%;
    padding: 0px 10px;
}
.course-list .course-item button {
    width: 100%;
    height: 60px;
    background: var(--body-dark-bg) 0% 0% no-repeat padding-box;
    border-radius: 14px;
    font: normal normal 400 15px/20px 'source-sans-pro', sans-serif !important;
    letter-spacing: 0px;
    color: var(--whiteColor);
    padding: 10px;
}
.course-list .course-item button:hover, .course-list .course-item.active button {
    border: 2px solid var(--primary-color);
}
.course-list .course-item:last-child button {
    background: var(--chips-color) 0% 0% no-repeat padding-box;
}
.admin-dash .cflex {
    margin-bottom: 15px;
}
.admin-dash .outer-box .row {
    align-items: center;
}
.learners-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 30px;
}
.learners-grid .learner-item {
    flex: 1 0 150px;
}
.learners-grid .learner-item button, .learner-item button {
    width: 100%;
    height: 80px;
    background: var(--body-dark-bg) 0% 0% no-repeat padding-box;
    border-radius: 18px;
    font: normal normal normal 16px/20px Source Sans Pro;
    letter-spacing: 0px;
    color: var(--whiteColor);
    padding: 0px 16px;
}
.learners-grid .learner-item button svg, .learner-item button svg {
    fill: var(--nav-inactive);
}
.learners-grid .learner-item button:hover, .learner-item button:hover {
    border: 2px solid var(--primary-color);
}
.learners-grid .learner-item button:hover svg, .learner-item button:hover svg {
    fill: var(--primary-color);
}
.admin-dash .user-status-flex .course-status {
    width: 164px;
    height: 51px;
    background: var(--dark-bg) 0% 0% no-repeat padding-box;
    border-radius: 18px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
}
.admin-dash .user-status-flex .course-status span {
    font: normal normal normal 14px/18px 'Avenir', sans-serif !important;
    letter-spacing: 0px;
    color: var(--whiteColor);
}

.admin-dash .user-status-flex .course-status.btn {
    background: var(--primary-color) 0% 0% no-repeat padding-box;
   
}
.admin-dash .user-status-flex .course-status.btn span {
    color: var(--body-dark-bg);
}
.admin-calendar .calendar {
    width: 250px;
    margin-bottom: 0px;
}
.events {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start !important;
    background: var(--body-dark-bg) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    width: 175px;
    gap: 30px; 
    padding: 10px;
    margin-bottom: 0px !important;
}
.event.events {
    width: 100%;
}
.flex-cal {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    gap: 10px;
}
.events .events-item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: 25px;
    background-color: var(--chips-color);
    padding: 10px;
    border-radius: 10px;
}
.events .events-item .date {
    font: normal normal 400 14px/18px 'Avenir', sans-serif !important;
    letter-spacing: 0px;
    color: var(--whiteColor);
    text-align: center;
}
.events .events-item .info {
    font: normal normal 400 14px/18px 'Avenir', sans-serif !important;
    letter-spacing: 0px;
    color: var(--whiteColor);
    text-align: center;
}

.cflex button {
    width: 150px !important;
    background: var(--body-dark-bg) 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 10px;
    font: normal normal 600 12px/16px 'Avenir', sans-serif !important;
    letter-spacing: 0px;
    color: var(--whiteColor);
    padding: 10px;
}
.cflex button:hover {
    border: 2px solid var(--primary-color);
}
.admin-users.courses-list.dash-course-list {
    height: 550px;
}
.admin-users canvas {
    width: 600px !important;
    height: 300px !important;
}

.admin-onboard.flex-center {
    width: 750px;
    max-width: 100%;
    margin: 0 auto;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.admin-onboard.flex-center .admin-per {
    height: 200px;
}

.admin-per .multiple-input.admin {
    height: initial !important;
}
@media only screen and (min-width: 1920px) {
    .course-list {
        height: 450px;
    }
    .learners-grid .learner-item button, .learner-item button {
        height: 130px;
    }
}

@media only screen and (min-width: 1920px) {
    .course-list {
        height: 380px;
    }
    .learners-grid .learner-item button, .learner-item button {
        height: 130px;
    }
    .outer-box.filter-courses, .outer-box.quick-actions {
        height: 465px !important;
    }
}

@media only screen and (min-width: 1366px) {
    .outer-box.filter-courses, .outer-box.quick-actions {
        height: 465px !important;
    }
}

@media only screen and (max-width: 1280px) {
    .course-list {
        height: 340px;
    }
    .outer-box.filter-courses, .outer-box.quick-actions {
        height: 425px !important;
    }
}
@media only screen and (max-width: 1024px)  {
    .learners-grid {
        overflow: auto;
        height: 358px;
    }
    .outer-box.filter-courses, .outer-box.quick-actions {
        height: 425px;
    }
    .user-status-flex {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }
}
@media only screen and (max-width: 992px) {
    .user-status-flex .user-status, .user-status {
        width: 100%;
    }
    .user-status-flex {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }
    .course-list {
        flex-direction: row;
        height: max-content;
        padding-bottom: 10px;
      
    }
    .main__section .row {
        margin-bottom: 0px;
    }

    .course-list .course-item {
        padding-left: 0px;
        padding-right: 0px;
    }
    .course-list .course-item button {
        width: 125px;
    }
    .outer-box.filter-courses, .outer-box.quick-actions {
        height: max-content !important;
    }
    .flex-cal {
        flex-direction: row;
    }
}

@media only screen and (max-width: 915px) {
    .course-list .course-item button {
        width: 120px;
    }
    .learners-grid {
        height: auto;
    }
    .user-status-flex {
        grid-template-columns: repeat(4, 1fr);
    }
}


@media only screen and (max-width: 768px) {
    .course-list {
        flex-direction: row;
    }
    .main__section .row {
        margin-bottom: 0px;
    }
    .course-list .course-item button {
        width: 150px;
    }
    .user-status-flex {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 600px) {
    .course-list {
        flex-direction: row;
    }
    .main__section .row {
        margin-bottom: 0px;
    }
    .flex-cal {
        flex-direction: column;
        align-items: center;
    }
    .react-calendar {
        width: 100% !important;
    }
    .admin-dash .cflex {
        flex-direction: row;
    }
    .admin-dash .user-status-flex .course-status {
        width: 100% !important;
    }

    .box-outer.left{
        height: max-content !important;
        min-height: max-content !important;
        margin-bottom: 16px;
    }

    .outer-box.filter-courses{
        height: 465px !important;
    } 
    .outer-box.quick-actions {
        height: max-content !important;
    }
    .search-container-courses {
        flex-direction: column;
        margin-bottom: 20px;
    }
    .searchBarCourses {
        width: 100% !important;
    }
    .estoreLms-btn-course {
        align-items: center;
        justify-content: center;
    }
    .user-status-flex .user-status, .user-status {
        width: 100%;
    }
    .user-status-flex {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 435px) {
    .course-list .course-item button {
        width: 115px;
    }
}


@media only screen and (max-width: 415px) {
    .course-list .course-item button {
        width: 110px;
    }
}

@media only screen and (max-width: 400px) {
    .course-list .course-item button {
        width: 101px;
    }
}

@media only screen and (max-width: 380px) {
    .course-list .course-item button {
        width: 150px;
    }
}

@media only screen and (max-width: 365px) {
    .course-list .course-item button {
        width: 145px;
    }
}

@media only screen and (max-width: 300px) {
    .course-list .course-item button {
        width: 100px;
    }
    .user-status-flex {
        grid-template-columns: repeat(1, 1fr);
    }
    .notifications {
        display: none;
    }
    .admin-dash .cflex {
        flex-direction: column;
    }
    .user-status-flex {
        grid-template-columns: repeat(1, 1fr);
    }
}