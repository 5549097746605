body {
    top: 0px !important;
}
.nav-tabs .nav-item.show .nav-link, .box-outer.editCourse .nav-tabs .nav-link {
    background-color: #757272 !important;
    border:  none !important;
    border-radius: 10px !important;
    padding: 0px !important;
    font: normal normal 400 16px/20px 'Avenir', sans-serif !important;
    letter-spacing: 0px;
    color: var(--whiteColor) !important;
    width: 100px;
    height: 40px;
}
.nav-tabs {
    gap: 15px;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: var(--body-dark-bg) !important;
    border: 2px solid var(--primary-color) !important;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: var(--whiteColor) !important;
}
.nav-tabs .nav-link {
    border-radius: 10px !important;
    border: none !important;
}
.nav-link {
    padding: 0px !important;
    background-color: var(--dark-bg) !important;
    color: var(--whiteColor) !important;
    border: none;
}
.nav-link:hover, .nav-link:focus {
    padding: 0px !important;
    background-color: var(--dark-bg) !important;
    color: var(--whiteColor) !important;
    border: none;
}

.sidebar-toggle.welcome-text {
    padding-left: 30px;
    padding-right: 35px;
    margin-top: 0px;
    margin-bottom: 0px;
    height: 15px !important;
    display: flex;
    align-items: center;
}