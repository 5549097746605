.nav-item.disabled {
  cursor: not-allowed !important;
}

.cert-flex {
  flex-direction: column;
  gap: 20px;
}

.cert-flex .iframe {
  height: 600px;
}

.cert-down {
  width: max-content !important;
  /* height: 35px; */
  background-color: var(--body-dark-bg);
  /* padding: 10px; */
  color: var(--whiteColor) !important;
}
.cert-down button {
  width: 200px;
  background-color: var(--primary-color);
  color: var(--whiteColor) !important;
  border-radius: 10px;
  color: #000000 !important;
}

.cert-down.admin button {
  width: max-content !important;
  background-color: transparent !important;
}
.cert-down:hover {
  color: #000000 !important;
}

.act-btn button.reset-btn {
  background-color: transparent;
  color: var(--whiteColor);
}

.input-box .icon button {
  background-color: transparent;
  width: max-content !important;
}

.pagenotfound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
}
.pagenotfound h1 {
  font-size: 100px;
}
.pagenotfound h1, .pagenotfound h2, .pagenotfound p {
  color: var(--whiteColor);
}

.btn-closes svg, .btn-close svg {
  fill: var(--whiteColor);
}

button.btn-closes {
  background-color: transparent;
  width: 50px;
}

.course-overview {
  color: var(--whiteColor) !important;
}

.outer-box .course-overview p, .outer-box .course-overview ul li {
  font: normal normal 400 16px/32px "Source Sans Pro", sans-serif !important;
    letter-spacing: 0px;
    color: var(--whiteColor);
}

.outer-box .course-overview h3 {
  font: normal normal 400 32px/48px "Source Sans Pro", sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
  margin-bottom: 30px;
}

.outer-box .course-overview h4 {
  font: normal normal 400 24px/36px "Source Sans Pro", sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
}



.nav-pills .nav-link.active .topics .topic-info .topic-content span.name, 
.nav-pills .show>.nav-link .topics .topic-info .topic-content span.name {
  color: var(--primary-color) !important;
}



/* .course-overview h3, .course-overview h4 {
  color: var(--primary-color);
} */


/* .mircophone-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 50vh;
  }
  .microphone-icon-container {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-image: linear-gradient(128deg, #ffffff, #647c88);
    padding: 20px;
    margin-right: 20px;
    position: relative;
    cursor: pointer;
  }
  .microphone-icon-container.listening::before {
    content: "";
    width: 100px;
    height: 100px;
    background-color: #ffffff81;
    position: absolute;
    top: 50%;
    left: 50%;
    transform:translate(-50%, -50%) scale(1.4);
    border-radius: 50%;
    animation: listening infinite 1.5s;
  }
  
  .microphone-icon {
    width: 100%;
    height: 100%;
  }
  .microphone-status {
    font-size: 22px;
    margin-right: 20px;
    min-width: 215px;
  }
  .microphone-result-container {
    text-align: center;
    height: 50vh;
  
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;
  
  }
  .microphone-result-text {
    margin-bottom: 30px;
    width: 70vw;
    overflow-y: auto;
  }
  .microphone-reset {
    border: 1px solid #fff;
    background: none;
    color: white;
    width: fit-content;
  }

   */

.input-box .icon.voice-icon {
  pointer-events: all;
  right: 20px;
}

.user-list.learner {
  height: 300px;
  overflow: auto;
}

.outer-box.f-height {
  height: 500px !important;
}
.outer-box.f-height .course-overview {
  width: 100%;
  height: 100%;
}
.outer-box.f-height .course-overview .course-img {
  width: 100%;
  height: 100% !important;
}
.outer-box.f-height img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Media query for very small screens */
@media screen and (max-width: 1200px) {
  .mbr-3 {
    margin-bottom: 1rem!important;
  }  
}

