.input-box.attachment .form-control {
    padding-top: 12px;
    padding-left: 15px;
}

button.mark-comp {
    width: 125px;
    height: 50px;
    background: var(--body-dark-bg) 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--whiteColor);
}

button.mark-comp.comp {
    background: var(--primary-color);
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    pointer-events: none; /* Allow clicks to pass through the overlay */
    transition: background 0.5s ease; /* Smooth transition */
  }
  
  .screenshot-detected .overlay {
    background: rgba(255, 0, 0, 0.5); /* Red overlay for demonstration */
  }
  