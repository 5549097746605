.onboarding .main {
    width: 100% !important;
    left: 0px !important;
}
.onboarding .main .main__section {
    padding-left: 100px !important;
    min-height: inherit !important;
}

.onboarding .heading h1 {
    font: normal normal var(--font-regular) 24px/30px 'source-sans-pro', sans-serif;
    letter-spacing: 0px;
    color: var(--whiteColor);
}
.left-flex .fixed {
  padding-right: 75px;
  min-height: 668px;
  position: fixed;
  border-right: 5px solid var(--dark-bg);
}
.onboard-list {
    display: flex;
    flex-direction: column;
}
.onboard-people, .onboard-courses {
    width: 185px;
    margin-bottom: 50px;
}
.onboard-people .cut-btn, .onboard-courses .cut-btn {
    width: 100%;
    height: 35px;
    background: var(--chips-color) 0% 0% no-repeat padding-box;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    font: normal normal 400 16px/35px 'source-sans-pro', sans-serif;
    letter-spacing: 0px;
    color: var(--whiteColor);
    padding-left: 23px;
}
.onboard-people ul, .onboard-courses ul {
    list-style: none;
    padding-left: 0px;
    margin-top: 21px;
}
.onboard-people ul li, .onboard-courses ul li {
    margin-bottom: 10px;
    font: normal normal 400 14px/18px 'source-sans-pro', sans-serif;
    letter-spacing: 0px;
    color: var(--whiteColor);
}
.onboard-people ul li.active, .onboard-courses ul li.active {
    color: var(--primary-color);
}
.onboard-right {
    padding-left: 15px;
    padding-top: 45px;
}
.onboard-right form {
  height: 575px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.onboard-right .row>div {
  margin-bottom: 0px !important;
}
.onboard-right .show {
  display: block;
}
.onboard-right .hide {
  display: none;
}
.onboarding .form-label {
    font: normal normal 400 24px/30px 'source-sans-pro', sans-serif;
    letter-spacing: 0px;
    color: var(--whiteColor);
    margin-bottom: 30px;
}
.input-box {
    position: relative;
    max-width: 800px;
    width: 100%;
}
.multiple-input .input-box {
    width: 100%;
}
.input-box .form-control, .input-box .form-select {
    width: 100% !important;
    height: 45px;
    background: var(--dark-bg) 0% 0% no-repeat padding-box;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.04);
    border-radius: 10px !important;
    border: none !important;
    outline: none;
    font: normal normal 400 14px/18px 'source-sans-pro', sans-serif;
    letter-spacing: 0px;
    /* color: rgb(255, 255, 255) !important; */
    color: var(--whiteColor);
    /* margin-bottom: 45px; */
}
.form-control:focus {
    background: var(--body-dark-bg) 0% 0% no-repeat padding-box;
    box-shadow: none !important;
}
.form-control::placeholder {
    font: normal normal 400 14px/18px 'source-sans-pro', sans-serif;
    letter-spacing: 0px;
    color: rgba(255, 255, 255, 0.75) !important;
}
.img-area {
  width: 190px;
  height: 152px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: var(--dark-bg);
  /* background-color: var(--whiteColor); */
  border-radius: 18px;
  border: 2px solid var(--primary-color);
}
.upload-area {
    width: 190px;
    height: 152px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: var(--dark-bg);
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='18' ry='18' stroke='%2322E1FEFF' stroke-width='2' stroke-dasharray='10%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 18px;
}
.upload-area input {
    opacity: 0;
    position: absolute;
    z-index: 5;
    width: 100%;
    height: 100%;
}
.upload-area .upload-btn {
    background-color: transparent;
    font: normal normal 400 16px/20px 'source-sans-pro', sans-serif;
    letter-spacing: 0px;
    /* color: rgba(255, 255, 255, 0.16); */
    color: var(--whiteColor);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.onboard-btn-f {
    width: 190px;
    height: 45px;
    border-radius: 25px;
    background: var(--primary-color) 0% 0% no-repeat padding-box;
    border: 2px solid var(--primary-color);
    font: normal normal 900 18px/24px Source Sans Pro;
    letter-spacing: 0px;
    color: #040404;
}
.onboard-btn-f:disabled {
  cursor: not-allowed;
}
.onboard-btn-o {
    width: 190px;
    height: 45px;
    border-radius: 25px;
    background: var(--secondary-color);
    border: 2px solid var(--primary-color);
    font: normal normal 900 18px/24px Source Sans Pro;
    letter-spacing: 0px;
    color: #040404;
}
span.icon {
    position: absolute;
    color: var(--whiteColor);
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}
span.icon svg path#Path_11892 {
  fill: var(--whiteColor);
  stroke: none;
}
span.icon svg path#Path_11890 {
  stroke: var(--whiteColor);
  fill: none;
}

.switch {
    position: relative;
    display: inline-block;
    width: 52px;
    height: 28px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .onboard-right .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .onboard-right .slider:before {
    position: absolute;
    content: "";
    height: 23px;
    width: 23px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .onboard-right input:checked + .slider {
    background-color: var(--primary-color);
  }
  
  .onboard-right input:focus + .slider {
    box-shadow: 0 0 1px var(--primary-color);
  }
  
  .onboard-right input:checked + .slider:before {
    -webkit-transform: translateX(22px);
    -ms-transform: translateX(22px);
    transform: translateX(22px);
  }
  
  /* Rounded sliders */
  .onboard-right .slider.round {
    border-radius: 34px;
  }
  
  .onboard-right .slider.round:before {
    border-radius: 50%;
  }

  .multiple-input.admin {
    margin-top: 25px;
    height: 400px;
    visibility: hidden;
    opacity: 0;
  }
  .multiple-input.active {
    visibility: visible;
    opacity: 100%;
  }

  .react-tagsinput-tag {
    background-color: #010001 !important;
    color: #ffffff !important;
    border-radius: 15px !important;
    border: none !important;
    padding: 5px 10px !important;
    margin-right: 10px !important;
    margin-bottom: 10px !important;
    width: max-content;
    padding-right: 30px !important;
    position: relative;
  }
  .react-tagsinput-remove {
    color: #ffffff !important;
  }
  .react-tagsinput {
    background-color: var(--chips-color) !important;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.04);
    border-radius: 10px !important;
    border: none !important;
    padding: 10px !important;
  }
  .react-tagsinput-input {
    font: normal normal 400 14px/18px 'source-sans-pro', sans-serif !important;
    letter-spacing: 0px !important;
    color: var(--whiteColor) !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    width: 125px !important;
    margin-bottom: 0px !important;
  }

  .react-tagsinput span input::placeholder {
    color: var(--whiteColor) !important;
  }

  .chips-flex.flex-row {
    justify-content: flex-start;
    gap: 15px;
  }
  .chips-flex.flex-row .chips {
    width: 175px;
    height: 32px;
    background: var(--chips-color) 0% 0% no-repeat padding-box;
    border-radius: 24px;
    font: normal normal 400 16px/20px 'source-sans-pro', sans-serif !important;
    letter-spacing: 0px;
    color: var(--whiteColor);
    display: flex;
    align-items: center;
    padding-left: 20px;
    cursor: pointer;
  }
  .chips-flex.flex-row .chips.active {
    border: 2px solid var(--primary-color);
  }
  .mb-3.cat{
    display: none;
  }
  .mb-3.cat.active{
    display: block;
  }
  .react-tagsinput-tag a::before {
    content: "" !important;
    background: url("../../assets/images/close.png") !important;
    position: absolute;
    width: 12px !important;
    height: 12px !important;
    background-size: 12px !important;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
  }
  .createRoles {
    padding-right: 50px;
  }
  .presetRoles {
    background: var(--chips-color) 0% 0% no-repeat padding-box;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.04);
    /* border: 1px solid #707070; */
    border-radius: 18px;
    width: 235px;
    max-width: 100%;
    height: 500px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    gap: 20px;
  }
  .presetRoles label {
    margin-bottom: 5px !important;
    text-align: center;
  }
  .presetRoles .roles {
    width: 185px;
    max-width: 100%;
    /* height: 135px; */
    height: max-content;
    background: var(--body-dark-bg) 0% 0% no-repeat padding-box;
    /* border: 1px solid #707070; */
    border-radius: 10px;
    position: relative;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .presetRoles .roles.active {
    /* border: 1px solid var(--primary-color); */
  }
  .presetRoles .roles .deleteIcon {
    position: absolute;
    top: 0px;
    right: 8px;
  }
  .presetRoles .roles .deleteIcon svg {
    fill: var(--whiteColor);
  }
  .presetRoles .roles .role-name, .presetRoles .roles .preset-permission {
    font: normal normal 400 16px/20px 'source-sans-pro', sans-serif !important;
    letter-spacing: 0px;
    color: var(--whiteColor);
  }
  .presetRoles .roles .role-name, .presetRoles .roles .preset-permission svg {
    fill: var(--primary-color);
  }
  .permission-flex.flex-column {
    flex-direction: column;
    gap: 20px;
    display: none;
  }
  .permission-flex.flex-column.active {
    display: flex;
  }
  .permission-flex.flex-column .flex-row {
    gap: 15px;
    justify-content: flex-start;
  }
  .permission-flex.flex-column .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }
  .check-box {
    height: 32px;
    background: var(--chips-color) 0% 0% no-repeat padding-box;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.04);
    border-radius: 34px;
    position: relative;
  }
  .check-box .round {
    background: var(--chips-color) 0% 0% no-repeat padding-box;
    /* box-shadow: inset 0px 3px 6px #00000087;*/
    box-shadow: none !important;
    border: 1px solid #556464;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
  }
  .check-box input {
    opacity: 0;
    position: absolute;
    z-index: 5;
    width: 100%;
    height: 100%;
  }
  .check-box input:checked + .round {
    background: var(--whiteColor) 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
  }
  .createRoles .chips-flex {
    margin-bottom: 30px;
  }
  .check-box.active {
    border: 2px solid var(--primary-color);
  }
  .check-box input + .round + span {
    display: block;
    width: 175px;
    height: 32px;
    border-radius: 34px;
    font: normal normal 400 12px/16px 'source-sans-pro', sans-serif !important;
    letter-spacing: 0px;
    color: var(--whiteColor);
    padding-left: 18px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
  }
  .check-box input:checked + .round + span {
    border: 2px solid var(--primary-color);
  }
 .flex-end .onboard-btn {
    width: 150px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--chips-color) 0% 0% no-repeat padding-box;
    border: 2px solid var(--primary-color);
    border-radius: 34px;
    font: normal normal normal 24px/30px 'source-sans-pro', sans-serif !important;
    letter-spacing: 0px;
    color: var(--whiteColor);
  }
  .coursecat-head span:last-child {
    font: normal normal 400 18px/22px 'source-sans-pro', sans-serif !important;
    letter-spacing: 0px;
    color: var(--whiteColor);
  }
  
  .badges-flex.flex-row .badges {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }
  .badges-flex.flex-row .badges .badge-item {
    width: 125px;
    height: 105px;
    background: var(--chips-color) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .badges-flex.flex-row .badges .badge-item .badge-name {
    font: normal normal normal 24px/30px 'source-sans-pro', sans-serif !important;
    letter-spacing: 0px;
    color: var(--whiteColor);
  }
  .badges-flex.flex-row .priority {
    width: 145px;
    font: normal normal 400 24px/30px 'source-sans-pro', sans-serif;
    letter-spacing: 0px;
    color: var(--whiteColor);
  }
  .badge-option.flex-row {
    align-items: flex-start;
  }
  .badge-option.flex-row .badge-option-list.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    gap: 15px;
  }
  .badge-option.flex-row .badge-option-list.grid .badge-option-item {
    width: 122px;
    height: 106px;
    background: var(--chips-color) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .badge-option.flex-row .badge-option-list.grid .badge-option-item .deleteIcon {
    position: absolute;
    top: 0px;
    right: 5px;
  }
  .badge-option.flex-row .badge-option-list.grid .badge-option-item .name {
    width: 100px;
    font: normal normal normal 16px/20px 'source-sans-pro', sans-serif !important;
    letter-spacing: 0px;
    color: var(--whiteColor);
    text-align: center;
  }

  .input-box.badges {
    width: 400px;
  }

  .input-box.badges  span.icon {
    top: 25% !important;
  }
.upload-flex {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 50px;
}
.admin .addedCat.grid {
  max-width: 800px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}
.admin .addedCat.grid .addedCat-item {
  background: var(--chips-color) 0% 0% no-repeat padding-box;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.04);
  border-radius: 34px;
  width: 200px;
  height: 35px;
  line-height: 35px;
  padding-left: 0px;
  font: normal normal 400 16px/35px 'source-sans-pro', sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
  position: relative;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.admin .addedCat.grid .addedCat-item.checked {
  background: var(--primary-color) 0% 0% no-repeat padding-box;
  color: var(--body-dark-bg);
}
.admin .addedCat.grid .addedCat-item input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.addedCat.grid .addedCat-item .deleteIcon {
  position: absolute;
  top: 0px;
  right: 8px;
}
.addedCat.grid .addedCat-item .deleteIcon svg {
  fill: var(--whiteColor);
}
.deleteIcon button {
  width: auto;
  height: auto;
  background-color: transparent !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
.input-box.cat {
  margin-bottom: 50px;
}
.createRoles.updateRoles, .createRoles  {
  display: none;
}
.createRoles.updateRoles.active, .createRoles.active {
  display: block;
}
.createRoles .input-box .form-control, .createRoles .input-box .form-select {
  margin-bottom: 0px;
}
.onboard-right .createRoles form {
  justify-content: flex-start;
  gap: 20px;
}