.login_wrapper{
	/* background-color: #010001; */
    background-image: url("../../assets/images/login-background.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}
.login_wrapper.active::before{
    right: 48%;
    transform: translateY(-50%);
}
.login_wrapper.form-success .container h1{
    transform: translateY(85px);
}
.container .heading {
	width: 100%;
	height: 60px;
	position: relative;
	margin-bottom: 75px;
}
.container h1{
    font: normal normal 200 30px/36px 'source-sans-pro', sans-serif !important;
	letter-spacing: 0px;
	color: var(--primary-color);
    transition-duration: 1s;
    transition-timing-function: ease-in-out;
    margin-bottom: 11px;
}
.container h1::after {
	content: "";
	position: absolute;
	left: 0;
	bottom: 0px;
	width: 100px;
	border-bottom: 3px solid var(--primary-color);
}
.container h4{
    font-size: 23px;
    color: #3E0F5A;
    transition-duration: 1s;
    transition-timing-function: ease-in-out;
    font-weight: 700;
    letter-spacing: 0.7px;
    margin-bottom: 0px;
}
.form{
    /* padding: 20px 0;
    padding-top: 48px; */
    position: relative;
    z-index: 2;
    width: 500px;
}
.form input.input{
    appearance: none;
    outline: 0;
	border: none;
	outline: none;
	border-bottom: 3px solid #D8D8D8;
    width: 100%;
    height: 50px;
    border-radius: 0px;
    padding: 10px 15px;
    display: block;
    font-size: 16px;
    color: #707070;
    background-color: transparent;
    margin-bottom: 16px;
    position: relative;
}
.form input.input.password{
    margin-bottom: 20px;
}
.form .form-group{
    position: relative;
	margin-bottom: 70px;
}
.form .icon{
    position: absolute;
    top: 30%;
    left: 21px;
    color: var(--secondary-color);
    font-size: 20px;
    z-index: 4;
}
.form label input{
    width: 26px;
    height: 26px;
    background-color: var(--secondary-color);
}
.flex_form-group{
    display: flex;
    align-items: center;
    gap: 55px;
}
.form-group{
    width: 100%;
}
.remb-me-flex{
    display: flex;
    align-items: center;
	justify-content: space-between;
}
.remember-me {
    width: 125px;
    height: 20px;
    position: relative;
}
.remember-me label{
    display: flex;
    align-items: center;
    gap: 10px;
}
.remember-me input{
	width: 125px;
    height: 20px;
    padding: 0;
    margin-bottom: 0;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
	opacity: 0;
}
.remember-me label{
    position: relative;
    cursor: pointer;
    color: var(--primary-color);
    font-size: 17px;
    font-weight: var(--font-light);
}
.remember-me label::before{
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
	border: 1px solid #707070;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 0px;
}
.remember-me input + label::before{
    content: "";
    margin-right: 0px;
    display: inline-block;
    vertical-align: text-top;
    width: 18px;
    height: 18px;
    border-radius: 0px;
    background: transparent;
}
.remember-me input:hover + label::before{
    background: var(--secondary-color);
}
.remember-me input:focus + label::before{
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}
.remember-me input:checked + label::before{
    background: var(--primary-color);
}
.remember-me input:checked + label:after{
    content: "";
    display: block;
    position: absolute;
    top: 1px;
    left: 6px;
    width: 6px;
    height: 12px;
    border: solid #ffffff;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}
.fill_btn{
    display: block;
    padding: 8px 32px;
    width: 200px;
    height: 38px;
    outline: 0;
    background: var(--primary-color) 0% 0% no-repeat padding-box;
    /* box-shadow: 0px 3px 6px var(--primary-color); */
    border: 1px solid #1EE5FE;
    border-radius: 27px;
    border: none;
    font: normal normal 400 15px/20px 'Source Sans Pro';
    letter-spacing: 0px;
    color: #050032;
	margin: 0 auto;
}
.fill_btn.guestlogin {
    width: 200px;
    background: var(--secondary-color) 0% 0% no-repeat padding-box;
}
.outline_btn{
    display: block;
    padding: 8px 32px;
    width: 196px;
    height: 47px;
    outline: 0;
    border: none;
    font-size: 14px;
    background-color: transparent;
    border: 1px solid var(--primary-color);
    border-radius: 6px;
    color: var(--primary-color);
}
.fill_btn a {
    color: var(--whiteColor);
    text-decoration: none;
}
.fill_btn a:hover{
    text-decoration: none;
    color: var(--whiteColor);
}
.outline_btn a {
    color: var(--secondary-color);
    text-decoration: none;
}
.outline_btn a:hover {
    color: var(--secondary-color);
    text-decoration: none;
}
/* .action{
    margin-top: 59px;
} */
.login_wrapper .signup{
    position: relative;
}
.login_wrapper .login{
    position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
    width: 500px;
    height: 450px;
    max-width: 100%;
}
.forget-psw a{
	font: normal normal 400 13px/16px 'Source Sans 3';
    letter-spacing: 0px;
    color: #D8D7DE !important;
    text-decoration: none;
}

.container .login-img {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	height: 100%;
}

.form-group.togglePassword {
    position: relative;
	margin-bottom: 55px;
}
.form-group.togglePassword.otp {
    display: none;
}
.form-group.togglePassword.otp.active {
    display: block;
}
.form-group.togglePassword .uil{
    position: absolute;
    top: 15px;
    right: 15px;
	color: #D8D7DE;
}

.form-group {
	position: relative;
}

.form-group label {
	font: normal normal 400 13px/16px 'Source Sans 3';
	letter-spacing: 0px;
	color: #D8D7DE;
	position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    pointer-events: none;
}

.form-group .input:focus~label, .form-group .input:valid~label {
    top: -5px;
	color: var(--primary-color);
}
.form-group .input:focus, .form-group .input:valid {
	border-bottom: 3px solid var(--primary-color);;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .login_wrapper .login, .login_wrapper .login .form {
        width: 100%;
    }
    
    .form .form-group.action .flex_form-group {
        display: flex;
        flex-direction: column;
    }
    .container {
    padding-top: 0px;
    padding-left: 0px;
    left: 0;
    top: 0;
    transform: translateY(10px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}
    .form-group{
        width: 335px;
    }
    .form input.input{
        width: 335px;
    }
    .remb-me-flex{
        gap: 30px;
        justify-content: space-between;
    }
    .flex_form-group{
        gap: 10px;
    }
    .fill_btn {
        margin: 0 auto;
    }
}
@media only screen and (max-width: 844px) and (orientation: landscape) {
    .container {
        width: 100%;
        height: 100vh;
        padding: 20px;
        top: 0px;
        left: 0px;
        transform: translateY(0px);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .form input.input {
        width: 100%;
        height: 50px;
    }
    .form .form-group {
        width: 100%;
    }
    .form .icon, .form-group.togglePassword .uil {
        top: 50%;
        transform: translateY(-50%);
    }
    .action {
        margin-top: 30px;
    }
}
@media only screen and (max-width: 380px){
    .container {
        padding-top: 0px;
        padding-left: 0px;
        left: 0;
        top: 0;
        transform: translateY(10px);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
    }
    .form input.input {
        width: 100%;
    }
    .form-group {
        width: 100%;
    }
    .forget-psw a, .remember-me label {
        font-size: 14px;
    }
    .fill_btn {
        margin: 0 auto;
    }
    .remember-me input + label::before {
        width: 20px;
        height: 20px;
    }
}
@media only screen and (max-width: 300px){
    .container {
        padding-top: 0px;
        padding-left: 20px;
        padding-right: 20px;
        left: 0;
        top: 0;
        transform: translateY(10px);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 100vh;
    }
    .form input.input {
        width: 100%;
    }
    .form-group {
        width: 100%;
    }
    .forget-psw a, .remember-me label {
        font-size: 12px;
    }
    .fill_btn {
        margin: 0 auto;
    }
    .remember-me input + label::before {
        width: 20px;
        height: 20px;
    }
}

.heading.login-heading {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.heading.login-heading p {
    width: 275px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
    color: var(--whiteColor);
}