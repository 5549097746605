.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
    font-size: 13px !important;
    color: var(--greyColor);
    font-weight: 400 !important;
    background-color: transparent !important;
    border: none !important;
    padding: 0rem 0rem !important;
    padding-right: 28px !important;
    transition: 0s;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: var(--secondary-color) !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active::after {
    content: '';
    border-bottom: 2px solid var(--secondary-color);
    width: 10px;
    display: block;
    margin: 5px auto;
    transition: 0s;
}

.nav-tabs {
    border: none !important;
    margin-bottom: 23px !important;
    transition: 0s;
}

.card {
    border-radius: 25px !important;
    width: 180px !important;
    border: none !important;
}

.card .card-title {
    font-size: 12.5px !important;
    line-height: 12px;
    font-weight: 500;
    color: #121212;
    margin-top: -2px;
}

.card-text {
    font-size: 10px !important;
    color: #787878;
    font-weight: 400;
    letter-spacing: 0.1px;
}

.card-body {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 0px 0px 36px 36px;
}

.card-img-top {
    position: relative;
}

.card .status {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 47px;
    height: 20px;
    border-radius: 32px 0px 0px 0px;
    background-color: var(--secondary-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.card .status span {
    font-size: 8px;
    font-weight: 400;
    color: var(--whiteColor);
}

.calendar {
    padding: 20px 16px 8px 16px;
    background-color: var(--body-dark-bg);
    border: 1px solid hsla(var(--hue-color), 6%, 50%, 0.16);
    border-radius: 25px;
    margin-bottom: 30px;
    height: 260px;
}

.month {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 13px;
    margin-bottom: 21px;
}

.month .uil {
    font-size: 24px;
    color: #808495;
}

.weekdays {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(148, 163, 175, 0.3);
}

.days {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0.2rem;
}

.date h2 {
    color: var(--whiteColor);
    font: normal normal 700 12px/17px 'Open Sans';
    letter-spacing: 0px;

    margin-bottom: 0px;
}

.days span {
    width: 14.25%;
    padding: 9.25px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    cursor: pointer;
    color: var(--whiteColor);
    font-size: 10px;
}

.weekdays span {
    width: 14.30%;
    text-align: center;
    color: var(--secondary-color);
    opacity: 0.9;
    font-size: 11px;
    font-weight: 700;
}

.prev-date,
.next-date {
    opacity: 0.5;
    cursor: none;
}

.days span.today:not(.next-date, .prev-date) {
    color: var(--secondary-color);
    font-weight: 700;
}

.days span:not(.next-date, .prev-date):hover {
    color: var(--secondary-color);
    font-weight: 700;
}

.days span:not(.next-date, .prev-date)::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 26px;
    height: 26px;
    background-color: var(--secondary-color);
    border-radius: 100%;
    z-index: -1;
    opacity: 0;
    transition: 0.5s;
}

.days span.today::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 26px;
    height: 26px;
    background-color: var(--secondary-color);
    opacity: 0.2;
    border-radius: 100%;
    z-index: -2;
    transition: 0.5s;
}

.days span:not(.next-date, .prev-date):hover:before {
    opacity: 0.2;
}

.owl-theme .owl-nav.disabled+.owl-dots {
    display: none;
}

/* my__progress */
.my__progress {
    width: 315px;
    padding: 21px 18px 24px 17px;
}

.courses {
    width: 565px;
    padding: 21px 20px 35px 21px;
}

.my__progress,
.courses {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 25px;
}

.my__progress h2.heading,
.courses h2.heading {
    font-size: 18px;
    font-weight: 400;
    color: #222222;
    letter-spacing: 0.81px;
    margin-top: -4px;
}

.progress_flex .courses h2.heading {
    margin-bottom: 12px;
}

.my__progress-chart {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 23px;
}

.chart__data {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 25px;
}

.chart__point {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.chart__color {
    width: 10px;
    height: 10px;
    border-radius: 2px;
}

.chart__color.one {
    background-color: #FE6D3D !important;
}
.chart__color.two {
    background-color: #EA1CB3 !important;
}
.chart__color.three {
    background-color: #00FF0F !important;
}
.chart__color.four {
    background-color: #3DFEC9 !important;
}
.chart__color.five {
    background-color: #D96DFF !important;
}
.chart__color.six {
    background-color: #34CEFF !important;
}
.chart__text {
    font: normal normal 400 15px/15px 'Avenir', sans-serif !important;
    letter-spacing: 0px;
    color: var(--whiteColor) !important;
    width: max-content !important;
}

.chart__data {
    font: normal normal 400 15px/15px 'Avenir', sans-serif !important;
    letter-spacing: 0px;
    color: var(--whiteColor) !important;
}

/* my__progress */

/* all_courses */
.all_courses .card {
    width: 215px !important;
    height: 97px !important;
    border-radius: 0px !important
}

.all_courses .card-body {
    background-color: rgba(252, 252, 252, 0.6) !important;
    border-radius: 0px !important;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
    gap: 9px;
}

.all_courses .card .flex_box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.all_courses .card .card-body .card-title {
    font-size: 12px !important;
    line-height: 14px !important;
    font-weight: 700 !important;
    letter-spacing: 0.54px !important;
    color: #121212 !important;
    margin-bottom: 0px;
}

.all_courses .card .card-body .card-text {
    font-size: 10px !important;
    color: #121212 !important;
    opacity: 0.4 !important;
    font-weight: 400 !important;
    margin-right: auto;
    margin-bottom: 0px !important;
    margin-top: -4px !important;
}

.all_courses .card .flex_box .right {
    width: 125px !important;
    height: 97px !important;
    border-radius: 0px 4px 4px 0;
    outline-offset: -1px;
    background-color: #ffffff;
}

.all_courses .card .flex_box .right .card-body {
    padding: 11px 17px 9px 15px;
}

.all_courses .card .flex_box .left {
    background-color: transparent;
    width: 90px !important;
}

.all_courses .card .flex_box .left img {
    width: 90px !important;
    height: 97px !important;
}

.all_courses .card .card-body .custom_btn {
    width: 63px;
    height: 21px;
    border: 0.5px solid var(--secondary-color);
    font-size: 9px;
    font-weight: 300;
    background-color: transparent;
    color: var(--secondary-color);
    transition: 0.5s;
    border-radius: 2px;
}

.all_courses .card .card-body .custom_btn:hover {
    background-color: var(--secondary-color);
    color: var(--whiteColor);
}

/* all_courses */

/* upcomming__events */
.upcomming__events {
    background-color: rgba(255, 255, 255, 0.36);
    border-radius: 25px;
    border: 1px solid hsla(var(--hue-color), 6%, 50%, 0.12);
    padding: 18px 18px 0px 14px;
    margin-bottom: 28px;
    height: 283px !important;
}

.upcomming__events h2.heading {
    font-size: 16px;
    font-weight: 400;
    color: #2A285A;
    letter-spacing: 0.9px;
    margin-bottom: 0px;
    margin-top: -4px;
}

.events {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
    gap: 30px;
}

.event__circle {
    position: relative;
    background-color: #FFFFFF;
    width: 14px;
    height: 14px;
    line-height: 14px;
    border-radius: 100%;
    border: 2px solid var(--secondary-color);
    text-align: center;
    margin-top: 0px;
}

.event__circle:first-child {
    position: relative;
}

/* .events:first-child .event__circle:after {
    content: '';
    position: absolute;
    display: block;
    right: 50%;
    transform: translate(-50%);
    bottom: 0px;
    width: 100%;
    height: 100px;
    transform: scale(1, 2);
    transform-origin: 50% -100%;
    border: 1px solid var(--secondary-color);
    opacity: 0.6;
} */
.events__list {
    position: relative;
}

.events__list::after {
    content: '';
    position: absolute;
    display: block;
    top: 8px;
    left: 6px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    border-left: 1px solid var(--secondary-color);
    opacity: 0.6;
    z-index: -1;
    overflow: hidden;
}

/* .event__circle:before {
    content: '';
    position: absolute;
    display: block;
    top: -30px;
    right: 50%;
    bottom: 1px;
    height: 100%;
    width: 1px;
    transform: scale(1, 2);
    transform-origin: 50% -100%;
    border: 1px solid var(--secondary-color);
    opacity: 0.6;
} */
.events:first-child .event__circle:before {
    display: none;
}

.events:last-child .event__circle:after {
    display: none;
}

.event__data {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 225px;
}

.event__data .title {
    font-size: 11px;
    font-weight: 500;
    color: #45484B;
}

.descrip {
    font-size: 10px;
    font-weight: 400;
    color: #95989A;
    letter-spacing: 0.2px;
}

/* upcomming__events */

/* my__courses */
.my__courses {
    margin-bottom: 50px;
    width: 960px !important;
    height: 300px !important;
    position: relative;
    margin-left: -22px;
}

.my__courses .custom_table {
    width: 928px;
    height: 314px;
    background-color: rgba(255, 255, 255, 0.36);
    box-shadow: 0px 3px 16px #0A4B6D14;
    border-radius: 25px;
    margin-left: 22px;
    margin-right: 22px;
}

.table__header {
    width: 100%;
    height: 55px;
}

.table__body {
    width: 100%;
    height: 245px;
}

.my__courses .table__header {
    position: sticky;
    top: 0;
}


.table {
    margin-bottom: 0px !important;
    width: 100%;
    z-index: 1;
}

.table thead tr {
    height: 56px;
}

.table tbody tr {
    height: 65px;
}

.table thead tr th,
.table tbody tr td {
    font-size: 14px;
    font-weight: 400;
    color: var(--secondary-color);
    vertical-align: middle;
}

.table thead tr th:first-child,
.table tbody tr td:first-child {
    padding-left: 10px;
}

.table thead tr th:last-child,
.table tbody tr td:last-child {
    padding-left: 0px;
}

.table tbody tr:last-child td {
    border-bottom: none !important;
}

.table thead tr th .table_head{
    display: flex;
    align-items: center;
    gap: 20px;
}

.table thead tr th .icon svg {
    fill: var(--primary-color);
}

.table>:not(:last-child)>:last-child>* {
    border-bottom-color: hsla(var(--hue-color), 100%, 50%, 0.40) !important;
}

.table>:not(caption)>*>* {
    border-bottom-color: hsla(var(--hue-color), 100%, 50%, 0.40) !important;
}

.green_btn,
.blue_btn,
.orange_btn,
.red_btn {
    box-shadow: none !important;
    outline: none !important;
    width: 100px;
    height: 35px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 400;
    cursor: default !important;
}

.green_btn {
    background-color: #AAFFBE;
    border: 1px solid #AAFFBE;
    color: #0D472D;
}

.launch_btn {
    background-color: transparent;
    border: 1px solid var(--secondary-color);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    width: 125px;
    height: 35px;
    color: var(--secondary-color);
}

.blue_btn {
    background-color: #6200EE;
    border: 1px solid #6200EE;
    color: var(--whiteColor);
}

.orange_btn {
    background-color: #FFEFCA;
    border: 1px solid #FFEFCA;
    color: #433108;

}

.red_btn {
    background-color: #FFD9D1;
    border: 1px solid #FFD9D1;
    color: #360E07;
}

.table tbody tr.active {
    transform: scale(1.04);
    background-color: rgba(252, 252, 252, 0.36);
    box-shadow: 0px 3px 16px #0A4B6D14;
    border-radius: 25px;
    height: 70px !important;
    /* border: 1px solid hsl(var(--hue-color), 81%, 53%); */
}

.table tbody tr.active td:first-child {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}

.table tbody tr.active td:last-child {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

/* my__courses */
.my__courses-heading h2.heading {
    color: #222222;
    font-size: 18px;
    letter-spacing: 0.81px;
    font-weight: 400;
    padding-left: 17px;
    padding-bottom: 12px;
}

.announcement {
    background-color: rgba(255, 255, 255, 0.36);
    border: 1px solid hsla(var(--hue-color), 6%, 50%, 0.12);
    border-radius: 25px;
    padding: 20px 10px 0px 15px;
    height: 313px !important;
}

.announcement__list {
    overflow: scroll;
    width: 100% !important;
    margin-left: 17px;
    height: 250px !important;
}

.events__list .events_list_inside {
    overflow: scroll;
    height: 215px;
    margin-top: -1px;
}

.announcement h2.heading {
    font-size: 16px;
    font-weight: 400;
    color: #2A285A;
    letter-spacing: 0.9px;
    margin-bottom: 0px;
    margin-top: 0px;
}

.announcement .add_new-announcement {
    width: 22px;
    height: 22px;
    position: relative;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.announcement .add_new-announcement::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--secondary-color);
    opacity: 0.8;
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

.announcement .add_new-announcement div {
    z-index: 1;
    color: var(--whiteColor);
    text-align: center;
}

.announcement .flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 41px;
    margin-bottom: 20px;
}

.announcemnet .icon {
    width: 34px;
    height: 34px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    position: relative;
}

.announcemnet .icon svg {
    z-index: 1;
}

.announcement .icon::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--secondary-color);
    opacity: 0.8;
    width: 34px;
    height: 34px;
    border-radius: 100%;
}

.announcemnet {
    display: flex;
    align-items: center;
    gap: 28px !important;
    margin-bottom: 14px;
}

.announcement_data {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.inactiveAdmin {
    text-align: center;
    font: normal normal 400 15px/20px 'Avenir', sans-serif !important;
    letter-spacing: 0px;
    color: var(--whiteColor);
}   
.cflex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.cflex .heading {
    margin-bottom: 0px;
}
.cflex .error-req {
    width: 300px;
}
.bar-area {
    width: 1000px;
    max-width: 100%;
    height: 500px;
    margin: 0 auto; 
}

.line-area canvas {
    width: 700px;
    max-width: 100%;
    height: 350px;
    /* padding: 20px; */
}

.mng-ppl-chart #gauge-chart1 {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 1920px) {
    .mng-ppl-chart #gauge-chart1 {
        width: 400px !important;
        margin: 0 auto;
    }
}
@media only screen and (max-width: 992px) {
    .bar-area {
        width: 100%;
        height: 350px;   
    }
    .line-area {
        width: 100%;
        height: 250px;
    }
    /* .bar-area canvas, .line-area canvas {
        margin: 0 auto;
    } */
    .mng-ppl-chart #gauge-chart1 {
        width: 500px !important;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 600px) {
    .bar-area {
        width: 100%;
        height: 300px;   
    }
    .line-area {
        width: 100%;
        height: 250px;
    }
    /* .bar-area canvas, .line-area canvas {
        margin: 0 auto;
    } */
    .mng-ppl-chart #gauge-chart1 {
        width: 200px !important;
        margin: 0 auto;
    }
    .cflex {
        flex-direction: column;
    }
    .outer-box .heading h1 {
        font: normal normal normal 12px/14px 'Avenir', sans-serif !important;
    }
}