@media only screen and (max-width: 1300px) {
    .permission-flex.flex-column .grid {
        grid-template-columns: repeat(3, 1fr);
    }
    .createRoles {
        padding-right: 25px;
    }
    .input-box.badges {
        width: 300px;
    }
}
@media only screen and (max-width: 1200px) {
    .permission-flex.flex-column .grid {
        grid-template-columns: repeat(2, 1fr);
    }
    .onboard-right .badges-flex {
        flex-direction: column !important;
        align-items: flex-start;
        gap: 20px;
    }
    .badges-flex.flex-row .priority {
        width: 300px;
    }
    .input-box.badges {
        width: 200px;
    }
    .onboard-right .row {
        margin-bottom: 50px !important;
    }
}
@media only screen and (max-width: 1080px) {
    .onboard-people, .onboard-courses {
        width: 150px;
    }
    .permission-flex.flex-column .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
    }
    .permission-flex.flex-column .grid {
        grid-template-columns: repeat(2, 1fr);
    }
    .left-flex .fixed {
        width: 250px;
    }
    .badge-option.flex-row {
        flex-direction: column !important;
        gap: 20px;
    }
    .onboard-right .badges-flex {
        margin-bottom: 25px;
    }
    .onboard-right .row {
        margin-bottom: 25px !important;
    }
}
@media only screen and (max-width: 992px) {
    .presetRoles {
        /* flex-direction: row !important; */
        display: grid !important;
        grid-template-columns: repeat(2, 1fr) !important;
        width: 100% !important;
    }
    .onboard-right {
        padding-left: 0px;
    }
    .onboarding .main .main__section {
        padding-left: 25px !important;
    }
    .left-flex .fixed {
        width: 225px;
    }
    .badges-flex.flex-row .badges {
        gap: 15px;
    }
    .badges-flex.flex-row .badges .badge-item {
        width: 120px;
        height: 100px;
    }
}
@media only screen and (max-width: 768px) {
    .presetRoles {
        flex-direction: row;
    }
    .onboard-people, .onboard-courses {
        width: 300px;
        text-align: center;
    }
    .left-flex::after {
        display: none;
    }
    .onboard-right {
        padding-top: 0px;
    }
    .onboard-list {
        flex-direction: row;
        gap: 50px;
    }
    .onboard-people .cut-btn, .onboard-courses .cut-btn {
        padding-left: 0px;
    }
    .topbar .flex {
        gap: 15px;
    }
    .left-flex .fixed {
        width: 100%;
        min-height: max-content;
        position: relative;
        border-right: none !important;
        border-bottom: 5px solid var(--dark-bg);;
    }
    .permission-flex.flex-column .grid {
        grid-template-columns: repeat(4, 1fr);
    }
    .presetRoles {
        margin: 0 auto;
        width: 300px;
    }
    .onboard-right form {
        height: max-content;
    }
    .onboard-right .mb-3 {
        margin-bottom: 50px !important;
    }
    .onboard-right form {
       align-items: center;
    }
}
@media only screen and (max-width: 650px) {
    .permission-flex.flex-column .grid {
        grid-template-columns: repeat(2, 1fr);
    }
    
}
@media only screen and (max-width: 600px) {
    .upload-flex {
        flex-direction: column;
        gap: 25px;
        margin-bottom: 25px;
    }
    .onboarding .main .main__section {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }
    .onboard-right {
        padding-left: 0px;
    }
    .flex-row {
        gap: 20px;
    }
    .permission-flex.flex-column .grid {
        grid-template-columns: repeat(2, 1fr);
    }
    .onboard-right form {
        height: max-content;
    }
    .createRoles {
        margin-bottom: 25px;
    }
    .badges-flex.flex-row .badges {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .badge-option.flex-row .badge-option-list.grid {
        grid-template-columns: repeat(2, 1fr);
    }
    .flex-row .onboard-btn-o, .flex-row .onboard-btn-f {
        width: 150px;
    }
    .chips-flex.flex-row .chips {
        width: 100px;
    }
    .createRoles {
        padding-right: 0px;
    }
    .addedCat.grid {
        grid-template-columns: repeat(2, 1fr);
    }
    .addedCat.grid .addedCat-item {
        width: 160px !important;
    }
    .presetRoles .roles {
        width: 150px !important;
        margin-bottom: 0px !important;
    }
    .presetRoles {
        height: 400px !important;
    }

}