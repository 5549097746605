.estore-price {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 4px;
}

.estore-price-details {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 30px 0px;
  width: 310px;
}

.estore-price input {
  background-color: var(--dark-bg);
  border: none;
  border-radius: 6px;
  height: 30px;
  text-decoration: none;
  outline: none;
  padding: 6px 10px;
  color: var(--whiteColor);
  position: relative;
}

.estore-date-icon {
  position: absolute;
  top: 0;
  right: 10px;
  pointer-events: none;
}
.estore-date-icon svg {
  fill: var(--whiteColor) !important;
}
.searchBarCourses {
  width: 73%;
  margin-bottom: 15px;
}

.searchBarCourses input {
  width: 100%;
  height: 45px;
  background: var(--body-dark-bg) 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 8px !important;
  padding-left: 15px;
  font: normal normal 400 15px/18px 'Avenir', sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
}

.search-container-courses {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  /* align-items: flex-start; */
}

.estoreLms-btn-course {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.lms-btn {
  background: var(--chips-color) 0% 0% no-repeat padding-box !important;
  color: var(--whiteColor);
  border-radius: 12px;
  width: 118px;
}

.lms-btn:hover {
  border: 2px solid var(--primary-color);
}

.estoreLms-btn-course .lms-btn.active {
  border: 3px solid var(--primary-color);
}
