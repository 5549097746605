.org.box-outer {
    background: var(--dark-bg) 0% 0% no-repeat padding-box;
    border-radius: 13px;
    width: 100%;
    height: 100%;
    min-height: 625px;
    padding: 45px;
}

.org.box-outer .heading h1 {
    font: normal normal 400 24px/30px 'Avenir', sans-serif!important;;
    letter-spacing: 0px;
    color: var(--whiteColor);
    margin-bottom: 35px;
}

.main-org,
.sub-org {
    text-align: center;
}

.main-org {
    margin-bottom: 50px;
}

.main-org .org-title h2,
.sub-org .org-title h2 {
    font: normal normal 400 20px/24px 'Avenir', sans-serif!important;;
    letter-spacing: 0px;
    color: var(--whiteColor);
    margin-bottom: 16px;
}

.main-org .logo {
    width: 120px;
    height: 120px;
    padding: 5px;
    margin: 0 auto;
    border-radius: 6px;
    margin-bottom: 10px;
}

.main-org .logo img {
    background-color: var(--body-dark-bg);
}

.sub-org .logo {
    width: 120px;
    height: 120px;
    padding: 5px;
    margin: 0 auto;
    /* background-color: var(--whiteColor); */
    background-color: var(--body-dark-bg);
    border-radius: 6px;
    margin-bottom: 10px;
}

.main-org .logo {
    border: 3px solid var(--primary-color);
}

.main-org .logo img,
.sub-org .logo img {
    padding: 10px;
    border-radius: 6px;
}

.main-org .orgname span,
.sub-org .orgname span {
    font: normal normal 400 15px/20px 'Avenir', sans-serif!important;;
    letter-spacing: 0px;
    /* color: rgba(255, 255, 255, 0.75); */
    color: var(--whiteColor);
}

.sub-org .org-item,
.logo {
    position: relative;
}

.sub-org .org-item .logo .deleteIcon {
    position: absolute;
    top: 0px;
    right: 5px;
}

.sub-org .org-item .logo .deleteIcon svg {
    fill: var(--whiteColor);
}

.sub-org .add-item .logo {
    width: 120px;
    height: 120px;
    padding: 5px;
    background-color: var(--body-dark-bg);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.sub-org .add-item .logo svg {
    fill: var(--whiteColor);
}

.sub-org .add-item .logo img {
    background-color: transparent;
    width: 48px;
}

.deleteIcon img {
    padding: 0px !important;
}

.box-outer.addUser {
    /* padding: 35px; */
    height: 100%;
}

.box-outer.addUser .heading h1 {
    font: normal normal 400 20px/24px 'Avenir', sans-serif!important;;
    letter-spacing: 0px;
    color: var(--whiteColor);
    margin-bottom: 25px;
}

.bulk-upload-area {
    width: 950px;
    height: 145px;
    background: var(--dark-bg) 0% 0% no-repeat padding-box;
    border: 2px dashed var(--primary-color);
    border-radius: 18px;
    position: relative;
    display: block;
    margin: 0 auto;
    margin-bottom: 25px;
}

.bulk-upload-area .info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 225px;
    height: 35px;
    background: var(--body-dark-bg) 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font: normal normal 400 16px/20px 'Avenir', sans-serif!important;;
    letter-spacing: 0px;
    color: var(--whiteColor);
}

.bulk-upload-area input[type="file"] {
    opacity: 0;
    position: absolute;
    z-index: 5;
    width: 100%;
    height: 100%;
}

.add-user-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.add-user-form label {
    font: normal normal 400 16px/20px 'Avenir', sans-serif!important;;
    letter-spacing: 0px;
    color: var(--whiteColor);
}

.add-user-form .input-box .form-control {
    background: var(--dark-bg) 0% 0% no-repeat padding-box;
    border-radius: 16px;
    width: 705px;
    height: 45px;
    padding-left: 10px;
    padding-right: 10px;
    border: none;
    box-shadow: none;
    font: normal normal 400 16px/20px 'Avenir', sans-serif!important;;
    letter-spacing: 0px;
    color: var(--whiteColor);
    margin-bottom: 0px;
}

.add-user-form .input-box .form-select {
    margin-bottom: 0px;
    background: #010001 0% 0% no-repeat padding-box;
}

.add-user-form .input-box .form-control:focus {
    background: var(--dark-bg) 0% 0% no-repeat padding-box;
}

.define-user {
    display: flex;
    align-items: center;
    gap: 25px;
}

.checkcontainer {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font: normal normal 400 16px/20px 'Avenir', sans-serif!important;;
    letter-spacing: 0px;
    color: var(--whiteColor);
}

.checkcontainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.radiobtn {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;
}

.checkcontainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkcontainer:hover input~.radiobtn {
    background-color: #ccc;
}

.checkcontainer input:checked~.radiobtn {
    background-color: var(--whiteColor);
}

.radiobtn:after {
    content: "";
    position: absolute;
    display: none;
}

.checkcontainer input:checked~.radiobtn:after {
    display: block;
}

.checkcontainer .radiobtn:after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: var(--primary-color);
}

.roles {
    width: 950px;
    display: block;
    margin: 0 auto;
}

.roles .roles-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.define-role {
    display: flex;
    align-items: center;
    gap: 15px;
}

.define-role label {
    font: normal normal 400 16px/20px 'Avenir', sans-serif!important;;
    letter-spacing: 0px;
    color: var(--whiteColor);
    margin-bottom: 0px;
}

.define-role select {
    background: #010001 0% 0% no-repeat padding-box;
    border-radius: 10px;
    width: 200px;
    height: 32px;
    border: none;
    outline: none;
    font: normal normal 400 16px/20px 'Avenir', sans-serif!important;;
    letter-spacing: 0px;
    color: var(--whiteColor);
    padding-left: 10px;
    padding-right: 10px;
}

.act-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.act-btn button {
    width: 140px;
    height: 32px;
    background: #010001 0% 0% no-repeat padding-box;
    border-radius: 10px;
    font: normal normal 400 15px/32px 'Avenir', sans-serif!important;;
    letter-spacing: 0px;
    color: var(--body-dark-bg);
}

.react-select__input,
.css-1dimb5e-singleValue {
    color: var(--whiteColor) !important;
    /* color: var(--primary-color) !important; */
}

.box-outer.addUser label {
    font: normal normal normal 15px/20px 'Avenir', sans-serif!important;;
    letter-spacing: 0px;
    color: var(--whiteColor);
}

@media only screen and (max-width: 768px) {
    .org-flex {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width: 600px) {
    .org-flex {
        grid-template-columns: repeat(2, 1fr);
    }
    .box-inner .action-flex .courses-act {
        flex-direction: column;
    }
}

@media only screen and (max-width: 450px) {
    .org-flex {
        grid-template-columns: repeat(1, 1fr);
    }
}