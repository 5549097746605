.fc .fc-toolbar-title {
  color: var(--whiteColor) !important;
  font: normal normal 400 25px/25px "Source Sans Pro", sans-serif !important;
  letter-spacing: 0px;
}
.fc .fc-col-header-cell-cushion {
  color: var(--whiteColor) !important;
}
.fc .fc-daygrid-day-number {
  color: var(--whiteColor) !important;
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: var(--chips-color);
}
.fc-direction-ltr .fc-daygrid-event .fc-event-time {
  color: var(--whiteColor) !important;
}
.fc-daygrid-dot-event .fc-event-title {
  color: var(--whiteColor) !important;
}

.fc-direction-ltr .fc-button-group .fc-today-button {
  width: 100px;
  height: 30px;
  background: var(--body-dark-bg) 0% 0% no-repeat padding-box;
  border-color: var(--body-dark-bg);
  border-radius: 10px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal 600 15px/20px "Source Sans Pro", sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
  text-transform: capitalize;
}

.fc-direction-ltr .fc-button-group .fc-prev-button,
.fc-direction-ltr .fc-button-group .fc-next-button {
  background-color: transparent;
  border: none !important;
  width: 30px;
  height: 30px;
  box-shadow: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-top {
  background-color: #3498DB;
}

.fc-daygrid-event-harness {
  /* background: var(--chips-color) 0% 0% no-repeat padding-box; */
  border-radius: 10px;
}

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.fc-theme-standard th {
  background-color: var(--event-thtd) !important;
  border: 1px solid #363535 !important;
  height: 50px;
  line-height: 50px;
}
.fc .fc-timegrid-slot-label,
.fc-timegrid-axis.fc-scrollgrid-shrink {
  background-color: var(--event-thtd) !important;
  border: 1px solid #363535 !important;
}
.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary {
  background-color: var(--body-dark-bg) !important;
  border-color: var(--body-dark-bg) !important;
  color: var(--whiteColor) !important;
  box-shadow: none !important;
  width: 75px !important;
}

.fc-theme-standard .fc-popover {
  background: var(--body-dark-bg) !important;
}
.fc-theme-standard .fc-popover-header {
  background: var(--dark-bg) !important;
}

.fc .fc-popover-title, .fc .fc-popover-close {
  color: var(--whiteColor) !important;
}

.outer-box.fullCal {
  padding-left: 0px !important;
  padding-right: 0px !important;
  position: relative;
}

.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.fc-scrollgrid.fc-scrollgrid-liquid,
.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid rgba(112, 112, 112, 0.33) !important;
}

.fc-h-event {
  border: none !important;
  outline: none !important;
}

/* .fc-h-event {
  background-color: var(--primary-color) !important;
} */

.fc .fc-button-group .fc-today-button.fc-button.fc-button-primary {
  margin-right: 10px !important;
}

.fc .fc-button-group .fc-prev-button.fc-button.fc-button-primary,
.fc-next-button.fc-button.fc-button-primary {
  background-color: transparent !important;
}

.fc-timeGridDay-button.fc-button.fc-button-primary.fc-button-active,
.fc-timeGridWeek-button.fc-button.fc-button-primary.fc-button-active,
.fc-dayGridMonth-button.fc-button.fc-button-primary.fc-button-active {
  background-color: var(--chips-color) !important;
  border: 1px solid var(--primary-color) !important;
  border-radius: 11px;
}
.outer-box.fullCal .fc-toolbar-chunk:last-child {
  padding-right: 200px;
}
.outer-box.fullCal.noedit .fc-toolbar-chunk:last-child {
  padding-right: 0px;
}
.outer-box.fullCal .addEvent {
  position: absolute;
  top: 16px;
  right: 16px;
  background: var(--body-dark-bg) 0% 0% no-repeat padding-box;
  border: 2px solid var(--primary-color);
  border-radius: 10px;
  font: normal normal 600 15px/20px "Source Sans Pro", sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
}

.createEvents-icon {
  left: 10px;
}

.event-modal .modal.show .modal-dialog {
  width: 370px !important;
}

.event-modal .input-box .form-control, .input-box .form-select {
  border-radius: 5px !important;
}

/* .input-box.guests {
  height: 45px !important;
  max-height: max-content !important;
  background-color: var(--body-dark-bg) !important;
  border-radius: 5px !important;
}

.css-b62m3t-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
} */

.css-11f69jj-control:hover {
  border: none !important;
  box-shadow: none !important;
}


.event-modal .input-box .form-control:focus, .input-box .form-select:focus, .css-11f69jj-control:focus {
  border: 1px solid var(--primary-color) !important;
  box-shadow: none !important;
}

.responsive-calendar {
  width: 100%;
}

@media screen and (max-width: 785px) {
  .outer-box.fullCal .fc-toolbar-chunk:last-child {
    padding-right: 115px;
  }
  .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
    flex-direction: column !important;
    gap: 20px !important;
  }
  .responsive-calendar {
    width: 100%;
    max-width: 100%;
  }
  .outer-box.fullCal .addEvent {
    top: 110px;
    width: 100px;
    font: normal normal 600 14px/20px "Source Sans Pro", sans-serif !important;
  }
}