.reportflex {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
}
.box-inner .action-flex.reports {
    margin-bottom: 45px;
}
.box-inner .action-flex.report {
    padding-left: 60px;
    padding-right: 60px;
    margin-bottom: 60px;
}
.reportflex span.title {
    font: normal normal 400 18px/24px 'Avenir', sans-serif!important;;
    letter-spacing: 0px;
    color: var(--whiteColor);
    text-align: center;
}
.reportflex span.desc {
    font: normal normal 400 15px/20px 'Avenir', sans-serif!important;;
    letter-spacing: 0px;
    color: var(--whiteColor);
    text-align: center;
}

.box-inner .action-flex.report .action-item {
    width: 270px;
    max-width: 100%;
    height: 75px;
    padding: 10px;
}
.box-inner .action-flex.report .action-item.one {
    width: 270px;
}
.box-inner .action-flex.report .action-item.two {
    width: 375px;
}
.box-inner .action-flex.report .action-item.three {
    width: 270px;
}
.circles {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.circles .circle {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background: var(--chips-color) 0% 0% no-repeat padding-box;
}
.circles .circle.active {
    background: var(--whiteColor) 0% 0% no-repeat padding-box;
}

.add-columns {
    width: 1125px;
    max-width: 100%;
    margin: 0 auto;
    display: block;
}
.add-columns label {
    font: normal normal 400 15px/20px 'Avenir', sans-serif!important;;
    letter-spacing: 0px;
    color: var(--whiteColor);
}

.draggable-items, .dropable-items {
    background: var(--chips-color) 0% 0% no-repeat padding-box;
    border: 1px solid var(--chips-color);
    border-radius: 10px;
    width: 385px;
    height: auto;
    min-height: 375px;
    /* overflow: overlay; */
    padding: 20px;
}
.draggable-items .info, .dropable-items .info {
    font: normal normal 400 15px/20px 'Avenir', sans-serif!important;;
    letter-spacing: 0px;
    color: var(--whiteColor);
    text-align: center;
}
.dropable-items .accordion-body {
    background-color: transparent !important;
}
.items-list .accordion {
    --bs-accordion-bg: var(--chips-color) !important;
}
.items-list .accordion-item {
    margin-bottom: 0px;
    border: none !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}
.items-list .accordion-item .accordion-button {
    background-color: var(--body-dark-bg);
    font: normal normal 400 15px/20px 'Avenir', sans-serif!important;;
    letter-spacing: 0px;
    color: var(--whiteColor);
    border: none !important;
    box-shadow: none !important;
}
.items-list .accordion-body {
    background-color: var(--body-dark-bg);
}
.items-list .accordion-body .list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.items-list .accordion-body .list .list-item {
    background-color: var(--chips-color);
    width: 100%;
    height: 30px;
    font: normal normal 400 10px/30px 'Avenir', sans-serif!important;;
    letter-spacing: 0px;
    color: var(--whiteColor);
    padding-left: 15px;
    border-radius: 5px;
    cursor: pointer;
}
.items-list .accordion-body .list .list-item.checked {
    background-color: var(--primary-color);
    color: var(--body-dark-bg);
}
.items-list .accordion-body .list .list-item input[type="checkbox"] {
    opacity: 0;
    width: 288px;
    max-width: 100%;
    height: 30px;
    position: absolute;
    cursor: pointer;
}
.add-columns .action {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.add-columns .action button {
    background: var(--chips-color) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    width: 130px;
    height: 35px;
    font: normal normal 400 18px/24px 'Avenir', sans-serif!important;;
    letter-spacing: 0px;
    color: var(--whiteColor);
    position: relative;
}
.add-columns .action button span.icon svg {
    fill: var(--whiteColor);
}
.add-columns .action button span.icon {
    position: absolute;
}
.add-columns .action button span.icon.left {
    left: 0;
}
.add-columns .action button span.icon.right {
    right: 0;
    width: 20px;
}
.dropable-items .items-list {
    margin-top: 20px;
}
.dropable-items .items-list .accordion-body .list .list-item {
    cursor: move;
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: var(--body-dark-bg) !important;
}

.dropable-items .items-list .accordion-body .list .list-item.checked {
    background-color: var(--primary-color) !important;
}
div.dt-buttons {
    float: right;
}
.dt-button.rep-down-btns {
    background: transparent !important;
    width: max-content;
    outline: none !important;
    border: none !important;
}

.incomplete, .Not.complete {
    color: #EEC213;
}
.completed, .Completed {
    color: #3498DB;
}
.passed {
    color: #2ecc72;
}
.failed {
    color: #FF362E;
}
.cert-down {
    width: max-content;
    background-color: transparent !important;
}
.box-outer.right .box-inner .search-bar .input-box .form-control {
    width: 100% !important;
    height: 45px;
    background: var(--dark-bg) 0% 0% no-repeat padding-box;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.04);
    border-radius: 10px !important;
    border: none !important;
    outline: none;
    font: normal normal 400 14px/18px 'source-sans-pro', sans-serif;
    letter-spacing: 0px;
    /* color: rgb(255, 255, 255) !important; */
    color: var(--whiteColor);
    /* margin-bottom: 45px; */
}

.input-box .form-control::placeholder {
    color: var(--whiteColor) !important;
}

.input-box .form-control, .input-box .form-select {
    width: 135px !important;
}

.filter.reports {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
    .box-inner .action-flex.report .action-item {
        width: 300px !important;
    }
}
@media only screen and (max-width: 992px) {
    .box-inner .action-flex.report .action-item {
        width: 100% !important;
    }
    .box-inner .action-flex.report {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.input-box .form-select {
    appearance: auto !important;
}