.settings-menu,
.notification-menu {
  background: var(--dark-bg) 0% 0% no-repeat padding-box;
  position: fixed;
  top: 0;
  right: 0;
  width: 350px;
  height: 100vh;
  z-index: 101;
  display: none;
  overflow: scroll;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}
.sidebar-toggle {
  position: relative;
  padding-left: 0px;
}
.sidebar-toggle span {
  color: var(--whiteColor) !important;
  margin-right: 20px;
  /* font: normal normal 400 20px/50px 'Avenir', sans-serif !important; */
  font: normal normal 400 16px/50px 'Avenir', sans-serif !important;
}
.settings-menu.active,
.notification-menu.active {
  display: block;
}
.settings-menu .deleteIcon {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.notification-menu .deleteIcon {
  position: absolute;
  top: 20px;
  left: 10px;
  cursor: pointer;
  width: 325px;
}
.notification-menu .deleteIcon svg,
.settings-menu .deleteIcon svg {
  fill: var(--whiteColor) !important;
}
.settings-menu .menu,
.notification-menu .menu {
  padding: 10px;
  margin-top: 75px;
}
.settings-menu .menu ul,
.notification-menu .menu ul {
  list-style-type: none;
  padding-left: 0px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.settings-menu .menu ul li {
  background: var(--body-dark-bg) 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 10px;
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding-left: 25px;
}

.notification-menu .menu ul li {
  background: var(--body-dark-bg) 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 10px;
  width: 100%;
  /* height: 75px; */
  height: max-content;
  padding: 10px 0px;
  padding-left: 25px;
}

.settings-menu .menu ul li a {
  font: normal normal 400 20px/50px 'Avenir', sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor) !important;
}

.notifcation-flex {
  display: flex;
  align-items: center;
  line-height: 75px;
  gap: 15px;
}
.notifcation-flex .notify-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.notifcation-flex .notify-info span.name {
  font: normal normal 400 16px/20px "source-sans-pro", sans-serif;
  letter-spacing: 0px;
  color: var(--whiteColor);
}
.notifcation-flex .notify-info span.desc {
  font: normal normal 400 14px/18px "source-sans-pro", sans-serif;
  letter-spacing: 0px;
  color: var(--whiteColor);
}
.notifcation-flex .notify-info span.time {
  font: normal normal 400 10px/15px "source-sans-pro", sans-serif;
  letter-spacing: 0px;
  color: var(--whiteColor);
}
.notifcation-flex .profile-img img {
  width: 60px;
  height: 60px;
}

.deleteIcon .notify-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.deleteIcon .notify-filter select {
  background-color: var(--chips-color);
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  font: normal normal 400 16px/20px "source-sans-pro", sans-serif;
  letter-spacing: 0px;
  color: var(--whiteColor);
}
.read-notify span {
  font: normal normal 400 16px/20px "source-sans-pro", sans-serif;
  letter-spacing: 0px;
  color: var(--whiteColor);
}


.VIpgJd-ZVi9od-l4eHX-hSRGPd,
.VIpgJd-ZVi9od-l4eHX-hSRGPd:link,
.VIpgJd-ZVi9od-l4eHX-hSRGPd:visited,
.VIpgJd-ZVi9od-l4eHX-hSRGPd:hover,
.VIpgJd-ZVi9od-l4eHX-hSRGPd:active {
  display: none !important;
}

.goog-te-gadget .goog-te-combo {
  background-color: var(--body-dark-bg) !important;
  color: var(--whiteColor) !important;
}

.cart-menu {
  background: var(--dark-bg) 0% 0% no-repeat padding-box;
  position: fixed;
  top: 80px;
  right: 33px;
  width: 400px;
  height: 600px;
  z-index: 101;
  display: none;
  overflow: auto;
  border: 1px solid #707070;
  border-radius: 10px;
  opacity: 1;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  padding: 10px;
}

.cart-menu.active {
  display: block;
}

@media only screen and (max-width: 600px) {
  .role_name {
    display: none;
  }
}
