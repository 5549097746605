.signup .form {
    padding: 25px;
}

.signup .form .col-md-6 input.input {
    width: 100%;
}
.signup .form input.input {
    width: 100%;
}
.signup .form-group {
    width: 100%;
}


.signup .form .form-group {
    margin-bottom: 35px;
}