.track .course-lists .course-item {
    background-color: transparent !important;
    padding: 0px !important;
}

.track .course-info .cinf-flex {
    justify-content: flex-start;
}
.track .course-lists .course-item .course-act {
    align-items: flex-start;
    justify-content: flex-start;
}
.track .course-lists .course-item .course-img {
    width: 150px;
    /* height: 145px; */
    height: 100%;
}
.track .course-lists .course-item .course-img  img {
    width: 150px;
    height: 100%;
}
.track .inner-box.course .course-lists.course {
    height: max-content !important;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-right: 0px !important;
    gap: 25px;
}
.track .course-lists .course-item {
    justify-content: flex-start;
}
.track .course-info {
    height: 150px;
} 
.track  .course-lists .course-item {
    width: max-content;
    height: 100%;
}
.track .course-track {
    width: 350px;
    max-width: 100%;
    height: 145px;
    background: var(--dark-bg) 0% 0% no-repeat padding-box;
    border-radius: 18px;
}
.track .progress {
    background-color: var(--dark-bg) !important;
    width: 200px;
}
.track .assignment {
    width: 275px;
    height: 145px;
    background: var(--dark-bg) 0% 0% no-repeat padding-box;
    border-radius: 18px;
}
.assignment .assignment-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 145px;
    gap: 20px;
}
.assignment .assignment-list .assignment-item {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.assignment .assignment-list .assignment-item .name span {
    font: normal normal normal 15px/20px 'Avenir', sans-serif !important;
    letter-spacing: 0px;
    color: var(--whiteColor);
}
.assignment .assignment-list .assignment-item .date span {
    font: normal normal normal 10px/15px 'Avenir', sans-serif !important;
    letter-spacing: 0px;
    color: var(--whiteColor);
}
.track .inner-box.left .tab-content {
    height: 165px !important;
}
.inner-box.course.mb-3:last-child {
    margin-bottom: 0px !important;
}

.course-lists .course-item .course-info {
    gap: 20px;
}

@media only screen and (max-width: 1290px) {
    .track .course-track {
        width: 250px;
    }
    .assignment .assignment-list .assignment-item {
        flex-direction: column;
        gap: 0px;
    }
    .assignment .assignment-list {
        gap: 0px;
        justify-content: flex-start;
    }
    .track .assignment {
        overflow: auto;
    }
}

