
.main-boxes {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* margin-bottom: 10px; */
  justify-content: center;
  /* align-items: center; */
}

.boxes1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 40px;
  color: var(--whiteColor);
  justify-content: space-between;
  align-items: center;
}

.box {
  background: var(--body-dark-bg) 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 160px;
  gap: 1.8em;
  overflow: hidden;
  align-items: flex-start;
  padding: 12px;
  border-radius: 20px;
  opacity: 1;
  position: relative;
}

.bg-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -20px;
}

.box-course-detail span.count {
  font: normal normal 600 36px/22px "Source Sans Pro", sans-serif !important;
  letter-spacing: 1.89px;
  color: var(--whiteColor);
}

.box-course-detail span.count-info {
  font: normal normal 600 14px/20px "Source Sans Pro", sans-serif !important;
  letter-spacing: 0.64px;
  color: var(--whiteColor);
}

.box-course-detail {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 15px;
  left: 50px;
  gap: 15px;
}

.chart-graph {
  background: var(--body-dark-bg) 0% 0% no-repeat padding-box;
  border-radius: 20px;
  /* padding: 10px 0px 0px 0px; */
}

.pieChart {
  height: 338px;
  /*width: 30em !important; */
  padding: 16px;
  font: normal normal normal 14px/16px "Source Sans Pro", sans-serif !important;
  text-align: center;
  color: var(--whiteColor);
  overflow: hidden;
}

.main-donut-container {
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: center;
  /* align-items: center; */
}

.donut-container {
  /* margin-top: 20px; */
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: center;
  align-items: center;
}

.do-color1 {
  background-color: #0058ff;
  height: 10px;
  width: 10px;
}

.do-color2 {
  background-color: #21d59b;
  height: 10px;
  width: 10px;
}

.do-color3 {
  background-color: #ffc700;
  height: 10px;
  width: 10px;
}

.do-color4 {
  background-color: #f99600;
  height: 10px;
  width: 10px;
}

.donut-data {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.donutDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.donutDetails .donutDetails-flex {
  display: flex;
  align-items: center;
  gap: 15px;
}
/* .pieChart canvas {
  height: 150px;
  width: 150px;
} */

.newCourseChart {
  width: 36em;
  padding-right: 0px;
  padding-left: 0px;
  /* margin-bottom: 0px; */
  padding-bottom: 0px;
}

.outer-box.learn .inner-box,
.learner-dash .inner-box,
.learner-dash .outer-box,
.outer-box.recomd-courses {
  height: max-content;
}

.outer-box.learn {
  height: max-content;
  /* margin-bottom: 20px; */
}
.outer-box.learn.mb-3 {
  margin-bottom: 16px !important;
}

.outer-box.learn .inner-box {
  height: max-content;
}

.outer-box.learn .inner-box .custom-hight {
  height: 325px !important;
}

.outer-box.learn .inner-box.custom-hight {
  height: 419px !important;
  position: relative;
}
.outer-box .inner-box.recom-courses {
  height: 152px !important;
}

.inner-box.cer-dash,
.inner-box.active-courses {
  height: 340px !important;
  overflow: hidden;
}

.inner-box.pathway-list .path-list {
  height: 325px !important;
  overflow: auto;
  padding-right: 10px;
}

.outer-box.learn.track .course-tracking {
  height: 605px;
  overflow: auto;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.inner-box.recom-courses {
  position: relative;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 width: 500px;
  text-align: center;
  margin: auto;
}

.loading h1 {
  color: var(--whiteColor);
  font: normal normal 700 18px/20px "Source Sans Pro", sans-serif !important;
}

.outer-box .inner-box.coursecount {
  height: 278px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.outer-box .inner-box.coursegraph {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
  margin-bottom: 0px !important;
}

.newcflex {
  position: absolute;
  top: 18.5em;
  margin-left: 21em;
}

.cflex {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.outer-box .inner-box.active-courses .course-lists.course {
  height: 268px !important;
  overflow: auto;
  padding-right: 15px;
}

.course-lists {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.course-lists.course {
  height: 400px;
  overflow: auto;
  /* padding-right: 20px; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.course-lists.course.grid1 {
  grid-template-columns: repeat(1, 1fr);
}
.learn .course-lists.course {
  padding-bottom: 10px;
  padding-right: 15px;
}

.course-lists.pathway {
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 15px;
}

.recom-courses .course-lists.pathway {
  overflow: hidden;
}

.inner-box.pathway-list .pathway-item {
  display: flex;
  align-items: center;
  gap: 50px;
  margin-bottom: 20px;
}
.course-lists .course-item {
  display: flex;
  justify-content: space-between;
  background: var(--dark-bg) 0% 0% no-repeat padding-box;
  border-radius: 18px;
  padding: 10px;
  width: 100%;
  /* max-width: 100%; */
  height: 105px;
}

.course-lists .item.course-item {
  justify-content: flex-start;
  gap: 20px;
}

.track .course-lists .course-item {
  gap: 10px;
}

.track .course-info {
  flex-direction: column !important;
}

.track .course-info .cinfo {
  gap: 5px;
  margin-bottom: 10px;
}

.inner-box.recom-courses .pathway-item .course-lists .course-item {
  width: 300px;
  height: 120px;
}

.course-lists .course-item .course-img {
  width: 90px;
  height: 100px;
  height: 100% !important;
  border-radius: 12px;
}

.course-lists .course-item .course-img img {
  width: 90px;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

/* .outer-box.learn .course-lists.course .course-item .course-img {
  width: 115px;
  height: 85px;
}
.outer-box.learn .course-lists.course .course-item .course-img img {
  width: 115px;
} */

.course-lists.pathway .course-item .course-img img {
  width: 85px;
  height: 85px;
}

.inner-box.recom-courses .course-lists.pathway .course-item .course-img img {
  width: 100px;
  height: 100px;
}

.inner-box.recom-courses .course-lists.pathway .course-item .course-info .cinfo {
  gap: 25px;
}

.inner-box.pathway-list .pathway-item .pathway-info .circles-flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.inner-box.pathway-list .pathway-item .pathway-info .circles-flex .circle {
  background: var(--chips-color) 0% 0% no-repeat padding-box;
  border: 1px solid var(--chips-color);
  width: 15px;
  height: 15px;
  border-radius: 100%;
}

.inner-box.pathway-list
  .pathway-item
  .pathway-info
  .circles-flex
  .circle.active {
  background: var(--whiteColor) 0% 0% no-repeat padding-box;
}

.course-lists .course-item .course-info,
.inner-box.pathway-list .pathway-item .pathway-info {
  /* padding-left: 10px; */
  display: flex;
  flex-direction: column;
  flex-direction: row !important;
  gap: 25px;
  align-items: flex-start;
  justify-content: space-between;
}

.course-lists .item.course-item .course-info {
  flex-direction: column !important;
  gap: 10px;
}

.course-info .cinf-flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  height: 100%;
}

.course-lists .course-item .course-info .cinfo,
.inner-box.pathway-list .pathway-item .pathway-info .cinfo {
  display: flex;
  flex-direction: column;
}

.inner-box.pathway-list .pathway-item .pathway-info .cinfo {
  margin-bottom: 15px;
  width: 200px;
}

.course-lists .course-item .course-info span.cname,
.inner-box.pathway-list .pathway-item .pathway-info span.cname {
  font: normal normal 700 16px/18px "Source Sans Pro", sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
  text-transform: capitalize;
}

.course-lists .course-item .course-info span.cname {
  margin-bottom: 5px;
}

.course-lists .course-item .course-info span.catname,
.inner-box.pathway-list .pathway-item .pathway-info span.catname {
  font: normal normal 400 15px/20px "Source Sans Pro", sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
}

.course-lists .course-item .course-info .range {
  /* width: 200px; */
  height: 10px;
  margin-bottom: 10px;
  padding-right: 20px;
}

.course-lists .course-item .course-act {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100%;
}

.course-lists .course-item .course-act button {
  background: var(--primary-color) 0% 0% no-repeat padding-box;
  border-radius: 34px;
  width: 100px;
  height: 30px;
  font: normal normal 600 12px/25px "Source Sans Pro", sans-serif !important;
  letter-spacing: 0px;
  color: var(--body-dark-bg);
}

.wrapper {
  border-radius: 30px;
}

.slide {
  background-color: var(--body-dark-bg);
  border-radius: 30px;
  width: 100%;
  height: 10px;
}

.barCompleted {
  background-color: var(--primary-color);
  width: 60%;
  height: 10px;
  border-radius: 30px;
}

.label {
  opacity: 0;
}
.outer-box.event-calendar .assignments {
  height: 500px;
}
.assignments {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
}

.assignments .assignments-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.assignments .assignments-item .date { 
  width: 25px;
}

.event .assignments .assignments-item .date {
  width: 50px;
}

.assignments .assignments-item .date span {
  letter-spacing: 0px;
  color: var(--whiteColor);
  font: normal normal 400 15px/30px "Source Sans Pro", sans-serif !important;
}

.assignments .assignments-item .info span.cname {
  font: normal normal 700 15px/20px "Source Sans Pro", sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
}

.assignments .assignments-item .info {
  display: flex;
  flex-direction: column;
}

.assignments .assignments-item .info span.catname {
  font: normal normal 400 12px/15px "Source Sans Pro", sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
}

.assignments .assignments-item a span {
  font: normal normal 400 12px/15px "Source Sans Pro", sans-serif !important;
  letter-spacing: 0px;
  color: var(--primary-color) !important;
}

.inner-box-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.inner-box-flex .nav-tabs .nav-item.show .nav-link,
.inner-box-flex .nav-tabs .nav-link.active {
  border: none !important;
  color: var(--primary-color) !important;
  background-color: var(--body-dark-bg) !important;
}

.inner-box-flex .nav-tabs .nav-item.show .nav-link,
.inner-box-flex .nav-tabs .nav-link {
  width: max-content;
  padding-right: 0px !important;
}

.inner-box-flex .nav-tabs .nav-item.show .nav-link,
.inner-box-flex .nav-tabs .nav-link.active::after {
  display: none;
}

.inner-box-flex .nav-tabs {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  margin-bottom: 10px !important;
}

.inner-box-flex .user-flex,
.inner-box.right .user-flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 10px;
}

.user-flex .user-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.user-flex .user-info span.name {
  font: normal normal 400 15px/20px "Source Sans Pro", sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
}

.user-flex .user-info span.catname {
  font: normal normal 400 12px/15px "Source Sans Pro", sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
}

.inner-box.left {
  width: 100%;
}

/* .learn .inner-box.left .tab-content,
.track .inner-box.left .tab-content {
  height: 100px;
  overflow: auto;
} */

.inner-box.left .search-bar input {
  width: 100%;
  height: 25px;
  border: 1px solid #a1a5a5;
  background-color: transparent;
  border-radius: 15px;
  padding-left: 10px;
  font: normal normal 400 12px/15px "Source Sans Pro", sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
}

.outer-box.chat {
  position: relative;
}

.inner-box.right {
  position: fixed;
  right: 10px;
  bottom: 10px;
  width: 300px;
  height: 400px;
  display: none;
  transition: all 0.5s;
  background: var(--dark-bg) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 1;
}

.inner-box.right .top {
  border-bottom: 1px solid #707070;
  padding: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inner-box.right.active {
  display: block;
}

.inner-box.right .footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.inner-box.right .footer .input-area {
  position: relative;
}

.inner-box.right .footer .input-area input {
  background-color: var(--body-dark-bg);
  border: none !important;
  width: 100%;
  height: 35px;
  padding-left: 30px;
  color: var(--whiteColor);
}

.inner-box.right .footer .input-area .icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: var(--whiteColor);
}

.inner-box.right .body .left-m,
.inner-box.right .body .right-m {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
}

.box3 {
  width: 175px;
  margin-bottom: 25px;
  /* margin: 50px auto; */
  border-radius: 15px;
  background: var(--body-dark-bg);
  padding: 10px;
  text-align: center;
  position: relative;
  font: normal normal 400 10px/16px "Source Sans Pro", sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
}

/* speech bubble 13 */

.sb13:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 15px solid var(--body-dark-bg);
  border-right: 15px solid transparent;
  border-top: 15px solid var(--body-dark-bg);
  border-bottom: 15px solid transparent;
  right: -16px;
  top: 0px;
}

/* speech bubble 14 */

.sb14:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 15px solid transparent;
  border-right: 15px solid var(--body-dark-bg);
  border-top: 15px solid var(--body-dark-bg);
  border-bottom: 15px solid transparent;
  left: -16px;
  top: 0px;
}

.outer-box .action-flex {
  margin-bottom: 50px;
}

.action-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.action-item span svg {
  fill: var(--whiteColor);
}

.action-item span {
  font: normal normal 400 15px/20px "Source Sans Pro", sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
}

.course-info {
  display: flex;
  flex-direction: column;
}

.course-info .course-name {
  font: normal normal 700 20px/25px "Source Sans Pro", sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
  margin-bottom: 10px;
}

.course-modules label {
  font: normal normal 700 15px/20px "Source Sans Pro", sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
  margin-bottom: 15px;
}

.course-modules .topics-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.topic-content .topic-name.g-10 {
  gap: 10px;
}

.course-modules .topics-list .topics .topic-info {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.course-modules .topics-list .topics .topic-info .topic-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 250px;
  gap: 10px;
}

.topic-content .topic-name {
  display: flex;
  flex-direction: column;
}

.topic-info .icon span svg {
  fill: var(--nav-inactive);
}

.course-modules .topics-list .topics .topic-info .topic-content span.name {
  font: normal normal 400 14px/18px "Source Sans Pro", sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
}

.course-modules .topics-list .topics .topic-info .topic-content span.type {
  font: normal normal 400 12px/24px "Source Sans Pro", sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
  margin-bottom: 5px;
}

.course-modules .topics-list .topics .topic-info .topic-content span.unlock {
  font: normal normal 400 10px/12px "Source Sans Pro", sans-serif !important;
  letter-spacing: 0px;
  color: var(--primary-color);
  display: flex;
  align-items: center;
  gap: 5px;
}

.course-modules
  .topics-list
  .topics
  .topic-info
  .topic-content
  span.unlock
  svg {
  width: 12px;
  height: 12px;
  fill: var(--primary-color);
}
.loadinggif-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.loadinggif-flex span {
  color: var(--whiteColor);
}
.course-overview img.loadinggif {
  width: 250px !important;
}

.loadinggif-flex img {
  width: 500px !important;
}

.course-overview img {
  width: 100%;
}

.outer-box p {
  font: normal normal 400 12px/24px "Source Sans Pro", sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
}

.course-img iframe {
  width: 100%;
  height: 500px;
}

.learner-dash .course-stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.learner-dash .inner-box label {
  font: normal normal 600 15px/20px "Source Sans Pro", sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.learner-dash .course-stats .course-item {
  background: var(--dark-bg) 0% 0% no-repeat padding-box;
  border-radius: 12px;
  /* width: 125px;
    height: 40px; */
  width: 125px;
  height: 60px;
  display: flex;
  /* flex-direction: column-reverse; */
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}

.learner-dash .course-stats .course-item span {
  font: normal normal normal 15px/20px "Source Sans Pro", sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
}

.learner-dash .course-stats .course-item span.count {
  font: normal normal 900 15px/20px "Source Sans Pro", sans-serif !important;
  letter-spacing: 0px;
  color: var(--primary-color);
}

.learner-dash .inner-box .course-lists.course {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  justify-content: center;
  width: 100%;
}

.learner-dash .inner-box .course-lists.course .course-item {
  width: 100%;
  /* height: max-content; */
  height: 100px;
}

/* .learner-dash .inner-box .course-lists .course-item .course-img {
  width: 100px;
  height: 100px;
} */

/* .learner-dash .inner-box .course-lists .course-item .course-info .range {
  width: 225px;
} */

.cflex.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.progress,
.progress-stacked {
  --bs-progress-bar-bg: var(--primary-color) !important;
}

.progress {
  background-color: var(--body-dark-bg) !important;
  width: 200px;
  height: 10px;
}

.progress-bar {
  border-radius: 10px;
  background-color: var(--primary-color) !important;
}

.certificate-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  overflow: auto;
  height: 500px !important;
}

.certificate-grid.dash {
  height: 325px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.certificate-grid .certificate {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.certificate-grid .inner-box {
  position: relative;
}

.certificate-grid .certificate .cer-act {
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  right: 10px;
  top: 5px;
}

.certificate-grid .certificate .cer-act button {
  width: max-content;
  height: max-content;
  background-color: transparent;
}

.certificate-grid .certificate .cer-act svg {
  fill: var(--whiteColor);
}

.certificate-grid .certificate .cer-img {
  width: 150px;
  max-width: 100%;
  height: 100px;
  margin-bottom: 10px;
}

.certificate-grid.dash .certificate .cer-img {
  margin-bottom: 0px;
}

.certificate-grid .certificate img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.certificate-grid .certificate .cer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.certificate-grid .certificate .cer-content span {
  font: normal normal 400 14px/16px "Source Sans Pro", sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
}

.certificate-grid .certificate .cer-content span.coursename {
  font: normal normal 400 16px/20px "Source Sans Pro", sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
  margin-bottom: 5px;
  text-align: center;
}

.certificate-grid.dash .certificate .cer-content span {
  font: normal normal 400 10px/12px "Source Sans Pro", sans-serif !important;
}

.certificate-grid.dash .certificate .cer-content span.coursename {
  font: normal normal 400 12px/16px "Source Sans Pro", sans-serif !important;
}

.no-evnets {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.no-evnets span {
  color: var(--whiteColor);
}

.learner-dash .outer-box.chat .inner-box.left .tab-content .user-list {
  height: 470px;
  overflow: auto;
}

.learn .outer-box.chat .inner-box.left .tab-content .user-list {
  height: 270px;
  overflow: auto;
}

.tracking .outer-box.chat .inner-box.left .tab-content .user-list {
  height: 370px;
  overflow: auto;
}

.learner-dash .inner-box-flex .user-flex {
  margin-bottom: 25px;
}

.inner-box.right .deleteIcon svg {
  fill: var(--whiteColor);
}

.achieve .certificate-grid {
  display: flex;
  flex-wrap: wrap;
}

.achieve .certificate-grid .inner-box {
  width: 250px;
  height: 200px;
  border-radius: 10px;
}
.bg-icon span svg {
  fill: var(--progress-icon);
}


.achieve .error-req.flex-end {
  display: flex;
  align-items: center;
  gap: 10px;
}

.achieve .error-req.flex-end .search-bar {
  background-color: var(--body-dark-bg);
  border-radius: 10px;
  width: 300px;
  height: 25px;
}

.achieve .error-req.flex-end .search-bar input {
  background-color: transparent;
  outline: none !important;
  border: none;
  padding-left: 10px;
  font: normal normal normal 15px/20px "Source Sans Pro", sans-serif !important;
  letter-spacing: 0px;
  color: var(--whiteColor);
}

.outer-box.learn.achieve .cflex {
  margin-bottom: 25px;
}

.outer-box.learn.achieve {
  padding-left: 25px;
  padding-right: 25px;
}

.outer-box.learn.achieve {
  height: 375px;
}
.outer-box.learn.recomanded {
  height: max-content;
}

.outer-box.track .inner-box {
  height: max-content;
}

.outer-box.track .inner-box.course {
  height: 175px;
  height: max-content !important;
}

.outer-box.flex-row {
  gap: 10px;
  align-items: center;
}

.outer-box.flex-row .inner-box.rec-conv {
  width: 350px;
}

.outer-box.flex-row .inner-box.rec-conv .cflex {
  justify-content: center;
}

.outer-box.flex-row .inner-box.chatbot .inner-box.right {
  display: block;
  position: relative;
  margin-top: 20px;
  background-color: transparent !important;
  box-shadow: none !important;
  width: 100%;
  height: 97%;
}

.outer-box.flex-row .inner-box.chatbot .inner-box.right .top {
  display: none;
}

.outer-box.flex-row .inner-box.chatbot .inner-box.right .box3 {
  background: var(--dark-bg);
  width: 100%;
}

.outer-box.flex-row .inner-box.chatbot .inner-box.right .sb14:before {
  border-right: 15px solid var(--dark-bg);
  border-top: 15px solid var(--dark-bg);
}

.outer-box.flex-row .inner-box.chatbot .inner-box.right .sb13:before {
  border-top: 15px solid var(--dark-bg);
  border-left: 15px solid var(--dark-bg);
}

.outer-box.flex-row .inner-box.right .footer .input-area input {
  background-color: var(--dark-bg) !important;
}

.outer-box.learn.achieve .certificate-grid .certificate .cer-img-bg {
  width: 200px;
  height: 100px;
  background: var(--dark-bg) 0% 0% no-repeat padding-box;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  position: relative;
}

.outer-box.learn.achieve .certificate-grid .certificate .cer-img {
  margin-bottom: 0px !important;
  height: 85px !important;
}

.outer-box.learn.achieve
  .certificate-grid
  .certificate
  .cer-img-bg
  .achive-icon {
  position: absolute;
  top: -7px;
  left: 5px;
}

.learner-dash .flex-cal,
.learn .flex-cal,
.tracking .flex-cal {
  flex-direction: column;
  width: 100%;
}

.learner-dash .flex-cal .events {
  height: max-content;
  width: 100%;
}

.learner-dash .flex-cal .events .events-item {
  flex-direction: column;
}

.typing-demo {
  width: 12;
  animation: typing 2s steps(12), blink 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-family: monospace;
  font-size: 2em;
}

@keyframes typing {
  from {
    width: 0;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

.user-list.learner {
  height: 300px;
  overflow: auto;
}

.outer-box .course-overview.player {
  width: 100%;
  /* height: 500px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.outer-box .course-overview .course-img {
  width: 100%;
  height: 500px !important;
}

.outer-box .course-overview .course-img .course-preview {
  width: 100%;
  height: 500px !important;
  position: relative;
}

.outer-box .course-overview .course-img .course-preview .overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  border-radius: 10px;
}

.outer-box .course-overview .course-img .course-preview button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75px;
  height: 75px;
  border-radius: 100%;
}

.outer-box .course-overview .course-img .course-preview button svg {
  margin-left: 8px;
}

.outer-box .course-overview .course-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.course-modules .nav-link {
  background-color: transparent !important;
  box-shadow: none !important;
  height: max-content !important;
}

.course-modules .nav-item.disabled {
  cursor: not-allowed;
}

.completedAct {
  color: rgb(119, 237, 139);
  background-color: rgba(34, 197, 94, 0.16);
  width: 50px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchBar {
  background: var(--body-dark-bg) 0 0 no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 10px;
  opacity: 1;
  color: var(--whiteColor);
  font: normal normal 400 15px/18px source-sans-pro, sans-serif !important;
  height: 35px;
  letter-spacing: 0;
  padding: 4px 15px;
  width: 300px;
}

.searchbar-view {
  position: relative;
}

.searchbar-view span svg {
  position: absolute;
  left: 88%;
  top: 30%;
  stroke: var(--whiteColor) !important;
  fill: var(--whiteColor) !important;
}

.catalog-icon {
  stroke: var(--whiteColor) !important;
  fill: var(--whiteColor) !important;
  cursor: pointer;
}

.searchbar-view-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* margin-right: 70px; */
  margin-bottom: 25px;
}

.container {
  padding: 0px;
}

.grid-container {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 35px;
  color: var(--whiteColor);
}

.grid-item {
  background: var(--dark-bg) 0% 0% no-repeat padding-box;
  border-radius: 9px;
  opacity: 1;
  height: 250px;
  padding: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.form-control.mx-hight {
  height: max-content !important;
}

.list-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background: var(--dark-bg) 0% 0% no-repeat padding-box;
  border-radius: 9px;
  opacity: 1;
  height: 100px;
  padding: 10px;
  gap: 10px;
}

.list-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 25px;
  color: var(--whiteColor);
  height: 500px;
  overflow: auto;
}

.list-container-cart {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 25px;
  color: var(--whiteColor);
  width: 100%;
}

.course-img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  opacity: 1;
}

.list-course-img {
  height: auto;
  width: 15%;
  border-radius: 5px;
}

.courseDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 0px;
  gap: 0px;
}

.courseDetails2 {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.list-course-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 6px;
  padding-top: 5px;
  width: 100%;
}

.list-course-details2 {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.course-name {
  text-align: left;
  font: normal normal bold 15px Source Sans Pro;
  letter-spacing: 0px;
  color: var(--whiteColor);
  opacity: 1;
  width: 100%;
}

.list-course-name {
  text-align: left;
  font: normal normal bold 18px Source Sans Pro;
  letter-spacing: 0px;
  color: var(--whiteColor);
  opacity: 1;
}

.course-cat {
  font: normal normal normal 12px Source Sans Pro;
  letter-spacing: 0px;
  color: var(--whiteColor);
  opacity: 1;
  text-overflow: ellipsis;
  width: 100%;
  max-height: 20px !important;
  overflow-y: hidden;
}

.list-course-cat {
  font: normal normal normal 15px Source Sans Pro;
  letter-spacing: 0px;
  color: var(--whiteColor);
  opacity: 1;
  text-overflow: ellipsis;
}

.grid-item:hover {
  border: 1px solid var(--primary-color);
  opacity: 1;
}

.read-more-icon {
  fill: var(--whiteColor);
}

.grid-item:hover .read-more-icon {
  position: relative;
  overflow: hidden;
  fill: var(--primary-color);
}

.read-more {
  position: relative;
  margin-right: 0;
  display: inline-block;
  vertical-align: middle;
  text-indent: -60px;
  visibility: hidden;
  white-space: nowrap;
  opacity: 0;
  -webkit-transition: visibility 0.4s ease, margin-right 0.4s ease-out,
    text-indent 0.5s ease-out, opacity 0.4s ease;
  -ms-transition: visibility 0.4s ease, margin-right 0.4s ease-out,
    text-indent 0.5s ease-out, opacity 0.4s ease;
  transition: visibility 0.4s ease, margin-right 0.4s ease-out,
    text-indent 0.5s ease-out, opacity 0.4s ease;
  will-change: visibility, margin-right, text-indent, opacity;
  color: var(--whiteColor);
}

.grid-item:hover .read-more {
  color: var(--primary-color);
  opacity: 1;
  text-indent: 0;
  margin-right: 6px;
  visibility: visible;
  -webkit-transition: visibility 0.4s ease, margin-right 0.3s ease-out,
    text-indent 0.4s ease-out, opacity 0.4s ease;
  -ms-transition: visibility 0.4s ease, margin-right 0.3s ease-out,
    text-indent 0.4s ease-out, opacity 0.4s ease;
  transition: visibility 0.4s ease, margin-right 0.3s ease-out,
    text-indent 0.4s ease-out, opacity 0.4s ease;
}

.list-item:hover {
  border: 1px solid var(--primary-color);
  opacity: 1;
}

.gird-course-more {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--whiteColor);
  margin-top: 3px;
  width: 100%;
  padding: 0px 10px 2px 0px;
}

.grid-read-more {
  display: flex;
  flex-direction: row;
  gap: 2px;
  font: normal normal normal 12px/15px Source Sans Pro;
  overflow: hidden;
}

.grid-price {
  font: normal normal normal 16px/20px Source Sans Pro;
}

.list-course-more {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: flex-end;
  padding-right: 10px;
}

.list-read-more {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.list-item:hover .list-read-more {
  color: var(--primary-color);
}

.list-item:hover .read-more-icon {
  fill: var(--primary-color);
}

@media only screen and (max-width: 1300px) {
  .assignments .assignments-item .info {
    width: 100px !important;
  }

  .assignments .assignments-item .info span.cname {
    font: normal normal 700 12px/20px "Source Sans Pro", sans-serif !important;
  }

  .assignments .assignments-item .info span.catname,
  .assignments .assignments-item a span {
    font: normal normal 700 10px/15px "Source Sans Pro", sans-serif !important;
  }

  .course-lists.course {
    height: 385px !important;
  }
}

@media only screen and (max-width: 1125px) {
  .track .inner-box.course .course-lists.course {
    flex-direction: column;
  }
}

@media only screen and (max-width: 992px) {
  .assignments {
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .calendar {
    margin-bottom: 0px;
    height: 250px;
  }

  .chatbot .inner-box-flex .inner-box.left {
    height: 250px;
  }

  .learn .inner-box.left .tab-content,
  .track .inner-box.left .tab-content {
    height: 150px;
  }

  .outer-box.track .row {
    margin-bottom: 0px;
  }

  .assignments .assignments-item .date {
    width: 95px;
  }
  .learner-dash .inner-box .course-lists.course {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 925px) {
}

@media only screen and (max-width: 778px) {
}

@media only screen and (max-width: 450px) {
  .course-lists.course {
    grid-template-columns: repeat(1, 1fr);
  }

  .course-lists .course-item {
    flex-direction: column;
    height: max-content;
    align-items: flex-start;
    width: 100%;
    gap: 10px;
  }

  .course-lists .course-item .course-info {
    padding-left: 0px;
    gap: 10px;
  }

  .course-lists .course-item .course-img {
    width: 100%;
    /* height: 200px; */
    height: 100%;
  }

  .course-lists .course-item .course-img img {
    width: 100%;
  }

  .pathway-item {
    flex-direction: column;
  }

  .course-lists.pathway {
    flex-direction: column;
  }

  .certificate-grid .certificate .cer-img {
    width: 125px;
  }
}

.leaderboard-item {
  width: 100%;
  background-color: var(--dark-bg);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leaderboard-item .name span {
  color: #101010;
  font: normal normal normal 18px/20px "Source Sans Pro", sans-serif;
}

.leaderboard-item .xppoints span {
  color: #101010;
}

.leaderboard-item .profile {
  position: relative;
}

.leaderboard-item .profile .count {
  position: absolute;
  top: -10px;
  left: -5px;
}

.leaderboard-item .profile .count svg {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.leaderboard-item .profile .profile-pic {
  display: flex;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: var(--body-dark-bg);
  border-radius: 10px;
}

.leaderboard-item .profile .profile-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.leaderboard-item.gold {
  background: linear-gradient(90deg, #ffd949 50%, #ffec7b 100%);
}

.leaderboard-item.silver {
  background: linear-gradient(90deg, #d8d8c7 50%, #e0e0d0 100%);
}

.leaderboard-item.bronze {
  background: linear-gradient(90deg, #f4aa6a 50%, #f5b47c 100%);
}

.leaderboard-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.leaderboard-item.other .name span,
.leaderboard-item.other .xppoints span {
  color: var(--whiteColor);
}

.leaderboard-item .profile .count .circle {
  background-color: #f37401;
  border-radius: 100%;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leaderboard-item .profile .count .circle span {
  color: var(--whiteColor);
}

.add-user-form.feedback-form {
  align-items: flex-start;
}
.star-rating {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.star-rating button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  width: 50px;
  font-size: 24px;
}
.on {
  color: var(--primary-color);
}
.off {
  color: var(--whiteColor);
}

.buy-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.buy-btn button {
  background: var(--primary-color) 0% 0% no-repeat padding-box;
  border: 1px solid var(--primary-color);
  border-radius: 27px;
  opacity: 1;
  font: normal normal bold 15px Source Sans Pro;
  color: #28282b;
  opacity: 1;
  /* margin-left: 78%; */
}

.add-cart-btn button {
  background: var(--dark-bg) 0% 0% no-repeat padding-box;
  border: 1px solid var(--primary-color);
  border-radius: 27px;
  opacity: 1;
  font: normal normal 15px Source Sans Pro;
  color: var(--primary-color);
  opacity: 1;
}

.add-cart-btn button:hover {
  background: #9aa1a769 0% 0% no-repeat padding-box;
  color: white;
}

.cart-btn-details {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.enroll-text {
  color: #1beb79;
}

.heading h4 {
  color: var(--whiteColor);
  text-align: left;
  font: normal normal normal 20px/39px Source Sans Pro;
  letter-spacing: 0px;
  opacity: 1;
}

.list-item-cart {
  background-color: var(--body-dark-bg);
  height: 100px;
  text-align: left;
  font: normal normal normal 20px/36px Source Sans Pro;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  border-radius: 13px;
  opacity: 1;
  width: 100%;
}

.cart-img {
  width: 15%;
  padding: 5px;
  border-radius: 15px;
}

.cart-price-buy {
  gap: 17px;
  align-items: center;
}

.cart-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.react-calendar {
  width: 100% !important;
  max-width: 100%;
  background: var(--body-dark-bg) !important;
  padding: 20px 16px 8px 16px !important;
  border: 1px solid hsla(var(--hue-color), 6%, 50%, 0.16) !important;
  border-radius: 25px !important;
  margin-bottom: 30px !important;
}
.react-calendar__tile,
.react-calendar__month-view__weekdays__weekday {
  color: var(--whiteColor) !important;
}
.react-calendar__navigation button {
  color: var(--whiteColor) !important;
}
.react-calendar__navigation button:hover,
.react-calendar__navigation button:focus {
  background: none !important;
}
.react-calendar__tile {
  border-radius: 100%;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: var(--chips-color) !important;
  color: var(--primary-color) !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575 !important;
}

.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from{
  color: var(--whiteColor) !important;
  font: normal normal 700 12px/17px 'Open Sans' !important;
  letter-spacing: 0px !important;
  margin-bottom: 0px !important;
}

.modal-body .input-box .form-control, .modal-body .input-box .form-select {
  background: var(--body-dark-bg) 0 0 no-repeat padding-box !important;
}

.form-flex .add-course {
  width: 100%;
}

@media only screen and (min-width: 1366px) {
  .pieChart {
    height: 302px;
  }
  .progress {
    width: 100px;
  }
  .learner-dash .outer-box.chat .inner-box.left .tab-content .user-list {
    height: 315px;
  }
  .outer-box.learn.achieve {
    height: 618px;
  }
  .learn .outer-box.chat .inner-box.left .tab-content .user-list {
    height: 338px;
  }
  .tracking .outer-box.chat .inner-box.left .tab-content .user-list {
    height: 120px;
  }
  .assignments .assignments-item .date {
    padding: 0px;
  }
}

@media only screen and (min-width: 1536px) {
  .pieChart {
    height: 338px;
  }
  .progress {
    width: 100px;
  }
  .learner-dash .outer-box.chat .inner-box.left .tab-content .user-list {
    height: 238px;
  }
  .learn .outer-box.chat .inner-box.left .tab-content .user-list {
    height: 340px;
  }
  .tracking .outer-box.chat .inner-box.left .tab-content .user-list {
    height: 120px;
  }
}

@media only screen and (min-width: 1920px) {
  .pieChart {
    height: 420px;
  }
  .donut-container {
    height: 250px;
  }
  .learner-dash .outer-box.chat .inner-box.left .tab-content .user-list {
    height: 438px;
  }
  .outer-box.learn.achieve {
    height: 725px;
  }
  .course-lists.course {
    grid-template-columns: repeat(3, 1fr);
  }
  .progress {
    width: 150px;
  }
}

@media only screen and (max-width: 1290px) {
  .certificate-grid .certificate .cer-img {
    width: 125px;
  }

  .pieChart {
    height: 283px !important;
  }

  .course-lists .course-item .course-img {
    width: 75px;
    /* height: 75px; */
    height: 100%;
  }

  .course-lists .item.course-item .course-img {
    width: 100px;
  }

  .course-lists .course-item .course-img img {
    width: 75px;
  }

  .course-lists .course-item {
    gap: 10px;
  }
  .track .course-track {
    width: 225px;
  }
}

@media only screen and (max-width: 1230px) {
  .certificate-grid .certificate .cer-img {
    width: 125px;
  }

  .course-lists .course-item .course-img {
    width: 100px;
    /* height: 60px; */
    height: 100%;
  }

  .course-lists .course-item .course-img img {
    width: 100px;
  }

  .course-lists .course-item {
    gap: 10px;
  }

  .track .course-track {
    width: 225px;
  }
}

@media only screen and (max-width: 1200px) {
  /* .outer-box .row > div {
    margin-bottom: 16px;
  } */
  .donutDetails {
    gap: 25px;
    justify-content: flex-start;
  }
  .donut-data {
    width: max-content;
  }
}

@media only screen and (max-width: 1180px) {
  .certificate-grid.dash {
    grid-template-columns: repeat(1, 1fr);
  }

  .course-lists .course-item .course-img {
    /* height: 75px; */
    height: 100%;
    width: 65px;
  }

  .course-lists .course-item .course-img img {
    width: 65px;
  }

  .course-lists .course-item .course-act button {
    width: 60px;
  }

  .course-lists .course-item {
    gap: 10px;
  }

  .course-lists .course-item .course-info {
    flex-direction: column;
  }

  .course-lists .course-item .course-info span.catname {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .inner-box.active-courses .course-lists .course-item {
    height: max-content !important;
    flex-direction: column !important;
  }
  .inner-box.active-courses .course-lists .course-item .course-info {
    flex-direction: column !important;
  }

  .inner-box.active-courses  .course-lists .course-item .course-act {
    justify-content: flex-start;
  }

  .inner-box.active-courses  .course-lists .course-item .course-act button {
    width: 100px;
  }
  .course-lists .course-item .course-img {
    width: 200px;
    height: 200px;
  }
  .course-lists .course-item .course-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .outer-box .inner-box.active-courses .course-lists.course {
    height: 350px !important;
  }

  .inner-box.active-courses .certificate-grid {
    height: 350px !important;
  }

  .certificate-grid {
    height: 350px !important;
  }

  .outer-box.learn.achieve {
    height: 435px !important;
  }
}



@media only screen and (max-width: 992px) {
  .certificate-grid.dash {
    grid-template-columns: repeat(2, 1fr);
  }

  .learner-dash .flex-cal {
    height: 300px;
    overflow: auto;
    justify-content: flex-start;
    align-items: center;
  }

  .calendar {
    width: 250px;
  }

  .flex-right {
    display: flex;
    gap: 20px;
  }

  .learner-dash .outer-box.chat .inner-box.left .tab-content .user-list {
    height: 195px;
  }

  .course-lists.pathway .course-item .course-img {
    width: 100%;
    height: 75px;
  }

  .course-lists.pathway .course-item {
    width: 100% !important;
  }

  .course-lists.pathway .course-item .course-img img {
    width: 100%;
    height: 100%;
  }

  .course-lists .course-item .course-info span.catname {
    display: none;
  }

  .course-lists .course-item .course-act button {
    width: 100px;
  }

  .course-lists .course-item .course-img img {
    width: 80px;
  }

  .course-lists .course-item .course-img {
    width: 80px;
  }

  .course-lists .course-item {
    gap: 10px;
  }

  .course-lists .course-item .course-info {
    flex-direction: column;
  }
  .outer-box.recomd-courses {
    margin-bottom: 16px !important;
  }
}

@media only screen and (max-width: 768px) {
  .certificate-grid.dash,
  .course-lists.course {
    grid-template-columns: repeat(2, 1fr);
  }

  .flex-right {
    flex-direction: column;
  }

  .course-lists .course-item {
    width: 300px;
  }
  .outer-box.recomd-courses {
    margin-bottom: 16px !important;
  }
}

@media only screen and (max-width: 600px) {
  .certificate-grid.dash,
  .course-lists.course {
    grid-template-columns: repeat(1, 1fr);
  }

  .flex-right {
    flex-direction: column;
  }

  .course-lists .course-item {
    width: 300px;
    height: max-content !important;
  }
  .boxes1 {
    flex-direction: column;
  }
  .learner-dash .inner-box .course-lists.course {
    grid-template-columns: repeat(1, 1fr);
  }

  .course-lists .course-item .course-info {
    flex-direction: column !important;
  }
  .course-lists .course-item .course-img,
  .course-lists .course-item .course-img img {
    width: 100%;
    height: 100%;
  }
  .outer-box.recomd-courses {
    margin-bottom: 16px !important;
  }
}

@media only screen and (max-width: 418px) {
  .donut-container {
    flex-direction: column;
  }
  .pieChart {
    height: max-content !important;
  }
  .main-donut-container {
    gap: 15px;
  }
  .boxes1 {
    flex-direction: column;
  }
}

.owl-theme .owl-nav,
.owl-theme .owl-dots {
  display: none !important;
}

@media only screen and (max-width: 1290px) {
  .progress {
    width: 100px;
  }
  .learner-dash .outer-box.chat .inner-box.left .tab-content .user-list {
    height: 210px;
  }
  .learn .outer-box.chat .inner-box.left .tab-content .user-list {
    height: 250px;
  }
  .tracking .outer-box.chat .inner-box.left .tab-content .user-list {
    height: 345px;
  }
}

@media only screen and (max-width: 1024px) {
  .certificate-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .progress {
    width: 100px;
  }
  .learner-dash .outer-box.chat .inner-box.left .tab-content .user-list {
    height: 625px;
  }
  .learn .outer-box.chat .inner-box.left .tab-content .user-list {
    height: 415px;
    height: 250px;
  }
  .tracking .outer-box.chat .inner-box.left .tab-content .user-list {
    height: 345px;
  }
  .event-calendar .days span {
    padding: 30px 15px;
  }
  .outer-box.fullCal {
    margin-top: 15px;
  }
  .course-lists.course {
    grid-template-columns: repeat(1, 1fr);
  }
  .track .inner-box.course .course-lists.course {
    flex-direction: row;
  }
  .outer-box.event-calendar {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
  }
  .react-calendar {
    width: 350px !important;
  }
}

@media screen and (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(
      3,
      1fr
    ); /* 3 columns for screens 768px and below */
  }
}

@media screen and (max-width: 600px) {
  .searchBar {
    width: 100% !important;
  }
  .grid-container {
    height: 550px !important;
  }
  .list-item {
    width: 100%;
  }
  .list-course-details2 {
    width: 150px !important;
    gap: 5px;
  }
  .list-course-name {
    white-space: nowrap;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .list-course-details {
    flex-direction: column !important; 
    gap: 10px;
  }
  .list-course-more {
    padding-right: 0px !important;
    align-items: flex-start !important; 
  }
  .list-course-img {
    width: 30% !important;
  }
  .list-container {
    padding-right: 10px;
  }

  .cart-menu {
    width: 95% !important;
    right: 10px;
  }
  .list-item-cart {
    height: 100% !important;
    justify-content: space-between;
    flex-direction: column !important;
    position: relative;
  }
  .cart-img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
  .delete-cart-icon {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  .add-user-form .row>div:first-child, .add-user-form .row>div:last-child {
    padding: 0 !important;
  }
  .profile-img-container, .searchbar-view-container {
    flex-direction: column !important;
  }
  .searchbar-view-container {
    gap: 15px;
    margin-bottom: 0px !important;
  }
  .learn .inner-box.left .tab-content, .track .inner-box.left .tab-content {
  height: max-content !important;
  }
}



/* Media query for even smaller screens */
@media screen and (max-width: 480px) {
  .grid-container {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* 2 columns for screens 480px and below */
  }
}

/* Media query for very small screens */
@media screen and (max-width: 320px) {
  .grid-container {
    grid-template-columns: 1fr; /* 1 column for screens 320px and below */
  }
}