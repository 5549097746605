.theme .box-outer {
    padding: 35px;
}
.theme .heading h2 {
    font: normal normal 400 24px/30px 'source-sans-pro', sans-serif;
    letter-spacing: 0px;
    color: var(--whiteColor);
    margin-bottom: 50px;
}
.changemode-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 55px;
}
.changemode label {
    font: normal normal 400 24px/30px 'source-sans-pro', sans-serif;
    letter-spacing: 0px;
    color: var(--whiteColor);
}
.changemode p {
    font: normal normal normal 18px/24px 'source-sans-pro', sans-serif;
    letter-spacing: 0px;
    color: #AAA4A4;
}
.theme .form-switch .form-check-input {
    background: #3E3E3E 0% 0% no-repeat padding-box !important;
    background-color: #3E3E3E !important;
    border: none !important;
}
.c-switch .slider {
    background-color: #3E3E3E;
}
.c-switch .switch {
    width: 64px;
    height: 34px;
}
.c-switch .slider:before {
    width: 27px;
    height: 27px;
    left: 4px;
    bottom: 4px;
}
.c-switch input:checked + .slider:before {
    transform: translateX(30px);
}
.theme-list-flex {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(5, 1fr);
    gap: 50px;
    margin-bottom: 25px;
}
.theme-list-flex .theme-item {
    width: 100%;
    height: 175px;
    background: var(--body-dark-bg) 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 23px;
    position: relative;
    overflow: hidden;
}
.theme-list-flex .theme-item.darkone {
    border: 3px solid #24DFFE;
}
.theme-list-flex .theme-item.lightone {
    border: 3px solid #58A6FF;
}
.theme-list-flex .theme-item.darktwo {
    border: 3px solid #87fe61;
}
.theme-list-flex .theme-item.lighttwo {
    border: 3px solid #8BC34A;
}
.theme-list-flex .theme-item.darkthree {
    border: 3px solid #9D65F2;
}
.theme-list-flex .theme-item.lightthree {
    border: 3px solid #9D65F2;
}
.theme-list-flex .theme-item.darkfour {
    border: 3px solid #0dafd2;
}
.theme-list-flex .theme-item.lightfour {
    border: 3px solid #6767FF;
}
.theme-list-flex .theme-item.darkfive {
    border: 3px solid #ff4cca;
}
.theme-list-flex .theme-item.lightfive {
    border: 3px solid #F6428B;
}

.theme-list-flex .theme-item .theme-name {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: var(--body-dark-bg) 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 20px 20px;
}
.theme-list-flex .theme-item .theme-name span {
    font: normal normal 400 15px/20px 'source-sans-pro', sans-serif;
    letter-spacing: 0px;
    color: var(--whiteColor);
}
.theme-list-flex .theme-item .theme-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
}
.theme-list-flex .theme-item img {
    width: 275px;
    position: absolute;
    top: 20px;
    left: 20px;
}
.theme-list-flex .theme-item svg.theme-svg {
    /* width: 200px; */
    /* width="288.767" height="170.333" */
    position: absolute;
    top: 20px;
    left: 25px;
}
.changemode-flex .input-color {
    /* background: var(--body-dark-bg) 0% 0% no-repeat padding-box; */
    background-color: transparent !important;
    border: 1px solid #707070;
    border-radius: 10px;
    width: 100px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.changemode-flex .input-color input {
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    font: normal normal 400 21px/28px 'source-sans-pro', sans-serif;
    letter-spacing: 0px;
    color: var(--whiteColor) !important;
    width: 75px;
}

@media only screen and (max-width: 1200px) {
    .theme-list-flex {
        gap: 10px;
    }
}
@media only screen and (max-width: 1125px) {
    .theme-list-flex {
        grid-template-columns: repeat(2, 1fr);
        gap: 25px;
    }
    .theme-list-flex .theme-item {
        width: 100%;
        height: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .theme-list-flex .theme-item img {
        width: 400px;
        left: 45px;
    }
    .theme-list-flex .theme-item svg.theme-svg {
        width: 400px;
        left: 45px;
    }
    .theme-list-flex .theme-item .theme-name {
        height: 50px;
    }
}
@media only screen and (max-width: 992px) {
    .theme-list-flex {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media only screen and (max-width: 768px) {
    .theme-list-flex {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media only screen and (max-width: 600px) {
    .theme-list-flex {
        grid-template-columns: repeat(1, 1fr);
    }
    .changemode-flex {
        flex-direction: column;
        align-items: flex-start;
    }
    .act-btn {
        justify-content: flex-start !important;
    }
}

