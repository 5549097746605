@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap);
@import url(https://use.typekit.net/gen0ssm.css);

.navigation ul li.active a::after,
.navigation ul li.active a::before {
  content: '';
  width: 65px;
  background: 0 0;
  border-radius: 50%;
  z-index: -1;
  pointer-events: none;
  right: 0
}

.heading .action .filter i,
.navigation ul li a,
.navigation ul li a .title,
.outer-box .links a:hover {
  color: var(--primary-color)
}

@font-face {
  font-family: Avenir;
  src: url("../src/assets/fonts/AVENIRLTSTD-ROMAN.OTF")
}

:root {
  --primary-color: #22E1FE;
  --secondary-color: #C9FCFC;
  --whiteColor: #f5f5f5;
  --blackColor: #222222;
  --greyColor: #A7A7A7;
  --body-dark-bg: #010001;
  --dark-bg: #28282B;
  --table-row-even: #5c5c5e;
  --chips-color: #3A3A3A;
  --event-thtd: #1E1D1D;
  --icon-color: #f5f5f5;
  --font-regular: 400;
  --font-medium: 500;
  --font-bold: 700;
  --nav-active: #22E1FE;
  --nav-inactive: #f5f5f5;
  --off-white: #101010;
  --table-row: #3C3C3E;
  --bs-border-radius: 16px !important;
  --bs-progress-bar-bg: var(var(--primary-color)) !important
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Avenir, sans-serif !important
}

html {
  scroll-behavior: smooth
}

body {
  width: 100%;
  height: 100vh;
  background-color: var(--off-white) !important;
  overflow-x: hidden
}


.page-item .page-link span {
  color: var(--primary-color) !important;
}


input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0
}

.pagination {
  margin-top: 25px
}

button.cust-btn:hover,
button.newCourse a,
span {
  color: var(--whiteColor)
}

input[type=number] {
  -webkit-moz-appearance: textfield
}

.g-10 {
  gap: 10px !important
}

input:-internal-autofill-selected {
  background-color: #010001 !important
}

a,
a:hover {
  color: inherit !important
}

input[type=date]::-webkit-calendar-picker-indicator,
input[type=date]::-webkit-inner-spin-button {
  opacity: 0;
  -webkit-appearance: none
}

input:focus-visible {
  outline: 0 !important
}

.form-control::placeholder {
  color: var(--whiteColor) !important
}

.form-flex .text-input .input-box input::placeholder {
  color: var(--whiteColor) !important
}

.course-materials .list,
.flex-column,
.main-content .topics-flex .topics .topic-count,
.time,
.user__data {
  display: flex;
  flex-direction: column
}

.assessment .action,
.card-body .course-draft-flex,
.flex-row,
.item-flex,
.org-flex .org-tree .sub-org .list {
  display: flex;
  align-items: center;
  justify-content: space-between
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px
}

.flex-row-reverse {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between
}

.container-fluid .row>div {
  padding: 0 8px
}

.container-fluid,
.row {
  min-height: inherit
}

::-webkit-scrollbar {
  border-radius: 20px;
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 20px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
  border-radius: 20px !important
}

.navigation::-webkit-scrollbar {
  display: none;
}



.item-flex .icon .content .type span,
a {
  text-decoration: none !important
}

.wrapper {
  width: 100%;
  height: 100%
}

.alert {
  width: 50%;
  padding: .5rem !important
}

.custom__container,
.search label {
  position: relative;
  width: 100%
}

.text-group text {
  fill: var(--whiteColor) !important
}

.form-check-input.suspend:checked {
  background-color: #2ecc72 !important;
  border-color: #2ecc72 !important
}

.form-check-input:checked {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important
}

/* .modal-body {
  padding: 0px !important;
} */

.modal-body .input-box .form-control,
.modal-body .input-box .form-select,
.react-select__control.css-wfk0y2-control {
  background-color: var(--body-dark-bg) !important
}

.form-switch .form-check-input {
  width: 40px !important;
  height: 20px !important
}

.navigation {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 100%;
  background-color: var(--dark-bg);
  border-radius: 0 50px 50px 0;
  z-index: 1000;
  overflow: scroll
}

.react-select__control.css-wfk0y2-control {
  transition: none !important;
  height: 45px !important
}

.navigation.open,
.select__role.active .custom_box,
.select_courses.active .custom_box {
  display: block
}

.navigation.close {
  background-color: transparent;
  display: none
}


.navigation ul {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  padding-left: 0
}

.navigation.active ul {
  background-color: transparent !important
}

.navigation ul li {
  position: relative;
  width: 100%;
  list-style: none;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  margin: 30px 0
}

.navigation ul li.logo {
  margin: 10px 0
}

.navigation ul li a {
  position: relative;
  display: block;
  width: 100%;
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
  padding: 12px 8px
}

.navigation ul li a .icon {
  position: relative;
  display: block;
  min-width: 60px;
  text-align: center;
  margin-left: -5px;
  transform: none !important
}

.navigation ul li:first-child a .icon {
  height: 46px;
  line-height: 46px
}

.icon__toggle svg,
.icon__toggle2 svg,
.navigation ul li a .icon.icon_nav svg {
  fill: var(--nav-inactive)
}

.navigation ul li.active a .icon.icon_nav svg {
  fill: var(--nav-active)
}

.navigation ul li a .title {
  position: relative;
  display: none;
  height: 65px;
  text-align: start;
  white-space: nowrap
}

.navigation ul li.dashboard a .title {
  height: 65px;
  width: 65px;
  margin-top: 5px;
  margin-left: 5px
}

.navigation ul li.mng_course.active a .title,
.navigation ul li.mng_people.active a .title {
  width: 82px;
  height: 82px
}

.navigation ul li.my_course.active a .title {
  width: 60px;
  height: 60px
}

.navigation ul li.learn_path.active a .title {
  width: 72px;
  height: 72px
}

.navigation ul li.get_repo.active a .title {
  width: 62px;
  height: 62px
}

.navigation ul li.logout.active a .title {
  width: 35px;
  height: 35px
}

.navigation ul li.active .title {
  display: block;
  transform: rotate(-90deg);
  margin: 0
}

.navigation ul li.active {
  background-color: var(--off-white);
  height: 65px
}

.navigation ul li.active a {
  z-index: 1;
  margin-left: 0
}

.navigation ul li.dashboard.active a {
  padding: 18px 8px;
  display: flex;
  align-items: center;
  height: 65px
}

.navigation ul li.mng_course.active a {
  padding: 16px 11px
}

.navigation ul li.my_course.active a {
  padding: 28px 11px
}

.navigation ul li.learn_path.active a {
  padding: 21px 11px
}

.navigation ul li.mng_people.active a {
  padding: 15px 11px
}

.navigation ul li.get_repo.active a {
  padding: 24px 11px
}

.navigation ul li.logout.active a {
  padding: 40px 11px
}

.navigation ul li.active a::before {
  position: absolute;
  top: -65px;
  height: 65px;
  box-shadow: 36px 36px 0 0 var(--off-white)
}

.navigation ul li.active a::after {
  position: absolute;
  top: 65px;
  height: 65px;
  box-shadow: 36px -36px 0 0 var(--off-white)
}

.close__menu,
.icon__toggle,
.icon__toggle2,
.notify_icon,
.select_batch,
.select_course,
.select_role,
.settings__icon,
.user_icon {
  cursor: pointer
}

.navigation.open.active {
  opacity: 0;
  transition: .5s
}

.navigation.close.active {
  display: block;
  opacity: 1
}

.main {
  position: absolute;
  width: calc(100% - 60px);
  left: 60px;
  background: 0 0/cover no-repeat;
  transition: .5s
}

.topbar .flex {
  display: flex;
  align-items: center;
  gap: 25px
}

.main_sec_flex,
.outer-box .config-list {
  display: flex;
  align-items: flex-start;
  justify-content: space-between
}

.right {
  width: 264px
}

.select__role .box,
.select_courses .box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--secondary-color)
}

.main__section {
  padding: 20px;
  width: 100%
}

.main__section .row {
  margin-bottom: 50px
}

.heading.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 26px
}

.heading .action {
  display: flex;
  align-items: center;
  margin-top: 0;
  gap: 10px
}

.assessment .action button,
.heading .action button {
  background-color: var(--secondary-color);
  color: var(--whiteColor);
  box-shadow: none !important
}

.heading h1 {
  font-size: 20px;
  font-weight: 500;
  color: var(--whiteColor);
  letter-spacing: .2px
}

.card-body h1,
.card-body h5 {
  color: var(--whiteColor) !important
}

.accordion-body .input-box .form-select,
.modal-body .accordion-body .input-box .form-control {
  background-color: var(--dark-bg) !important
}

.modal-body .input-box .form-control.form-control-color {
  width: 50px !important
}

.nav-tabs {
  border: none !important;
  margin-bottom: 23px !important;
  transition: none
}

.card {
  border-radius: 25px !important;
  width: 180px !important;
  border: none !important;
  background-color: var(--chips-color) !important
}

.card-body {
  background-color: transparent !important;
  display: flex;
  flex-direction: column;
  gap: 5px
}

.card-body h5 {
  font: 400 16px/20px Avenir, sans-serif !important
}

.card-body h1 {
  font: 400 20px/25px Avenir, sans-serif !important;
  text-transform: capitalize !important
}

.card-body a,
.card-text {
  font-size: 10px !important
}

.card .card-title {
  font-size: 12.5px !important;
  line-height: 12px;
  font-weight: 500;
  color: #121212;
  margin-top: -2px;
  margin-bottom: 0
}

.card-text {
  color: #787878;
  font-weight: 400;
  letter-spacing: .1px;
  margin-bottom: 0
}

.card-body .course-draft-flex .course-info,
.course-catalog .filter .filter-list .accordion,
.item-list {
  display: flex;
  flex-direction: column;
  gap: 10px
}

.card-body .course-draft-flex .action {
  display: flex;
  gap: 10px
}

.card-body .course-draft-flex .action .btn {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center
}

.card .status,
.month {
  align-items: center;
  display: flex
}

.card-img-top {
  position: relative
}

.card .status {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 47px;
  height: 20px;
  border-radius: 32px 0 0;
  justify-content: center
}

.days span,
.event__circle,
.event__circle:first-child,
.events__list,
.my__courses {
  position: relative
}

.card .status.published {
  background-color: var(--secondary-color)
}

.card .status span {
  font-size: 8px;
  font-weight: 400;
  color: var(--whiteColor)
}

.calendar {
  padding: 20px 16px 8px;
  background-color: var(--body-dark-bg);
  border: 1px solid hsla(var(--hue-color), 6%, 50%, .16);
  border-radius: 25px;
  margin-bottom: 30px;
  height: max-content !important
}

.days span.today::after,
.days span:not(.next-date, .prev-date)::before {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 26px;
  height: 26px;
  border-radius: 100%;
  content: '';
  background-color: var(--secondary-color);
  transition: .5s;
  left: 50%
}

.month {
  justify-content: space-between;
  height: 13px;
  margin-bottom: 21px
}

.month .uil {
  font-size: 24px;
  color: #808495
}

.weekdays {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(148, 163, 175, .3)
}

.days {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: .2rem
}

.date h2 {
  font-size: 13px;
  font-weight: 700;
  color: var(--secondary-color);
  margin-bottom: 0
}

.days span {
  width: 14.25%;
  padding: 9.25px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
  color: #43425d;
  font-size: 10px
}

.days span.today:not(.next-date, .prev-date),
.days span:not(.next-date, .prev-date):hover,
.weekdays span {
  color: var(--secondary-color);
  font-weight: 700
}

.weekdays span {
  width: 14.3%;
  text-align: center;
  opacity: .9;
  font-size: 11px
}

.next-date,
.prev-date {
  opacity: .5;
  cursor: none
}

.days span:not(.next-date, .prev-date)::before {
  z-index: -1;
  opacity: 0
}

.days span.today::after {
  opacity: .2;
  z-index: -2
}

.days span:not(.next-date, .prev-date):hover:before {
  opacity: .2
}

.owl-theme .owl-nav.disabled+.owl-dots {
  display: none
}

.my__progress {
  width: 315px;
  padding: 21px 18px 24px 17px
}

.my__progress .flex,
.newcourseform form .flex {
  justify-content: flex-start
}

.courses {
  width: 565px;
  padding: 21px 20px 35px 21px
}

.courses,
.my__progress {
  background-color: rgba(255, 255, 255, .3);
  border-radius: 25px
}

.courses h2.heading,
.my__progress h2.heading {
  font-size: 18px;
  font-weight: 400;
  color: #222;
  letter-spacing: .81px;
  margin-top: -4px
}

.progress_flex .courses h2.heading {
  margin-bottom: 12px
}

.my__progress-chart {
  display: flex;
  align-items: center;
  gap: 23px
}

.chart__data {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 10px
}

.chart__point {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px
}

.chart__color {
  width: 10px;
  height: 10px;
  border-radius: 2px
}

.chart__color.one {
  background-color: #0058ff
}

.chart__color.two {
  background-color: #21d59b
}

.chart__color.three {
  background-color: #ffc700
}

.chart__color.four {
  background-color: #f99600
}

.chart__text {
  font-size: 10px;
  font-weight: 400;
  color: #131523;
  width: 100px
}

.chart__data {
  font-size: 12px;
  font-weight: 700;
  color: #131523
}

.all_courses .card {
  width: 215px !important;
  height: 97px !important;
  border-radius: 0 !important
}

.all_courses .card-body {
  background-color: rgba(252, 252, 252, .6) !important;
  border-radius: 0 !important;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  gap: 9px
}

.all_courses .card .flex_box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%
}

.all_courses .card .card-body .card-title {
  font-size: 12px !important;
  line-height: 14px !important;
  font-weight: 700 !important;
  letter-spacing: .54px !important;
  color: #121212 !important;
  margin-bottom: 0
}

.all_courses .card .card-body .card-text {
  font-size: 10px !important;
  color: #121212 !important;
  opacity: .4 !important;
  font-weight: 400 !important;
  margin-right: auto;
  margin-bottom: 0 !important;
  margin-top: -4px !important
}

.all_courses .card .flex_box .right {
  width: 125px !important;
  height: 97px !important;
  border-radius: 0 4px 4px 0;
  outline-offset: -1px;
  background-color: var(--whiteColor)
}

.all_courses .card .flex_box .right .card-body {
  padding: 11px 17px 9px 15px
}

.all_courses .card .flex_box .left {
  background-color: transparent;
  width: 90px !important
}

.all_courses .card .flex_box .left img {
  width: 90px !important;
  height: 97px !important
}

.all_courses .card .card-body .custom_btn {
  width: 63px;
  height: 21px;
  border: .5px solid var(--secondary-color);
  font-size: 9px;
  font-weight: 300;
  background-color: transparent;
  color: var(--secondary-color);
  transition: .5s;
  border-radius: 2px
}

.all_courses .card .card-body .custom_btn:hover,
.course-catalog .filter .filter-list .accordion-item .accordion-header .accordion-button:not(.collapsed),
.course-play .action .btn,
.modal-footer .btn.fill,
button.fill {
  background-color: var(--secondary-color);
  color: var(--whiteColor)
}

.upcomming__events {
  background-color: rgba(255, 255, 255, .36);
  border-radius: 25px;
  border: 1px solid hsla(var(--hue-color), 6%, 50%, .12);
  padding: 18px 18px 0 14px;
  margin-bottom: 28px;
  height: 283px !important
}

.custom_theme input:checked+.slider,
.event__circle {
  background-color: #fff
}

.upcomming__events h2.heading {
  font-size: 16px;
  font-weight: 400;
  color: #2a285a;
  letter-spacing: .9px;
  margin-bottom: 0;
  margin-top: -4px
}

.events {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
  gap: 30px
}

.event__circle {
  width: 14px;
  height: 14px;
  line-height: 14px;
  border-radius: 100%;
  border: 2px solid var(--secondary-color);
  text-align: center;
  margin-top: 0
}

.events__list::after {
  content: '';
  position: absolute;
  display: block;
  top: 8px;
  left: 6px;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-left: 1px solid var(--secondary-color);
  opacity: .6;
  z-index: -1;
  overflow: hidden
}

.events:first-child .event__circle:before,
.events:last-child .event__circle:after {
  display: none
}

.event__data {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 225px
}

.announcement_data .title,
.event__data .title {
  font-size: 11px;
  font-weight: 500;
  color: #45484b
}

.descrip {
  font-size: 10px;
  font-weight: 400;
  color: #95989a;
  letter-spacing: .2px
}

.my__courses {
  margin-bottom: 50px;
  width: 960px !important;
  height: 300px !important;
  margin-left: -22px
}

.my__courses .custom_table {
  width: 928px;
  height: 314px;
  background-color: rgba(255, 255, 255, .36) !important;
  box-shadow: 0 3px 16px #0a4B6D14;
  border-radius: 25px;
  margin-left: 22px;
  margin-right: 22px
}

.table__header {
  width: 100%;
  height: 55px
}

.table {
  background-color: transparent !important;
  --bs-table-bg: transparent !important;
  margin-bottom: 0 !important;
  width: 100%;
  z-index: 1
}

.table__body {
  width: 100%;
  height: 245px
}

.my__courses .table__header {
  position: sticky;
  top: 0
}


.table tbody tr td,
.table thead tr th {
  font-size: 14px;
  font-weight: 400;
  color: var(--secondary-color);
  vertical-align: middle
}

.table tbody tr td:first-child,
.table tbody tr td:last-child,
.table thead tr th:first-child,
.table thead tr th:last-child {
  padding-left: 50px
}

.user-table .table tbody tr td:last-child,
.user-table .table thead tr th:last-child {
  padding-left: 8px
}

.user-table .flex {
  gap: 15px
}

.modal-header,
.table tbody tr:last-child td,
.users-list .user-table .table tbody tr:last-child td {
  border-bottom: none !important
}

.table thead tr th .table_head {
  display: flex;
  align-items: center;
  gap: 20px
}

.table thead tr th .icon svg,
.topbar__icon.active svg {
  fill: var(--primary-color)
}

.plus__icon svg,
.topbar__icon svg {
  fill: var(--secondary-color)
}

.table>:not(:last-child)>:last-child>*,
.table>:not(caption)>*>* {
  border-bottom-color: hsla(var(--hue-color), 100%, 50%, .4) !important
}

.blue_btn,
.green_btn,
.orange_btn,
.red_btn {
  box-shadow: none !important;
  outline: 0 !important;
  width: 100px;
  height: 35px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 400;
  cursor: default !important
}

.green_btn {
  background-color: #aaffbe;
  border: 1px solid #aaffbe;
  color: #0d472d
}

.file-link span,
.launch_btn,
.month .uil-angle-left,
.month .uil-angle-right {
  color: var(--secondary-color)
}

.launch_btn {
  background-color: transparent;
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  width: 125px;
  height: 35px
}

.blue_btn {
  background-color: #6200ee;
  border: 1px solid #6200ee;
  color: var(--whiteColor)
}

.orange_btn {
  background-color: #ffefca;
  border: 1px solid #ffefca;
  color: #433108
}

.red_btn {
  background-color: #ffd9d1;
  border: 1px solid #ffd9d1;
  color: #360e07
}

.my__courses-heading h2.heading {
  color: #222;
  font-size: 18px;
  letter-spacing: .81px;
  font-weight: 400;
  padding-left: 17px;
  padding-bottom: 12px
}

.announcement {
  background-color: rgba(255, 255, 255, .36);
  border: 1px solid hsla(var(--hue-color), 6%, 50%, .12);
  border-radius: 25px;
  padding: 20px 10px 0 15px;
  height: 313px !important
}

.announcement__list {
  overflow: scroll;
  width: 100% !important;
  margin-left: 17px;
  height: 250px !important
}

.events__list .events_list_inside {
  overflow: scroll;
  height: 215px;
  margin-top: -1px
}
.announcement h2.heading {
  font-size: 16px;
  font-weight: 400;
  color: #2a285a;
  letter-spacing: .9px;
  margin-bottom: 0;
  margin-top: 0
}

.announcement .add_new-announcement {
  width: 22px;
  height: 22px;
  position: relative;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center
}

.announcement .add_new-announcement::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--secondary-color);
  opacity: .8;
  width: 100%;
  height: 100%;
  border-radius: 100%
}

.announcement .add_new-announcement div {
  z-index: 1;
  color: var(--whiteColor);
  text-align: center
}

.announcement .flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 41px;
  margin-bottom: 20px
}

.announcemnet .icon {
  width: 34px;
  height: 34px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  position: relative
}

.announcemnet .icon svg {
  z-index: 1
}

.announcement .icon::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--secondary-color);
  opacity: .8;
  width: 34px;
  height: 34px;
  border-radius: 100%
}

.custom_progress,
.search,
.theme_settings img {
  position: relative
}

.announcemnet {
  display: flex;
  align-items: center;
  gap: 28px !important;
  margin-bottom: 14px
}

.announcement_data {
  display: flex;
  align-items: flex-start;
  flex-direction: column
}

span label.violet_label {
  background-color: #ae00ff
}

span label.pink_label {
  background-color: #f08
}

span label.blue_label {
  background-color: #0032ff
}

span label.blue2_label {
  background-color: #009dff
}

span label.sky_blue_label {
  background-color: #00d8ff
}

span label.green_label {
  background-color: #0f0
}

span label.green2_label {
  background-color: #a1ff00
}

span label.yellow_label {
  background-color: #fffa00
}

span label.orrange_label {
  background-color: #ffd000
}

span label.orrange2_label {
  background-color: orange
}

span label.orrange3_label {
  background-color: #ff7b00
}

span label.red_label {
  background-color: #ff4e32
}

.learning__cat {
  margin-bottom: 42px;
  padding-right: 30px
}

.learning__cat .card {
  background-color: transparent;
  width: 192px !important
}

.learning__cat .card-body {
  background-color: rgba(255, 255, 255, .3) !important;
  height: 73px;
  padding: 13px !important
}

.upcomming__events .flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px
}

.responsive_table {
  overflow: none !important
}

.custom_progress {
  background: rgba(0, 0, 0, .1);
  justify-content: flex-start;
  border-radius: 100px;
  align-items: center;
  padding: 0 5px;
  display: flex;
  height: 10px;
  width: 150px
}

.progress-value {
  animation: 3s forwards load;
  box-shadow: 0 10px 40px -10px var(--secondary-color);
  border-radius: 100px;
  background: var(--secondary-color);
  height: 10px;
  width: 0
}

@keyframes load {
  0% {
    width: 0
  }

  100% {
    width: 68%
  }
}

.custom_box .flex_box {
  display: flex;
  flex-direction: column;
  padding-top: 10px
}

.course-catalog .nav-link.active:focus,
.course-catalog .nav-link.active:hover,
.learning__cat .nav-link.active:focus,
.learning__cat .nav-link.active:hover,
.nav-link:focus,
.nav-link:hover {
  color: var(--secondary-color) !important
}

.learning_cat .owl-stage,
.learning_cat2 .owl-stage,
.learning_cat3 .owl-stage {
  min-width: 10317px
}

.item-flex .icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px
}

.item-flex .icon .content .type {
  color: #a7a7a7;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase
}

.file-link span {
  font-size: 16px;
  font-weight: 400
}

.white-box .status .sts-box:hover,
button.cust-btn,
button.newCourse {
  background-color: var(--primary-color);
  color: var(--whiteColor)
}

.file-link span:hover {
  text-decoration: underline !important
}

.file-link h1 {
  font-size: 24px;
  font-weight: 500
}

.white-box {
  width: 100%;
  background-color: var(--whiteColor);
  border-radius: 8px;
  padding: 25px;
  margin-top: 30px
}

.white-box .action {
  margin-bottom: 40px
}

.error-req,
.error-req.course .select,
.left .questions,
.main-content,
.org-flex .org-tree .sub-org,
.outer-box .config-list .config-item,
.preview-course,
.white-box .status {
  width: 100%
}

.white-box .status .sts-box {
  width: 100%;
  height: 125px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(100, 100, 111, .2) 0 7px 29px 0
}

.modal-header .btn-close:focus,
input[type=color] {
  box-shadow: none !important
}

.customisation .flex .switch .slider,
.profile__menu ul li:hover,
.search label .search__icon {
  background-color: var(--secondary-color)
}

.white-box .status .sts-box p {
  text-align: center;
  font-size: 18px
}

.white-box .status .row {
  width: 100%;
  margin: 25 auto
}

.courses .flex {
  justify-content: space-between;
  margin-bottom: 20px
}

button.newCourse {
  width: 100px;
  height: 30px;
  font-size: 12px;
  border: none;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 15px
}

.dataTables_filter,
.dataTables_length,
.paginate_button.first,
.paginate_button.last,
.theme_settings img {
  display: none !important
}

button.cust-btn {
  width: 100px !important
}

.course-catalogue .card {
  width: 100% !important;
  margin-bottom: 25px;
  background-color: transparent !important
}

.dropdown-item,
.instructors .profile .profile-detsils span.name {
  font-size: 14px
}

.dropdown-item:hover {
  background-color: transparent !important;
  color: var(--primary-color) !important
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent !important;
  color: #121212 !important
}

button {
  width: 125px;
  height: 40px;
  outline: 0;
  border: none;
  border-radius: 0
}

button.outline {
  background-color: var(--whiteColor);
  color: var(--secondary-color)
}

.card-img,
.card-img-top {
  width: 100%;
  height: 200px
}

.card-img,
.card-img-top img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  border-radius: 30px 30px 0 0
}

.card-body {
  border-radius: 0 0 30px 30px
}

.user-table .table thead,
.users-list .user-table .table tbody tr,
.users-list .user-table .table thead tr,
table {
  border: none !important
}

.table.dataTable {
  width: 100%;
  border-radius: 25px;
  border: none !important;
  border-collapse: separate !important;
  border-spacing: 0px 10px !important
}

.dataTables_scrollHead,
.dataTables_scrollHeadInner,
.table.dataTable.no-footer {
  height: 40px !important
}

.dataTables_wrapper .dataTables_paginate {
  height: 25px !important
}

.dataTables_info,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.modal-body,
.modal-title,
.nav-link.active:focus,
.nav-link.active:hover {
  color: var(--whiteColor) !important
}

.modal-footer .btn.fill:hover,
.profile__menu ul li:hover a,
span label {
  color: var(--whiteColor)
}

.topbar {
  width: 100%;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding-left: 30px;
  padding-right: 35px
}

.progress_flex {
  display: flex;
  align-items: flex-start;
  gap: 50px;
  margin-bottom: 38px
}

.add__new-course .plus__icon {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 1px solid var(--secondary-color);
  display: flex;
  align-items: center;
  justify-content: center
}

.search {
  width: 260px
}

.search label input {
  width: 100%;
  height: 38px;
  border-radius: 38px 38px 0;
  padding: 5px 20px;
  outline: 0;
  border: none;
  font-size: 11px;
  font-weight: 400;
  color: #343434
}

.search label input::placeholder {
  color: rgba(52, 52, 52, .23);
  font-size: 11px;
  font-weight: 400
}

.search label .search__icon {
  position: absolute;
  right: 0;
  width: 38px;
  height: 38px;
  border-radius: 38px 38px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0
}

.right-flex,
.select_flex {
  display: flex;
  align-items: center;
  gap: 30px
}

.select__role .box,
.select_courses .box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 177px;
  border: 1px solid var(--secondary-color);
  border-radius: 38px;
  height: 38px;
  padding: 6px 12px 8px;
  background-color: rgba(255, 255, 255, .36)
}

.select__role .box span span.text,
.select_courses .box span span.text {
  color: var(--secondary-color);
  font-size: 14px;
  font-weight: 400;
  margin-left: 15px;
  margin-top: 1px;
  line-height: 16px
}

.error-req .action,
.select__role .box span.topbar__icon,
.select_courses .box span.topbar__icon {
  display: flex;
  align-items: center;
  justify-content: center
}

.select__role .topbar__icon svg {
  stroke: var(--secondary-color);
  fill: var(--secondary-color)
}

.topbar__icon svg {
  fill: var(--primary-color);
  cursor: pointer
}

.topbar__icon svg.arrow {
  stroke: var(--secondary-color);
  fill: none
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px
}

.points .flex span.text {
  font-size: 14px;
  font-weight: var(--font-regular);
  color: var(--secondary-color);
  margin-top: 1px
}

.customisation .flex .switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 12px
}

.create-course .switch input,
.custom_theme .switch input,
.customisation .flex .switch input,
.user-table .switch input {
  opacity: 0;
  width: 0;
  height: 0
}

.customisation .flex .switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: .4;
  border-radius: 5px
}

.customisation .flex .switch .slider .round {
  position: absolute;
  top: -6px;
  left: -9px;
  transition: .5s
}

.customisation .flex .switch input:checked+.slider {
  opacity: 1
}

.create-course .switch input:checked+.slider:before,
input:checked+.slider .round {
  transform: translateX(26px)
}

.user .flex {
  gap: 0
}

.user .flex .user__pic {
  width: 34px;
  height: 34spx
}

.user .flex .user__name {
  font-size: 10px;
  margin-left: 6px;
  font-weight: var(--font-regular);
  color: var(--secondary-color)
}

.user_icon {
  margin-left: 13px
}

.theme__colors {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column
}

.theme_settings {
  width: 30px;
  height: 30px
}

input[type=color] {
  width: 100%;
  height: 100%;
  border: 1px solid var(--primary-color) !important;
  border-radius: 5.5px;
  outline: 0 !important;
  appearance: none
}

.main-content .topics-flex .topics .topic-action .btn.save,
.pathway-info,
span input[type=radio] {
  display: none
}

span label {
  padding: 2px 6px;
  font-weight: 400;
  border-radius: 0;
  width: 20px;
  height: 20px;
  cursor: pointer
}

.box.custom_box {
  display: none;
  height: auto;
  position: absolute;
  border-radius: 0 0 18px 18px;
  border-top: none
}

.select__role.active .role_box,
.select_courses.active .box1 {
  border-bottom: none;
  border-radius: 18px 18px 0 0
}

.custom_box .flex_box {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 10px
}

.custom_box span.text a {
  color: var(--secondary-color);
  text-decoration: none
}

.profile__menu {
  display: none;
  top: 0;
  right: 0;
  background: var(--whiteColor);
  width: 300px;
  height: 100%;
  position: fixed;
  z-index: 2;
  box-shadow: rgba(149, 157, 165, .2) 0 8px 24px
}

.profile__info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 20px
}

.user__data .name {
  font-size: 14px;
  font-weight: 500;
  color: var(--primary-color)
}

.user__data .email {
  font-size: 12px;
  font-weight: 400;
  color: var(--greyColor)
}

.profile__menu ul {
  padding-top: 50px;
  padding-left: 0
}

.profile__menu ul li {
  list-style: none;
  padding-left: 20px
}

.profile__menu ul li a {
  height: 60px;
  line-height: 60px;
  text-decoration: none;
  color: var(--greyColor)
}

.pathway-info.active,
.profile__menu.active,
.select-area.active {
  display: block
}

.user__img img {
  width: 34px
}

.nav-pills .nav-link {
  font-size: 15px;
  font-weight: 700;
  height: 50px;
  line-height: 35px;
  background-color: var(--whiteColor);
  border: none;
  box-shadow: none;
  border-radius: 0 !important;
  box-shadow: rgba(149, 157, 165, .2) 0 8px 24px;
  margin-bottom: 10px;
  color: #212529
}

.nav-link:focus,
.nav-link:hover {
  color: #212529 !important
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: var(--whiteColor) !important;
  background-color: var(--secondary-color)
}

.course-overview {
  width: 100%;
  position: relative
}

.course-overview img {
  border-radius: 10px
}

.course-overview .progress {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 10px !important;
  height: 10px;
  box-shadow: rgba(99, 99, 99, .2) 0 2px 8px 0
}

.course-details .course-name {
  font: 400 24px/31px Source Sans Pro;
  letter-spacing: 0;
  color: var(--whiteColor)
}

.course-details .overview {
  font-size: 16px;
  font-weight: 500;
  color: #465b65;
  text-align: center;
  border-top: 1px solid var(--secondary-color);
  border-bottom: 1px solid var(--secondary-color);
  padding: 20px 0
}

.course-catalog .filter,
.course-tab {
  width: 100%;
  background-color: rgba(255, 255, 255, .36);
  border: 1px solid hsla(var(--hue-color), 6%, 50%, .16);
  padding: 25px;
  border-radius: 30px
}

.assessment,
.course-materials {
  width: 100%;
  background-color: var(--whiteColor);
  padding: 15px;
  border-radius: 10px
}

.course-tab .tab-pane {
  height: 550px;
  overflow-x: hidden;
  overflow-y: scroll
}

.overview-content .overview-item {
  margin: 20px 0
}

.overview-content .overview-item .overview-header {
  font-size: 20px;
  color: var(--secondary-color);
  margin-bottom: 5px
}

#playvideo .modal-body {
  padding: 0 !important;
  position: relative
}

#playvideo .modal-body a {
  position: absolute;
  top: 10px;
  right: 25px;
  z-index: 1001
}

#playvideo .modal-body a span {
  color: var(--whiteColor);
  font-size: 24px
}

.instructors {
  display: flex;
  flex-direction: column;
  gap: 15px
}

.instructors .profile {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px
}

.instructors .profile .profile-detsils {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start
}

.instructors .profile .profile-detsils a {
  font-size: 12px;
  color: var(--secondary-color)
}

.error-req .filter .select select,
.org-act .org-act-flex button,
.outer-box .heading h1,
.outer-box .links a,
.outer-box .sub-header span {
  letter-spacing: 0;
  color: var(--whiteColor)
}

.course-materials {
  box-shadow: rgba(100, 100, 111, .2) 0 7px 29px 0
}

.action-flex,
.course-materials .list .item,
.preview-course .course-order .topics-list .topics {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px
}

.course-materials .list .item .item-name {
  display: flex;
  align-items: center;
  gap: 50px
}

.course-materials .list .item .action a {
  color: var(--secondary-color);
  font-size: 14px
}

.accordion-item {
  margin-bottom: 10px
}

.assessment .assess-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px
}

.assessment .assess-flex .name span {
  font-size: 20px;
  font-weight: 500
}

.assessment .assess-flex .marks-duration {
  display: flex;
  align-items: center;
  gap: 15px
}

.add-que-flex {
  display: flex;
  align-items: flex-start
}

.left {
  width: 200px
}

.add-que-flex .right {
  padding: 10px;
  width: 400px !important
}

.left .questions .queList .queItem {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 10px
}

.left .questions>label {
  padding: 10px 20px
}

.modal-footer .btn.outline {
  background-color: #d3d3d3;
  color: #121212
}

.questions .queList button {
  width: 100% !important
}

.custom_theme .switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 18px
}

.custom_theme .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--dark-bg);
  box-shadow: rgba(149, 157, 165, .2) 0 8px 24px;
  -webkit-transition: .4s;
  transition: .4s
}

.custom_theme .slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 2px;
  background-color: var(--whiteColor);
  -webkit-transition: .4s;
  transition: .4s
}

.custom_theme input:focus+.slider {
  box-shadow: 0 0 1px var(--primary-color)
}

.custom_theme input:checked+.slider:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px)
}

.error-req .filter .select::before,
.upload-area img,
.upload-area input {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%)
}

.create-course .switch .slider.round,
.custom_theme .slider.round,
.user-table .switch .slider.round {
  border-radius: 34px
}

.create-course .switch .slider.round:before,
.custom_theme .slider.round:before,
.user-table .switch .slider.round:before {
  border-radius: 50%
}

.user {
  position: relative
}

.user .circle {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 2px solid var(--whiteColor);
  padding: 2px
}

.org-flex .org-tree .main-org .item img,
.org-flex .org-tree .sub-org .list .out-bord .item img,
.user .circle img.user-pic {
  width: 100%;
  height: 100%;
  object-fit: cover
}

.user .edit {
  position: absolute;
  right: 0;
  bottom: 0
}

.preview-course .course-order .topics-list .topics:first-child .topic-info .icon,
.user .edit img.edit-pic {
  width: 15px
}

.outer-box {
  width: 100%;
  height: 100%;
  background-color: var(--dark-bg);
  border-radius: 13px;
  padding: 16px
}

.outer-box.filter-courses,
.outer-box.quick-actions {
  height: 465px
}

.outer-box.one .inner-box-one {
  max-height: 100%;
  height: 235px;
  padding: 0 30px
}

.outer-box.two .inner-box {
  max-height: 100%;
  height: 285px
}

.outer-box.four,
.outer-box.three .inner-box {
  max-height: 100%;
  height: max-content
}

.outer-box.three .inner-box.estore {
  width: 100%;
  height: 450px;
  height: max-content
}

.outer-box .heading {
  margin-bottom: 22px
}

.outer-box .heading h1 {
  font: 14px/16px Avenir, sans-serif !important
}

.org-act .org-act-flex button,
.outer-box .links a,
.outer-box .sub-header span {
  font: 400 14px/18px Avenir, sans-serif !important
}

.outer-box.four .row,
.outer-box.two .row {
  height: 100%;
  margin-bottom: 0
}

.outer-box .row>div,
.outer-box.three .row {
  margin-bottom: 0
}

.outer-box .inner-box {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: var(--body-dark-bg);
  border-radius: 20px
}

.outer-box.three .heading h1 {
  text-align: left
}

.org-flex {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px
}

.org-flex .org-tree {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px
}

.org-flex .org-tree .main-org .out-bord {
  width: 75px;
  border: 1px solid #707070;
  padding: 5px;
  border-radius: 15px
}

.org-act .org-act-flex button,
.outer-box .sub-header {
  height: 50px;
  background-color: #000;
  border-radius: 18px
}

.org-flex .org-tree .main-org .item,
.org-flex .org-tree .sub-org .list .out-bord .item {
  width: 100%;
  height: auto;
  padding: 5px;
  border-radius: 7px;
  background-color: var(--whiteColor)
}

.org-flex .org-tree .sub-org .list .out-bord {
  width: 75px;
  padding: 5px;
  border-radius: 15px
}

.org-act .org-act-flex {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 28px
}

.error-req .filter,
.outer-box .sub-header {
  align-items: center;
  display: flex;
  margin-bottom: 15px
}

.org-act .org-act-flex button {
  width: 250px;
  max-width: 100%
}

.error-req .action button,
.error-req .filter .select,
.error-req table td button {
  background: var(--chips-color) 0 0 no-repeat padding-box
}

.outer-box .sub-header {
  max-width: 175px;
  justify-content: center
}

.outer-box .links {
  max-width: 175px;
  display: flex;
  flex-direction: column;
  gap: 15px
}

.outer-box .links a {
  text-align: center
}

.error-req .filter {
  justify-content: flex-end
}

.error-req .filter .select {
  width: 105px;
  height: 25px;
  border-radius: 6px;
  position: relative
}

.error-req .filter .select select {
  background-color: transparent;
  border: none;
  outline: 0;
  width: 100%;
  height: 25px;
  padding-left: 5px;
  padding-right: 5px;
  font: 400 14px/25px Avenir, sans-serif !important;
  appearance: none;
  background-color: var(--chips-color);
  border-radius: 6px
}

.error-req .filter .select::before {
  content: "";
  right: 0;
  width: 12px;
  height: 12px;
  background-image: url("./assets/images/sort.png");
  background-size: cover;
  background-repeat: no-repeat;
  pointer-events: none
}

.error-req .table-responsive .table-body {
  height: 255px;
  overflow-x: hidden;
  overflow-y: scroll
}

.table-responsive {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 20px
}

.error-req .table-responsive .table {
  border-collapse: separate;
  border-spacing: 0px 10px
}

.error-req .table-responsive .table thead tr {
  background-color: transparent;
  height: 0 !important
}

.error-req .table-responsive .table thead tr th {
  font: 400 14px/18px Avenir, sans-serif !important;
  letter-spacing: 0;
  color: var(--whiteColor);
  border-bottom: none;
  padding-left: 10px
}

.error-req .table-responsive .table tbody tr td,
.error-req table td button {
  font: 400 12px/16px Avenir, sans-serif !important;
  color: var(--whiteColor);
  letter-spacing: 0
}

.error-req .table-responsive .table tbody tr td:last-child,
.error-req .table-responsive .table thead tr th:last-child {
  width: 100px;
  text-align: center;
  padding-left: 0
}

.error-req .table-responsive .table tbody tr td {
  width: max-content;
  border-bottom: none;
  padding-left: 10px
}

.error-req .table-responsive .table tbody tr {
  background-color: #3c3c3e;
  height: 50px;
  border-bottom: none
}

.error-req .table-responsive .table tbody tr td:first-child {
  border-radius: 12px 0 0 12px
}

.error-req .table-responsive .table tbody tr td:last-child {
  border-radius: 0 12px 12px 0
}

.main-content .topics-flex .topics .topic-action,
.raisedBy {
  display: flex;
  align-items: center;
  gap: 10px
}

.error-req table td button {
  width: 90px;
  height: 35px;
  border-radius: 18px
}

.raisedBy .info {
  width: max-content;
  display: flex;
  flex-direction: column
}

.course-catalog .filter .action,
.upload {
  width: 100%;
  align-items: center;
  display: flex
}

.error-req .action button {
  width: 103px;
  height: 28px;
  border-radius: 6px;
  font: 12px/16px Avenir, sans-serif !important;
  letter-spacing: 0;
  color: var(--whiteColor);
  margin: 0 auto
}

.course-catalog .filter .action {
  gap: 20px;
  margin-bottom: 25px
}

.course-catalog .filter .action .filter-btn {
  width: 100px;
  height: 50px;
  color: #1c1d1f;
  background-color: transparent;
  border: 1px solid #1c1d1f
}

.course-catalog .filter .action .sort-filter {
  width: 200px;
  height: 50px;
  color: #1c1d1f;
  background-color: transparent;
  border: 1px solid #1c1d1f;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px
}

.course-catalog .filter .action .sort-filter label {
  font-size: 14px;
  font-weight: 700
}

.course-catalog .filter .action .sort-filter select {
  background-color: transparent;
  border: none;
  outline: 0;
  box-shadow: none
}

.course-catalog .filter .filter-list .accordion-item {
  background-color: var(--whiteColor);
  border: none;
  box-shadow: none;
  border-radius: 0 !important
}

.course-catalog .filter .filter-list .accordion-item .accordion-header {
  border: none;
  height: 50px;
  line-height: 50px
}

.course-catalog .filter .filter-list .accordion-item .accordion-header .accordion-button {
  font-size: 15px;
  font-weight: 700;
  height: 50px;
  line-height: 50px;
  background-color: var(--whiteColor);
  border: none;
  box-shadow: none;
  border-radius: 0 !important;
  box-shadow: rgba(149, 157, 165, .2) 0 8px 24px;
  margin-bottom: 10px
}

.course-catalog .filter .filter-list .accordion-item .accordion-header .accordion-button::after {
  background-image: url("./assets/images/down-arrow-black.png")
}

.course-catalog .filter .filter-list .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
  background-image: url("./assets/images/down-arrow.png")
}

.users-list .user-table {
  width: 100%;
  padding: 0;
  border-radius: 30px
}

.users-list .user-table .table thead tr th {
  border: none !important;
  border-bottom: 1px solid hsla(var(--hue-color), 6%, 50%, .16) !important;
  border-right: 1px solid hsla(var(--hue-color), 6%, 50%, .16) !important;
  font-weight: 700
}

.users-list .user-table .table tbody tr:last-child td:last-child,
.users-list .user-table .table thead tr th:last-child {
  border-right: none !important
}

.users-list .user-table .table tbody tr td {
  border: none !important;
  border-bottom: 1px solid hsla(var(--hue-color), 6%, 50%, .16) !important;
  border-right: 1px solid hsla(var(--hue-color), 6%, 50%, .16) !important
}

.users-list .user-table .table tbody,
.users-list .user-table .table td,
.users-list .user-table .table tfoot,
.users-list .user-table .table th,
.users-list .user-table .table thead,
.users-list .user-table .table tr {
  border-style: none !important
}

.upload {
  justify-content: center;
  flex-direction: column
}

.upload-area {
  width: 500px;
  height: 250px;
  background-color: #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative
}

.select-area {
  width: 100%;
  height: 250px;
  overflow: scroll;
  background-color: #fcfcfc;
  padding: 20px;
  display: none
}

.upload-area img {
  left: 50%;
  z-index: 1
}

.upload-area input {
  opacity: 0;
  left: 50%;
  z-index: 5;
  width: 100%;
  height: 100%
}

.select-img-flex {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: flex-start;
  gap: 20px
}

.select-img {
  width: 200px;
  height: 100px
}

.preview-course .course-info .course-info-flex .course-img img,
.select-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover
}

.mb-3.flex {
  display: flex;
  align-items: center;
  justify-content: flex-start
}

.courseupload-area .upload-flex {
  display: flex;
  flex-direction: column;
  gap: 20px
}

.courseupload-area .upload-flex .upload-media-area,
.courseupload-area .upload-flex .upload-scrom-area {
  width: 240px;
  max-width: 100%;
  height: 180px;
  background-color: var(--chips-color);
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23707070FF' stroke-width='4' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative
}

.courseupload-area .upload-flex .upload-media-area input[type=file],
.courseupload-area .upload-flex .upload-scrom-area input[type=file] {
  opacity: 0;
  position: absolute;
  z-index: 5;
  width: 100%;
  height: 100%
}

.courseupload-area .upload-flex .upload-media-area .upload-btn,
.courseupload-area .upload-flex .upload-scrom-area .upload-btn {
  width: 150px;
  height: 35px;
  background-color: var(--body-dark-bg);
  color: var(--whiteColor);
  border-radius: 8px;
  font: 400 15px/24px Avenir, sans-serif !important;
  letter-spacing: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px
}

.courseupload-area .upload-flex .upload-media-area .info-text,
.courseupload-area .upload-flex .upload-scrom-area .info-text {
  position: absolute;
  bottom: 5px;
  color: var(--whiteColor);
  font: 400 18px/24px Avenir, sans-serif !important;
  letter-spacing: 0
}

.courseupload-area .upload-flex .upload-media-area .info-text span,
.courseupload-area .upload-flex .upload-scrom-area .info-text span {
  font-size: 12px
}

.courseupload-area .upload-flex .add-assessment {
  width: 240px;
  max-width: 100%;
  height: 50px;
  background-color: var(--chips-color);
  border: 1px solid #707070;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center
}

.courseupload-area .upload-flex .add-assessment .btn {
  width: -moz-max-content;
  width: max-content;
  height: 60px;
  outline: 0;
  box-shadow: none;
  border: none;
  color: var(--whiteColor);
  font: 400 18px/24px Avenir, sans-serif !important;
  letter-spacing: 0
}

.main-content .topics-flex {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  height: 450px;
  overflow: scroll;
  overflow-X: hidden
}

.main-content .topics-flex .topics {
  width: 100%;
  height: 145px;
  background-color: #d9d9d9;
  border-radius: 6px;
  cursor: move;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  padding: 30px
}

.main-content .topics-flex .topics.over {
  border: 3px dotted #666
}

.main-content .topics-flex .topics .draggable-topic {
  width: 600px;
  display: flex;
  align-items: center;
  flex-direction: row
}

.main-content .topics-flex .topics .topic-count span {
  font-size: 30px
}

.main-content .topics-flex .topics .topic-info {
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px
}

.main-content .topics-flex .topics .topic-info .conditions,
.main-content .topics-flex .topics .topic-info .topic-name,
.preview-course .course-info .course-info-flex .course-details .bottom,
.preview-course .course-info .course-info-flex .course-details .top,
.preview-course .course-order .topics-list .topics .topic-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start
}

.main-content .topics-flex .topics .topic-info .topic-name input {
  width: 400px !important;
  padding: 0 !important;
  background-color: transparent !important;
  border: none !important;
  font-size: 18px !important;
  color: var(--whiteColor) !important
}

.main-content .topics-flex .topics .topic-info .topic-name span {
  font-size: 18px;
  color: var(--whiteColor)
}

.main-content .topics-flex .topics .topic-info .topic-name span.type {
  font-size: 14px;
  color: var(--whiteColor)
}

.main-content .topics-flex .topics .topic-info .conditions label {
  font-size: 16px;
  color: var(--whiteColor)
}

.main-content .topics-flex .topics .topic-action .btn {
  width: -moz-max-content;
  width: max-content
}

.preview-course .course-info,
.preview-course .course-order {
  width: 100%;
  height: max-content;
  padding: 25px;
  border-radius: 6px
}

.preview-course .course-order .topics-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px
}

.preview-course .course-order .topics-list .topics:first-child {
  margin-bottom: 15px
}

.preview-course .course-order .topics-list .topics .topic-info {
  width: 310px;
  display: flex;
  align-items: center;
  gap: 20px
}

.preview-course .course-info .course-info-flex .course-details .bottom .course-cat span,
.preview-course .course-info .course-info-flex .course-details .bottom .course-pathways span,
.preview-course .course-info .course-info-flex .course-details .bottom .course-pathways-count span,
.preview-course .course-info .course-info-flex .course-details .top .course-desc span,
.preview-course .course-info .header span,
.preview-course .course-order .topics-list .topics .conditions span,
.preview-course .course-order .topics-list .topics .topic-content span.name,
.preview-course .course-order .topics-list .topics .topic-content span.type {
  font: 400 15px/20px Avenir, sans-serif !important;
  letter-spacing: 0;
  color: var(--whiteColor)
}

.preview-course .course-order .topics-list .topics .conditions {
  width: 150px;
  line-height: 12px;
  text-align: left
}

.preview-course .course-info .course-action {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px
}

.preview-course .course-info .course-action label {
  width: 150px;
  display: flex;
  align-items: center;
  gap: 10px
}

.preview-course .course-info .course-action label input {
  width: 20px !important;
  height: 20px !important;
  margin-bottom: 0 !important;
  background-color: var(--body-dark-bg)
}

.preview-course .course-info .header {
  margin-bottom: 30px
}

.preview-course .course-info .course-info-flex {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 15px;
  margin-bottom: 25px
}

.preview-course .course-info .course-info-flex .course-img {
  width: 250px;
  height: 150px
}

.preview-course .course-info .course-info-flex .course-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px
}

.preview-course .course-info .course-info-flex .course-details .top .course-name span {
  font: 600 20px/24px Avenir, sans-serif !important;
  letter-spacing: 0;
  color: var(--whiteColor)
}

.preview-course .course-info .course-info-flex .course-details .bottom .course-cat span.val {
  text-transform: capitalize
}

#pdf::before {
  font-family: FontAwesome;
  content: "\f1c1"
}

#scrom::before {
  font-family: FontAwesome;
  content: "\f167"
}

#assessment::before {
  font-family: FontAwesome;
  content: "\f46d"
}

.icon {
  width: 30px
}

.create-course .action-flex {
  display: flex;
  flex-direction: row-reverse;
  align-items: center !important;
  justify-content: space-between !important
}

.create-course .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px
}

.create-course .switch input:checked+.slider,
.user-table .switch input:checked+.slider {
  background-color: #2196f3
}

.create-course .switch input:focus+.slider,
.user-table .switch input:focus+.slider {
  box-shadow: 0 0 1px #2196f3
}

.create-course .switch .slider,
.user-table .switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s
}

.create-course .switch .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #fff;
  transition: .4s
}

.newCourse a {
  color: var(--whiteColor);
  display: flex;
  align-items: center;
  gap: 10px
}

.newCourse a span.uploadIcon::before {
  font-family: FontAwesome;
  content: "\f0ee"
}

.newCourse a span.userIcon::before {
  font-family: FontAwesome;
  content: "\f007"
}

.user-table .switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px
}

.user-table .switch input:checked+.slider:before {
  transform: translateX(18px)
}

.user-table .switch .slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 3px;
  bottom: 2px;
  background-color: #fff;
  transition: .4s
}

.modal {
  background-color: rgba(0, 0, 0, .5) !important
}

.modal-content {
  background-color: var(--dark-bg) !important;
  border: var(--bs-modal-border-width) solid #ffffff26 !important
}

.modal-footer {
  border-top: none !important
}

.modal-header .btn-close {
  padding: 0 20px 0 0 !important;
  height: 30px;
  background-image: none !important
}

.modal-title {
  font: 20px/30px 'Source Sans Pro', sans-serif !important;
  letter-spacing: 0;
  color: var(--whiteColor) !important
}

.modal-footer button {
  width: 85px;
  background-color: transparent;
  font: 20px/30px 'Source Sans Pro' sans-serif !important;
  letter-spacing: 0;
  color: var(--whiteColor)
}

.modal-footer button.yes {
  background: var(--body-dark-bg) 0 0 no-repeat padding-box;
  border: 1px solid var(--primary-color);
  border-radius: 5px
}

.doughnut-chart-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto
}

.navigation.active {
  width: 0
}

.main.active {
  width: calc(100% - 0px);
  left: 0
}

@media only screen and (max-width:600px) {

  .launch_btn,
  .table tbody tr td,
  .table thead tr th {
    font-size: 10px
  }

  .table tbody tr td:first-child,
  .table tbody tr td:last-child,
  .table thead tr th:first-child,
  .table thead tr th:last-child {
    padding-left: 15px
  }

  .launch_btn {
    width: 70px
  }

  .blue_btn,
  .green_btn,
  .orange_btn,
  .red_btn {
    width: 75px
  }

  .main_sec_flex,
  .progress_flex {
    flex-direction: column
  }

  .courses,
  .left,
  .my__courses,
  .my__courses .custom_table,
  .my__progress,
  .right {
    width: 100%
  }

  .right {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px
  }

  .learning__cat .card {
    width: 230px !important
  }

  .my__progress-chart {
    gap: 0
  }

  .chart__point {
    gap: 5px
  }

  .announcement__list,
  .events__list .events_list_inside {
    height: 175px !important;
    margin-left: 0 !important
  }

  .calendar,
  .latest_announcements,
  .upcomming__events {
    height: max-content !important
  }

  .my__courses {
    width: 100% !important
  }

  .add__new-course,
  .points,
  .search,
  .select_flex {
    display: none
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link,
  .nav-tabs .nav-link.active {
    padding-right: 1rem !important
  }

  .search label .search__icon {
    position: relative
  }

  .mobile_flex {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column
  }

  .announcement,
  .calendar,
  .upcomming__events {
    width: 100%;
    height: auto
  }

  .announcement__list,
  .events__list {
    width: 260px
  }

  .heading .toggle {
    left: -14.5%
  }

  .responsive_table {
    overflow-x: auto
  }

  .events {
    gap: 20px
  }

  .descrip {
    font-size: 9px
  }

  .announcemnet .icon {
    margin-left: 0
  }
}

@media only screen and (max-width:380px) {
  .table thead tr th .table_head {
    gap: 5px
  }

  .my__courses .custom_table {
    overflow: scroll
  }


  .learning__cat .card {
    width: 100% !important
  }

  .learning__cat {
    padding-right: 0
  }

  .add__new-course,
  .points,
  .search,
  .select_flex,
  .theme_settings {
    display: none
  }
}

@media only screen and (min-width:600px) {

  .launch_btn,
  .table tbody tr td,
  .table thead tr th {
    font-size: 11px
  }

  .table tbody tr td:first-child,
  .table thead tr th:first-child {
    padding-left: 10px
  }

  .launch_btn {
    width: 80px
  }

  .blue_btn,
  .green_btn,
  .orange_btn,
  .red_btn {
    width: 75px
  }

  .main_sec_flex,
  .progress_flex {
    flex-direction: column
  }

  .courses,
  .left,
  .my__courses,
  .my__courses .custom_table,
  .my__progress,
  .right {
    width: 100%
  }

  .right {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px
  }

  .learning__cat .card {
    width: 225px !important
  }

  .announcement__list,
  .events__list .events_list_inside {
    height: 175px !important;
    margin-left: 0 !important
  }

  .calendar,
  .latest_announcements,
  .upcomming__events {
    height: max-content !important
  }

  .my__courses {
    width: 100% !important
  }

  .search,
  .select_flex {
    display: none
  }

  .mobile_flex {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    gap: 20px
  }

  .announcement,
  .calendar,
  .upcomming__events {
    width: 100%;
    height: auto
  }

  .announcement__list,
  .events__list {
    width: 260px
  }
}

@media only screen and (min-width:768px) {
  .main_sec_flex {
    flex-direction: column
  }

  .left,
  .right {
    width: 100%
  }

  .right {
    display: flex;
    align-items: flex-start;
    gap: 20px
  }

  .courses {
    width: 510px
  }

  .learning__cat .card {
    width: 225px !important
  }

  .announcement__list,
  .events__list .events_list_inside {
    height: 175px !important;
    margin-left: 0 !important
  }

  .calendar,
  .latest_announcements,
  .upcomming__events {
    height: max-content !important
  }

  .my__courses .custom_table {
    width: 855px
  }

  .my__courses {
    width: 100% !important
  }

  .select_flex {
    display: none
  }

  .my__courses,
  .my__progress {
    margin-bottom: 50px
  }

  .mobile_flex {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    gap: 20px
  }

  .announcement,
  .calendar,
  .upcomming__events {
    width: 100%;
    height: auto
  }
}

@media only screen and (min-width:992px) {
  .main_sec_flex {
    flex-direction: row
  }

  .outer-box.filter-courses {
    height: 100% !important
  }

  .right {
    flex-direction: column
  }

  .search {
    display: none
  }

  .select_flex {
    display: flex
  }

  .left {
    width: 695px
  }

  .item .card-img-top {
    width: 100%;
    height: 150px !important
  }

  .learning__cat .card {
    width: 185px !important
  }

  .my__courses .custom_table {
    width: 696px
  }

  .progress_flex {
    gap: 36px;
    flex-direction: row
  }

  .courses,
  .my__progress {
    width: 330px
  }

  .my__courses,
  .my__progress {
    margin-bottom: 50px
  }

  .add__new-course,
  .customisation,
  .notifications,
  .search input,
  .select__role,
  .select_courses,
  .settings,
  .theme_settings {
    display: block
  }

  .search label .search__icon {
    position: absolute
  }

  .search {
    width: 200px
  }

  .search input {
    font-size: 14px
  }

  .announcemnet .icon {
    margin-left: 0
  }

  .announcemnet {
    gap: 15px
  }

  .mobile_flex {
    display: flex;
    align-items: flex-start;
    justify-content: space-between
  }

  .announcement,
  .calendar,
  .upcomming__events {
    width: 100%;
    height: max-content
  }

  .announcement__list {
    height: 215px
  }

  .main__section .row {
    margin-bottom: 0
  }
}

@media only screen and (min-width:1200px) {
  .learning__cat .card {
    width: 192px !important
  }

  .calendar {
    height: max-content !important
  }

  .upcomming__events {
    height: 283px !important
  }

  .announcement__list,
  .events__list .events_list_inside {
    height: 215px !important
  }

  .announcement {
    height: 313px !important
  }

  .announcement__list {
    height: 250px !important
  }

  .main_sec_flex {
    flex-direction: row
  }

  .right {
    flex-direction: column;
    width: 264px
  }

  .add__new-course,
  .customisation,
  .notifications,
  .search,
  .search input,
  .select__role,
  .select_courses,
  .settings,
  .theme_settings {
    display: block
  }

  .mobile_flex,
  .select_flex {
    display: flex
  }

  .my__progress {
    width: 315px
  }

  .courses {
    width: 565px
  }

  .progress_flex {
    gap: 50px;
    flex-direction: row
  }

  .my__courses .custom_table {
    width: 928px
  }

  .left {
    width: 940px
  }

  .my__courses,
  .my__progress {
    margin-bottom: 0
  }

  .mobile_flex {
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    gap: 0
  }

  .announcement,
  .calendar,
  .upcomming__events {
    width: 100%;
    height: auto
  }

  .search {
    width: 260px
  }
}

@media only screen and (min-width:1920px) {

  .outer-box.filter-courses,
  .outer-box.quick-actions {
    height: 565px
  }
}

@media only screen and (max-width:1300px) {
  .filter {
    width: 250px !important
  }
}

@media only screen and (max-width:1280px) {

  .css-13cymwt-control,
  .css-t3ipsp-control,
  .react-select-container {
    width: 100% !important
  }

  .filter {
    width: 250px !important
  }

  .outer-box.two .inner-box {
    height: 100% !important
  }

  .my__progress-chart {
    flex-direction: column !important
  }
}

@media only screen and (max-width:1200px) {
  .filter {
    width: 230px !important
  }

  .outer-box.two .inner-box {
    height: 100% !important
  }

  .my__progress-chart {
    flex-direction: column !important
  }
}

@media only screen and (max-width:1125px) {
  .admin-users .permission-btn {
    font: 400 12px/30px source-sans-pro, sans-serif !important;
    width: max-content;
    padding-left: 10px;
    padding-right: 10px
  }

  .css-13cymwt-control,
  .css-t3ipsp-control,
  .react-select-container {
    width: 100% !important
  }

  .filter {
    width: 200px !important
  }
  .outer-box.two .inner-box {
    height: 100% !important
  }

  .my__progress-chart {
    flex-direction: column !important
  }
}

@media only screen and (max-width:992px) {

  .css-13cymwt-control,
  .css-t3ipsp-control,
  .filter,
  .react-select-container {
    width: 100% !important
  }

  .filter-body .filter-list,
  .filter-body .filter-list .filter-item .filter-input {
    flex-direction: row
  }

  .box-outer {
    height: max-content
  }

  .box-inner {
    padding-bottom: 25px
  }

  .filter {
    position: initial !important
  }
  .outer-box .row>div {
    margin-bottom: 20px
  }

  .outer-box .row>div:last-child {
    margin-bottom: 0
  }

  .outer-box.two .inner-box {
    height: 100% !important
  }

  .my__progress-chart {
    flex-direction: row !important
  }
}

@media only screen and (max-width:925px) {
  .filter-body .filter-list .filter-item .filter-input {
    flex-direction: row
  }

  .filter-body .filter-list {
    flex-direction: column
  }

  .box-outer {
    height: max-content
  }

  .box-inner {
    padding-bottom: 25px
  }

  .css-13cymwt-control,
  .css-t3ipsp-control,
  .react-select-container {
    width: 100% !important
  }
}

@media only screen and (max-width:778px) {

  .act-flex,
  .box-inner .action-flex {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px
  }

  .css-13cymwt-control,
  .css-t3ipsp-control,
  .react-select-container {
    width: 100% !important
  }
}

@media only screen and (max-width:600px) {

  .estoreLms-btn-course, .search-container-courses {
    flex-direction: column;
  }

  .css-13cymwt-control,
  .css-t3ipsp-control,
  .filter,
  .react-select-container {
    width: 100% !important
  }

  .box-outer.right .box-inner .search-bar,
  .my__progress-chart {
    flex-direction: column !important
  }

  .filter-body .filter-list,
  .filter-body .filter-list .filter-item .filter-input {
    flex-direction: column
  }

  .box-outer {
    height: max-content
  }

  .box-inner {
    padding-bottom: 25px
  }

  .permission-flex,
  .permission-flex .act-permission,
  .user-status-flex {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start
  }

  .filter {
    position: initial !important
  }

  .navigation ul li a {
    padding: 12px 2px
  }

  .main__section {
    padding: 10px
  }

  .navigation {
    width: 0
  }

  .navigation.active {
    width: 60px
  }

  .main {
    width: calc(100% - 0px);
    left: 0
  }

  .main.active {
    width: calc(100% - 60px);
    left: 60px
  }

  .custom_theme,
  .help,
  .lang {
    display: none
  }
}

#google_translate_element {
  position: fixed !important;
  bottom: 0px !important;
  right: 50px !important;
  height: 50px !important;
  width: 100px !important;
  z-index: 1 !important;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
  display: none;
  z-index: unset;
}

.VIpgJd-ZVi9od-l4eHX-hSRGPd,
.VIpgJd-ZVi9od-l4eHX-hSRGPd:link,
.VIpgJd-ZVi9od-l4eHX-hSRGPd:visited,
.VIpgJd-ZVi9od-l4eHX-hSRGPd:hover,
.VIpgJd-ZVi9od-l4eHX-hSRGPd:active {
  display: none;
}
