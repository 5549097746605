.profile-img-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  letter-spacing: NaNpx;
  color: var(--whiteColor);
  opacity: 1;
  margin-bottom: 25px;
}

.profile-img-container img {
  height: 150px;
  width: 150px;
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 1;
}

.profile-details {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  text-align: left;
}

.profile-id {
  padding-top: 10px;
  font: normal normal normal 20px/42px;
  letter-spacing: 0px;
  color: var(--whiteColor);
  opacity: 1;
}

.edit-profile-details {
  margin-top: 50px;
}

.editpro {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: var(--whiteColor);
}

.name-details {
  color: var(--whiteColor);;
  margin-left: 18em;
}

.profile-img-container .circle {
  border-radius: 100%;
  border: 2px solid var(--whiteColor);
  padding: 5px;
}